@import "../../../../styles/mixins";

.container {
  & > [data-tippy-root] {
    @media (max-width: 400px) {
      width: 100%;
      transform: translate3d(0, 60px, 0) !important;
    }

    & > div[class*="tippy-box"] {
      background-color: var(--secondary);
    }
  }

  .user-profile {
    @include flex-center;
    cursor: pointer;

    .icon {
      width: 20px;
    }

    .name {
      display: none;
    }
  }

  .options-block {
    width: 100%;
    min-width: 290px;
    font-size: 18px;
  }

  .user-block {
    padding: 12px var(--indent-base) var(--indent-small);
    color: var(--secondary);
    font-size: var(--font-size-medium);
    background-color: var(--sand-o-80);

    .name {
      @include flex-center;
      font-weight: var(--font-weight-semi-bold);
      text-transform: uppercase;

      .icon {
        margin-right: 15px;
      }
    }

    .email {
      @include text-overflow-ellipsis;
      margin-top: var(--indent-xsmall);
      font-weight: var(--font-weight-thin);
    }

    .profile-status {
      display: inline-block;
      margin-top: var(--indent-small);
      padding: var(--indent-xsmall) var(--indent-small);
      background-color: var(--red-primary);
      color: var(--secondary);
      letter-spacing: 1.25px;
      font-family: var(--noto-sans-medium);
      font-size: var(--font-size-small);
      text-transform: uppercase;
      user-select: none;
    }
  }

  .items {
    min-width: 290px;
    background-color: var(--secondary);
    box-shadow: var(--box-shadow-pop-up-box);

    .dropdown-item {
      position: relative;

      & > [data-tippy-root] {
        position: static !important;
        width: 100%;
        transform: translate3d(0, 0, 0) !important;

        & > div[class*="tippy-box"] {
          background-color: var(--transparent);
        }
      }

      .label {
        position: relative;
        padding: 13px var(--indent-base) var(--indent-base);
        color: var(--dark-grey);
        font-size: var(--font-size-base);
        border-bottom: var(--border-black);
        cursor: pointer;

        &::after {
          position: absolute;
          top: 38%;
          width: 8px;
          height: 12px;
          margin-left: var(--indent-small);
          background: url("../../assets/chevrons-and-arrows/chevron-right.svg") 100% / 100% no-repeat;
          transition: .3s;
          content: "";
        }

        &.active {
          font-weight: var(--font-weight-semi-bold);

          &::after {
            transform: rotate(90deg);
          }
        }
      }

      .submenu-item {
        padding-left: var(--indent-large);

        &:last-child {
          border-bottom: var(--border-black);
        }
      }
    }
  }
}

@include md-screen {
  .container {
    @include flex-start-end;

    & > [data-tippy-root] {
      max-width: 290px;
    }

    .user-profile {
      padding: 14px 0;
    }
  }
}

@include lg-screen {
  .container {
    .user-profile .name {
      @include header-txt-style;
      @include text-overflow-ellipsis;
      max-width: 150px;
      margin-left: 12px;

      &.lg-visible {
        display: block;
        max-width: 100px;
      }
    }

    .user-block .profile-status {
      display: none;
    }
  }
}

@include xl-screen {
  .container .user-profile .name {
    display: block;

    &.lg-visible {
      max-width: 150px;
    }
  }
}

@media (min-width: 1360px) {
  .container .user-profile {
    .name.lg-visible,
    .name {
      max-width: 170px;
    }
  }
}