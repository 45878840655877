@import "src/styles/mixins";

.chip {
  @include flex-center;
  position: relative;
  padding: 6px 12px;
  color: var(--black-o-87);
  font-size: var(--font-size-medium);
  cursor: pointer;
  user-select: none;
}

.updating-process {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: var(--white-o-40);
}

.primary {
  background-color: var(--light-grey);
  transition: background-color .3s;

  &.active {
    background-color: var(--light-sand);
  }
}

.secondary {
  color: var(--black-o-60);

  &.active {
    color: var(--dark-khaki);
    font-weight: var(--font-weight-bold);
  }

  &.updating-data {
    color: var(--grey77);
  }
}

.active-icon {
  margin-right: var(--indent-small);
}