@import "../../../../../../../../styles/mixins";

.container {
  width: 100%;
  padding: var(--indent-medium);
  background-color: var(--secondary);

  .title,
  .total-label {
    color: var(--dark-grey);
    font-family: var(--russo-one);
  }

  .title {
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .total-label {
    @include flex-column;

    & > span:last-child {
      margin-top: var(--indent-medium);
      align-self: flex-end;
      white-space: nowrap;
    }

    .substr {
      font-family: var(--noto-sans);
    }
  }

  .subtotal-block {
    .subtotal-item {
      @include flex-center-between;
      color: var(--dark-grey);
      font-size: var(--font-size-medium);

      & > span:last-child {
        margin-left: var(--indent-xlarge);
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .separator {
    height: 1px;
    margin: var(--indent-small) 0;
    background-color: var(--black-o-20);
    border: none;
  }

  .btn-confirm {
    width: 100%;
    max-width: 320px;
    margin-top: var(--indent-medium);
    margin-left: auto;
    font-size: var(--font-size-medium);
  }
}

@include sm-screen {
  .container {
    .total-label {
      justify-content: space-between;
      flex-direction: row;

      & > span:last-child {
        margin-top: 0;
        margin-left: var(--indent-xlarge);
      }
    }
  }
}

@include xl-screen {
  .container {
    max-width: 50%;
    margin-left: auto;

    .title {
      font-size: 18px;
    }
  }
}