@import "src/styles/mixins";

.container {
  background: var(--secondary);

  .item {
    position: relative;
    display: grid;
    grid-template-columns: 5% 20% 14% 15% repeat(2, 8%) repeat(2, 15%);
    padding: var(--indent-base) var(--indent-large);
    color: var(--dark-grey);
    font-size: var(--font-size-medium);

    &:not(:last-child) {
      border-bottom: var(--border-black);
    }

    .head-column,
    .column {
      &.second-last,
      &.last {
        min-width: 150px;
      }

      &:not(:first-child) {
        padding-left: var(--indent-small);
      }
    }

    .head-column {
      align-self: center;
      max-width: 87px;
    }

    .column {
      @include text-overflow-ellipsis;
      max-width: 175px;
    }

    .delete {
      position: absolute;
      top: 25%;
      right: var(--indent-base);
      width: 14px;
      height: 24px;
      background: url("../../../../../../../../common/assets/other-icons/trash-icon.svg") center no-repeat;
      visibility: hidden;
      cursor: pointer;
      opacity: 0;
    }

    &.hide {
      pointer-events: none;
    }

    .hiding-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-color: var(--white-o-40);

      .preloader {
        top: 50%;
        height: 30px;
        transform: translate(-50%, -50%);

        & > div {
          transform-origin: 38px 15px;

          &:after {
            width: 2px;
            height: 5px;
          }
        }
      }
    }
  }

  .item.head {
    padding: var(--indent-xsmall) var(--indent-large);
    color: var(--grey);
  }
}

@include xl-screen {
  .container {
    .item:hover {
      background-color: var(--white-green);

      .delete {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}