@import "src/styles/mixins";

.logo {
  width: 135px;
}

.logo-link {
  @include flex-center;
}

@include xs-screen {
  .logo {
    width: 150px;
  }
}

@include sm-screen {
  .logo {
    width: 180px;
  }
}

@include lg-screen {
  .logo {
    width: 200px;
  }
}

@include xl-screen {
  .logo {
    width: 230px;
  }
}