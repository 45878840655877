@import "src/styles/mixins";

.container {
  @include flex-column;
  padding: var(--indent-large) var(--indent-medium);
  color: var(--dark-grey);
  background: var(--secondary);

  .shipment-options {
    max-width: 820px;

    .title {
      margin-bottom: var(--indent-medium);
      color: var(--dark-grey);
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;
      opacity: .9;
    }

    .radio-block {
      margin-bottom: var(--indent-large);

      & > div[class*='radio-group'] {
        display: grid;
        grid-template-columns: repeat(2, 342px);
        align-items: flex-start;
        justify-content: space-between;

        & > label[class*='radio'] {
          &:last-child {
            margin: 0;
          }
        }
      }

      & > div[class*='radio-group-label'] {
        display: none;
      }
    }

    .grid-group {
      display: grid;
      grid-row-gap: var(--indent-medium);
      justify-content: space-between;
    }

    .group-even-2 {
      grid-template-columns: repeat(2, 342px);
    }
  }

  .organizations-block {
    position: relative;
    min-height: 150px;
    margin-top: 28px;

    .preloader {
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .setup {
      border-bottom: var(--border-grey77);

      .setup-content {
        padding: var(--indent-small) 0 var(--indent-medium);
      }

      .setup-label {
        position: relative;
        width: 100%;
        padding: 0;

        & > div {
          max-width: 80%;
        }

        &::after {
          right: var(--indent-xsmall);
        }
      }

      .setup-title {
        @include flex-center;
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-base);
        font-family: var(--russo-one);

        & > a {
          margin-left: var(--indent-xlarge);

          .btn {
            padding: var(--indent-base) 0;

            & > div:first-child {
              position: relative;
              top: -2px;
            }
          }
        }
      }
    }
  }
}