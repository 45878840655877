@import "./mixins";

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

h1,
h2,
h3,
h4,
p,
body {
  margin: 0;
  padding: 0;
}

body,
div {
  @include scroll;
}

body {
  overflow-x: hidden;
}

h1 {
  font-weight: var(--font-weight-normal);
  font-size: 18px;
  font-family: var(--russo-one);
  line-height: var(--line-height-1rem-half);
  text-transform: uppercase;
}

body {
  font-size: var(--font-size-base);
  font-family: var(--noto-sans);
  letter-spacing: .15px;
  background-color: var(--secondary);
}

button,
input {
  cursor: pointer;
}

a {
  text-decoration: none;
}

[data-tippy-root] {
  max-width: none;
}

input {
  letter-spacing: .15px;
}

input[type='number'] {
  padding: 0;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
}

div[data-tippy-root] {
  & > div[class="tippy-box"] {
    line-height: normal;
    background-color: transparent;

    div[class="tippy-content"] {
      padding: 0;
    }

    div[class="tippy-arrow"] {
      color: var(--secondary);
    }
  }
}

strong {
  font-family: var(--noto-sans-bold);
}

@include lg-screen {
  h1 {
    font-size: 24px;
    line-height: calc(3.5 * var(--indent-base));
  }
}
