@import "src/styles/mixins";

.wrapper {
  box-shadow: var(--box-shadow-common);
}

.container {
  @include container-xl;
  display: grid;
  grid-template-areas:
      "logo btn-block"
      "search search";
  grid-gap: var(--indent-base) var(--indent-small);
  min-height: 60px;
  padding: var(--indent-base);

  &.private {
    grid-template-areas:
      "logo btn-block btn-block"
      "search search search";

    .btn-block {
      .menu {
        display: flex;
        align-self: center;
      }

      .lang {
        margin-left: var(--indent-medium);
      }
    }
  }

  &.trial-mode .btn-block .profile-status {
    display: none;
  }

  .logo {
    grid-area: logo;
  }

  .btn-block {
    @include flex-center;
    grid-area: btn-block;
    justify-content: flex-end;

    .sign-in-btn,
    .btn-menu button {
      display: flex;
      padding: 10px var(--indent-base);
      max-width: 100px;
      min-height: var(--btn-secondary-min-height);
    }

    .sign-in-btn .btn-label,
    .btn-menu button > div > span {
      @include text-overflow-ellipsis;
      max-width: 80px;
    }

    .lang {
      margin-left: 10px;
    }
  }

  .search {
    grid-area: search;
  }
}

@include xs-screen {
  .container .btn-block {
    .sign-in-btn,
    .btn-menu button {
      max-width: none;
    }

    .sign-in-btn .btn-label,
    .btn-menu button > div > span {
      max-width: none;
    }
  }
}

@include sm-screen {
  .container {
    &.private .btn-block .lang {
      margin-left: var(--indent-large);
    }

    .btn-block .lang {
      margin-left: var(--indent-base);
    }
  }
}

@include md-screen {
  .container {
    grid-template-areas: "logo search btn-block";
    grid-column-gap: var(--indent-base);
    grid-template-columns: 190px .9fr auto;
    padding: var(--indent-medium);

    &.private {
      grid-template-areas: "logo search btn-block";

      &.importer-role:not(.trial-mode) {
        .btn-block {
          .menu {
            margin-right: var(--indent-medium);
          }

          .lang {
            margin-left: var(--indent-medium);
          }
        }
      }
    }

    .btn-block {
      .lang {
        margin-left: var(--indent-medium);
      }
    }

    .search {
      margin: 0;
      max-width: none;
    }
  }
}

@include lg-screen {
  .container {
    grid-template-columns: 210px .8fr auto;

    &.trial-mode {
      grid-template-columns: 210px .9fr auto;

      .btn-block {
        .menu {
          margin-right: var(--indent-base);
        }

        .profile-status {
          position: relative;
          display: block;
          padding-right: 12px; // tooltip width

          .label {
            color: var(--red-primary);
            letter-spacing: 1.25px;
            font-family: var(--noto-sans-semi-bold);
            font-size: var(--font-size-medium);
            text-transform: uppercase;
          }

          .tooltip {
            padding: var(--indent-base);
            font-size: var(--font-size-medium);
          }
        }

        .lang {
          margin-left: var(--indent-base);
        }
      }
    }
  }
}

@include xl-screen {
  .container {
    grid-template-columns: 240px .7fr auto;

    &.private {
      .btn-block {
        .menu {
          margin-right: var(--indent-medium);
        }

        .lang {
          margin-left: var(--indent-medium);
        }
      }
    }
  }
}