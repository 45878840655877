@import "../../../../../../../../../../styles/mixins";

.container {
  padding: var(--indent-medium);

  .form {
    @include flex-column;
    align-items: center;

    .inputs-block {
      width: 100%;
      max-width: 350px;
      max-height: 500px;
      margin-bottom: 20px;
      overflow-y: auto;

      &.with-scroll {
        padding-right: var(--indent-base);
      }

      .input-container {
        @include flex-center-between;
        width: 100%;

        .input-index {
          display: block;
          flex-basis: 7%;
          flex-shrink: 0;
          text-align: right;
        }

        & > div:last-child {
          flex-basis: 88%;
          flex-shrink: 0;
        }

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }

    .add {
      height: auto;
    }

    .save {
      min-width: 100px;
      margin-top: var(--indent-large);
    }
  }
}