@import "src/styles/mixins";

.container {
  .accordion-container {
    &:not(:first-child) {
      margin-top: var(--indent-xsmall);
    }

    .label {
      padding-right: calc(2 * var(--indent-base) + 11px);

      & > div[class *= 'text'] {
        max-width: none;
        width: 100%;

        &::after {
          right: var(--indent-base);
        }

        .text {
          @include flex-start-between;

          .number {
            flex-basis: 35%;
          }

          .total {
            flex-basis: 63%;
            flex-shrink: 0;
            width: 100%;
          }
        }
      }
    }

    .content {
      padding: var(--indent-base) var(--indent-small) 12px var(--indent-small);
    }
  }
}

.empty {
  height: 115px;
  padding: var(--indent-xlarge) var(--indent-medium);
  color: var(--grey);
  font-size: var(--font-size-medium);
  text-align: center;
}

.preloader {
  top: 50%;
  transform: translate(-50%, -50%);
}