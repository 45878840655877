@import "src/styles/mixins";

.container {
  max-height: 0;
  padding: 0;
  overflow-y: hidden;
  visibility: hidden;
  opacity: 0;
  transition: max-height .3s;

  &.show {
    max-height: 520px;
    padding: 40px 0 0;
    visibility: visible;
    opacity: 1;
  }

  .filters {
    @include flex-start-end;
    margin-bottom: var(--indent-medium);

    .filter {
      color: var(--dark-khaki);
      font-size: var(--font-size-medium);
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 28px;
      }

      &.active {
        font-weight: var(--font-weight-bold);
      }
    }
  }

  .items-block {
    max-height: 320px;
    padding-right: var(--indent-large);
    overflow-y: auto;

    &.text-center {
      text-align: center;
    }

    .item {
      display: flex;
      padding: 20px 20px var(--indent-base);
      background-color: var(--secondary);
      border-bottom: var(--border-black);

      .mark {
        display: block;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }

      .title {
        margin-bottom: var(--indent-base);
      }

      &.success {
        .mark {
          background: url("../assets/error_and_info_message-icons/check-mark.svg") center no-repeat;
        }

        .title {
          color: var(--green);
        }
      }

      &.failed {
        .mark {
          background: url("../assets/error_and_info_message-icons/exclamation-mark.svg") center no-repeat;
        }
      }

      .content {
        .description {
          margin-bottom: var(--indent-base);

          &-item {
            color: var(--red-primary);
            font-size: var(--font-size-medium);

            &::before {
              display: inline-block;
              width: 8px;
              height: 8px;
              margin-right: 6px;
              background-color: var(--red);
              border-radius: 50%;
              content: "";
            }
          }
        }

        .row-number {
          color: var(--dark-grey);
          font-size: var(--font-size-medium);
        }
      }
    }
  }

  .btn-block {
    @include flex-start-end;
    margin-top: var(--indent-xxlarge);
  }
}