:root {
  /* Color variables */
  --primary: #000000;
  --secondary: #ffffff;
  --transparent: transparent;

  --half-black: #0000007f;
  --black-o-87: #000000dd;
  --black-o-60: #00000099;
  --black-o-20: #00000033;
  --black-o-10: #0000001A;

  --white-blue: #f6f7fa;
  --white-green: #f0f2ee;
  --white-green-o-50: #f0f2ee7f;
  --white-red: #999595;
  --white-grey: #f6f6f6;
  --white-grey-lighter: #f4f4f4;
  --white-o-80: #ffffffcc;
  --white-o-70: #ffffffb2;
  --white-o-60: #ffffff99;
  --white-o-40: #ffffff66;

  --dark-khaki: #364534;

  --sand: #776755;
  --sand-o-80: #776755cc;
  --sand-o-70: #776755b2;
  --sand-red: #746757;
  --light-sand: #e5dfd3;
  --light-sand-o-40: #d6cab466;

  --dark-grey: #333333;
  --grey34-o-80: #575757cc;
  --grey34-o-40: #57575766;
  --grey: #666666;
  --grey77: #c4c4c4;
  --silver: #cccccc;
  --silver-dark: #b6b6b6;
  --light-grey: #dadada;
  --alluminium: #a3ab96;
  --light-alluminium: #a3ab96b2;

  --dark-green: #036333;
  --green: #088046;

  --dark-red: #a60808;
  --red-primary: #e01212;
  --berry-red: #c41010;
  --red: #e63a3a;

  --orange-bright: #ff8a00;
  --orange: #ff9d29;
  --dirty-orange: #ffaf5e99;

  --blue: #2e56a5;

  --border-alluminium: 1px solid var(--alluminium);
  --border-dashed-alluminium: 1px dashed var(--alluminium);
  --checkbox-border: 2px solid var(--alluminium);
  --border-grey: 1px solid var(--grey);
  --error-border: 2px solid var(--red);
  --border-grey77: 1px solid var(--grey77);
  --border-light-grey: 1px solid var(--light-grey);
  --border-black: 1px solid var(--black-o-20);
  --border-black-strong: 2px solid var(--black-o-20);
  --border-transparent: 1px solid var(--transparent);
  --border-sand-o-70: 1px solid var(--sand-o-70);
  --border-language-menu: 2px solid var(--red-primary);

  /* Box shadows */
  --box-shadow-initial: 0 1px 1px transparent, 0 2px 1px transparent, 0 1px 3px transparent;
  --box-shadow-right-left-side: 5px 0 4px -4px #0000001e, -5px 0 4px -4px #0000001e;
  --box-shadow-all-side: 0 1px 1px #00000023, 0 2px 1px #0000001e, 0 1px 3px #00000033;
  --box-shadow-hover: 0 2px 2px #0000001e, 5px 0 4px -4px #0000001e, -5px 0 4px -4px #0000001e;
  --box-shadow-input: 0px 1px 2px rgba(0, 0, 0, 0.15);
  --box-shadow-beard: 0px 1px 2px rgba(0, 0, 0, 0.15);
  --box-shadow-tooltip: 0px -2px 4px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.15);
  --box-shadow-pop-up-box: 0px -2px 4px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.15);
  --box-shadow-video: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));
  --box-shadow-common: 0px 0px 15px rgba(0, 0, 0, 0.25);
  --box-shadow-panel: 0px 1px 2px rgba(0, 0, 0, 0.15);
  --box-shadow-sand: 0px 1px 2px var(--sand);
  --box-shadow-panel-secondary: 0 0 15px rgba(55, 70, 51, 0.15);

  /* Typography variables */
  --font-size-base: 16px;

  --font-size-small: 12px;
  --font-size-medium: 14px;

  --font-weight-thin: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;

  --line-height-base: 1rem;
  --line-height-1rem-half: 1.5rem;
  --line-height-1rem-7: 1.7rem;
  --line-height-2rem: 2rem;

  --noto-sans: "Noto Sans", sans-serif; // font-weight: 400
  --noto-sans-medium: "Noto Sans Medium", sans-serif; // font-weight: 500
  --noto-sans-semi-bold: "Noto Sans Semi Bold", sans-serif; // font-weight: 600
  --noto-sans-bold: "Noto Sans Bold", sans-serif; // font-weight: 700
  --noto-sans-extra-bold: "Noto Sans Extra Bold", sans-serif; // font-weight: 800
  --russo-one: "Russo One", sans-serif;

  /* Indent variables */
  --indent-base: 1rem; // 16px

  --indent-xsmall: 0.25rem; // 4px
  --indent-small: 0.5rem; // 8px
  --indent-medium: 1.5rem; // 24px
  --indent-large: 2rem; // 32px
  --indent-xlarge: 3rem; // 48px
  --indent-xxlarge: 3.5rem; // 56px
  --indent-xxxlarge: 4rem; // 64px

  --main-padding-bottom: calc(4 * var(--indent-large));
  --main-padding-bottom-medium-device: calc(5 * var(--indent-base));

  /* Box border */
  --border-radius: 4px;
  --border-radius-circle: 50%;
  --border-radius-box: 8px;
  --border-radius-checkbox: 2px;
  --border-radius-input: 1px;
  --border-btn-radius: 1px;
  --border-radius-skeleton: 3px;

  /* Sizes */
  --container-min-height-xs-device: 350px;
  --container-min-height-md-device: 560px;

  --input-height: 56px;
  --checkbox-size: 22px;

  --row-height: 38px;
  --row-height-large: 44px;

  --btn-primary-min-height: 52px;
  --btn-secondary-min-height: 36px;
}