@import "src/styles/mixins";

.container {
  @include flex-column;
  width: 100%;
  background-color: var(--secondary);

  .info-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--indent-xxlarge) var(--indent-base) 40px;
    color: var(--secondary);
    background-color: var(--dark-khaki);

    .title {
      font-size: 1.5rem;
      font-family: var(--russo-one);
      text-align: center;
      text-transform: uppercase;
    }

    .description {
      @include flex-center;
      flex-direction: column;
      margin: var(--indent-medium) 0 62px;
      font-size: var(--font-size-medium);

      .text {
        &:not(:last-child) {
          margin-bottom: var(--indent-medium);
        }
      }
    }
  }

  .form-block {
    padding: var(--indent-xlarge) 40px;

    .title {
      margin-bottom: var(--indent-base);
      color: var(--dark-grey);
      font-weight: var(--font-weight-bold);
      font-size: 22px;
      text-align: center;
      text-transform: uppercase;
    }

    .inputs-block {
      margin-bottom: var(--indent-base);

      .name-block > div:last-child {
        margin-bottom: var(--indent-base);
      }

      .input-phone {
        max-width: none;
        transition: margin-bottom .1s;
      }

      .select-container {
        position: relative;
        min-width: 0;
        height: var(--input-height);
        margin-bottom: var(--indent-base);
        transition: margin-bottom .1s;

        & > div[data-tippy-root] {
          width: 100%;
          padding: var(--indent-xsmall) 0;
        }

        .select {
          height: 100%;

          & > div {
            color: var(--grey);
          }
        }

        .item {
          color: var(--grey);

          &.active {
            color: var(--dark-grey);
            background-color: var(--white-green);
          }

          &.not-available {
            color: var(--light-grey);
            pointer-events: none;
          }
        }

        .error-message {
          position: absolute;
          bottom: calc(-1 * var(--indent-xsmall));
          left: var(--indent-base);
          display: none;
          color: var(--red);
          font-size: var(--font-size-small);
          transform: translateY(100%);
        }

        &.error {
          margin-bottom: var(--indent-large);

          .select {
            border-color: var(--red);

            & > div,
            & > span {
              color: var(--red);
            }
          }

          .error-message {
            display: block;
          }
        }
      }

      .input-error {
        margin-bottom: var(--indent-large) !important;
        border-color: var(--red);
      }
    }

    .selection-block {
      font-size: var(--font-size-medium);

      &:not(:first-child) {
        margin-top: var(--indent-medium);
      }

      .label {
        margin-bottom: var(--indent-base);
        color: var(--dark-grey);
        font-weight: var(--font-weight-bold);
      }

      .radio-block {
        display: flex;

        & > label:last-child {
          margin-left: var(--indent-medium);
        }
      }
    }

    .checkbox {
      @include flex-center;
      margin-bottom: var(--indent-base);
      color: var(--grey);
      cursor: pointer;

      .box {
        width: 18px;
        min-width: 18px;
        height: 18px;
        margin-right: 19px;
        border: var(--checkbox-border);
        border-radius: var(--border-radius);
        content: '';
      }

      input:checked + .box {
        background: url('../assets/active.svg') center center no-repeat;
      }
    }

    .privacy_checkbox {
      align-items: flex-start;
      margin-top: var(--indent-large);
      font-size: var(--font-size-medium);

      .link {
        color: var(--grey);
        font-weight: var(--font-weight-bold);
        text-decoration: underline;
      }
    }

    .btn {
      width: 100%;
    }

    .common-error {
      height: 16px;
      color: var(--red);
      text-align: center;
      opacity: 0;

      &.show {
        height: 22px;
        margin-bottom: var(--indent-base);
        opacity: 1;
      }
    }
  }
}

.preloader {
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  transform: translate(-50%, -50%);

  & > div {
    transform-origin: 16px 17px;

    &:after {
      top: 2px;
      left: 14px;
      width: 3px;
      height: 7px;
    }
  }
}

@include lg-screen {
  .container {
    flex-direction: row;
    width: 960px;
    margin: 0;

    .info-block,
    .form-block {
      flex: 1 1 50%;
    }

    .info-block {
      padding: 225px 40px 42px;

      .title {
        font-size: 30px;
      }

      .description {
        flex-grow: 1;
        margin-top: var(--indent-large);
        font-size: var(--font-size-base);

        .text {
          text-align: left;
        }
      }
    }

    .form-block {
      padding: var(--indent-xxlarge) 40px 40px;

      .inputs-block .name-block {
        @include flex-start-between;

        & > div {
          flex-basis: 48%;
        }
      }
    }
  }
}

@media (min-width: 1100px) {
  .modal {
    max-width: 1000px !important;
  }

  .container {
    width: 1000px;
  }
}