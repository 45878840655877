@import "../../../../../../../../styles/mixins";

.preloader {
  position: relative;
}

.form {
  color: var(--dark-grey);

  .input-block {
    &.with-indent-bottom {
      margin-bottom: var(--indent-large);
    }

    &:not(.with-indent-bottom):not(:last-child) {
      margin-bottom: var(--indent-medium);
    }

    .group {
      display: grid;
      grid-column-gap: var(--indent-base);
      grid-template-columns: auto 180px;
    }

    .remove-item-btn {
      height: auto;
      margin: var(--indent-medium) 0 0 auto;
    }
  }

  .add-item-btn {
    margin: var(--indent-base) 0 var(--indent-base) auto;
  }

  .btn-group {
    display: grid;
    grid-column-gap: var(--indent-large);
    grid-template-columns: repeat(2, 130px);
    justify-content: flex-end;
    margin-top: 40px;

    .btn-submit {
      min-height: var(--btn-secondary-min-height);
    }
  }
}