@import "src/styles/mixins";
@import "src/styles/page-container.module.scss";

.title {
  margin-bottom: var(--indent-xlarge);
  color: var(--dark-grey);
  font-size: 18px;
  font-family: var(--russo-one);
  text-transform: uppercase;
}

@include md-screen {
  .title {
    margin-bottom: var(--indent-xxlarge);
    font-size: 20px;
  }
}