@import "src/styles/mixins";

.container {
  display: flex;
  flex-wrap: nowrap;
  margin: calc(-1 * var(--indent-large)) 0 var(--indent-base);
}

.options-block {
  @include flex-wrap;
}

.option-item {
  flex: 0 0 auto;
  margin: var(--indent-small) var(--indent-small) var(--indent-small) 0;

  &:last-child {
    margin-right: 0;
  }
}

.reset-btn {
  flex: 0 0 auto;
  margin-left: var(--indent-medium);
  padding: 14px 0;
  color: var(--grey);
  font-size: var(--font-size-medium);
  cursor: pointer;

  &.reset-pnum-btn {
    margin-left: 0;
  }
}