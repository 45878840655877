.info {
  position: absolute;
  top: 0;
  display: inline-block;
  transform: translateY(-60%);
  cursor: pointer;

  &-icon {
    width: 12px;
    height: 12px;
  }
}

.info-content {
  padding: var(--indent-medium);
  color: var(--grey);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-base);
  text-transform: none;

  .info-title {
    margin-bottom: var(--indent-medium);
    color: var(--dark-grey);
    font-weight: var(--font-weight-semi-bold);
    font-size: 20px;
  }
}