@import "src/styles/mixins";
@import "src/styles/page-container.module.scss";

.categories {
  display: grid;
  grid-gap: var(--indent-base);
  grid-template-columns: 1fr;
}

.product-card {
  width: 100%;
  min-width: 250px;
  margin-right: 20px;
}

@include xs-screen {
  .categories {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include md-screen {
  .categories {
    grid-gap: var(--indent-medium);
    grid-template-columns: repeat(3, 1fr);
  }

  .product-card {
    min-width: 0;
    margin-right: 0;
  }
}

@include lg-screen {
  .categories {
    grid-template-columns: repeat(5, 1fr);
  }
}

@include xl-screen {
  .categories {
    grid-template-columns: repeat(6, 1fr);
  }
}