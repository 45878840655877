@import "src/styles/mixins";

.container {
  @include flex-column;
  padding: var(--indent-large) var(--indent-medium) 40px;
  background: var(--secondary);

  .title {
    margin-bottom: var(--indent-medium);
    color: var(--dark-grey);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    opacity: .9;
  }

  .grid-group {
    display: grid;
    grid-row-gap: var(--indent-medium);
    grid-column-gap: var(--indent-medium);
  }

  .group-3 {
    grid-template-columns: 200px repeat(2, 1fr);
  }

  .accounts-block {
    position: relative;
    min-height: 200px;

    .preloader {
      top: 35%;
      transform: translate(-50%, -35%);
    }

    .currency {
      border-bottom: var(--border-grey77);

      .content {
        padding: var(--indent-small) 0 var(--indent-medium);
      }

      .label {
        position: relative;
        width: 100%;
        padding: 0;

        &::after {
          right: var(--indent-xsmall);
        }

        .currency-title {
          @include flex-center;
          font-size: var(--font-size-base);
          font-family: var(--russo-one);
          letter-spacing: 1.25px;

          & > a {
            margin-left: var(--indent-xlarge);

            .add-btn {
              padding: var(--indent-base) 0;

              & > div:first-child {
                position: relative;
                top: -2px;
              }
            }
          }
        }
      }
    }
  }

  .offset-top {
    margin-top: 40px;
  }

  .bank-details-block,
  .pg-details-block {
    margin-top: 40px;
  }
}