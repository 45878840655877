@import "src/styles/mixins";

.options {
  padding: var(--indent-base) var(--indent-base) calc(2.5 * var(--indent-base));
  border-bottom: var(--border-black);

  .title {
    margin-bottom: var(--indent-small);
    font-weight: var(--font-weight-semi-bold);
    font-size: var(--font-size-medium);
    text-transform: uppercase;
  }

  &.input-block {
    padding: var(--indent-medium) var(--indent-base) calc(2.5 * var(--indent-base));

    .title {
      margin-bottom: var(--indent-medium);
    }
  }

  .items {
    @include flex-wrap;

    & > div[class*= 'options-block'] {
      & > div[class*= 'section']:nth-child(n + 2) {
        display: none;
      }
    }

    & > div[class*= 'item']:nth-child(n + 5) {
      display: none;
    }

    &.show-more-active {
      & > div[class*= 'options-block'] {
        & > div[class*= 'section']:nth-child(n + 2) {
          display: flex;
        }
      }

      & > div[class*= 'item'] {
        display: flex;
      }
    }
  }

  .btn {
    margin-top: var(--indent-large);
  }
}

.input-block {
  & > [data-tippy-root] {
    position: absolute !important;
    width: 100%;
    transform: translate3d(0, 48px, 0) !important;

    div[class*="proposed-option"] {
      & > div[class*="search-icon"] {
        margin-right: 12px;
      }
    }
  }
}