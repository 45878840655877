@import "src/styles/mixins";

.container {
  position: relative;
  background-color: var(--secondary);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);

  &.loading {
    @include flex-center-center;
    min-height: 56px; // preloader height + padding-top + padding-bottom
    padding: var(--indent-small) 0;

    .preloader {
      height: 40px;

      & > div {
        transform-origin: 30px 20px;

        &:after {
          left: 28px;
          width: 3px;
          height: 8px;
        }
      }
    }
  }

  &.no-data {
    color: var(--grey);
    text-align: center;
  }

  &.with-padding {
    padding-bottom: var(--indent-base);
  }

  .proposed-option {
    @include flex-center;
    padding: var(--indent-small) 18px;
    color: var(--grey);
    cursor: pointer;

    &:first-child {
      padding-top: 12px;
    }

    .search-icon {
      flex-shrink: 0;
      width: 12px;
      height: 12px;
      margin-right: var(--indent-large);
      background-image: url("../assets/search-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .title {
    padding: 12px var(--indent-base) var(--indent-small);
    color: var(--grey);
    font-size: var(--font-size-medium);
  }

  .options {
    padding: 0 var(--indent-base);
  }

  .option-item {
    display: inline-flex;
    padding: var(--indent-small) 0;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: var(--indent-small);
    }

    & > div {
      padding: var(--indent-xsmall);
    }
  }
}

@include sm-screen {
  .container {
    box-shadow: var(--box-shadow-input);
  }
}

@include xl-screen {
  .container {
    .proposed-option:hover {
      background: var(--white-green);
    }
  }
}