@import "src/styles/mixins";

.container {
  @include container-xl;
  position: relative;
  padding: 40px 0 155px;
}

.title-block {
  padding: 0 var(--indent-base) var(--indent-medium);

  .title,
  .subtitle {
    font-weight: var(--font-weight-normal);
    font-family: var(--russo-one);
    text-transform: capitalize;
    line-height: normal;

    &.not-capitalize-text {
      text-transform: none;
    }
  }

  .title {
    margin-bottom: var(--indent-base);
    font-size: 26px;
    color: var(--sand);
    text-align: center;
  }
}

.section {
  padding: 40px var(--indent-base);
  background-color: var(--white-green);

  &.sand-theme {
    margin-bottom: var(--indent-medium);
    background-color: var(--light-sand);
  }

  .mobile-title {
    margin-bottom: var(--indent-base);
    font-weight: var(--font-weight-normal);
    font-family: var(--russo-one);
    font-size: 20px;
    line-height: normal;
    text-align: center;

    & > span {
      display: block;
    }
  }

  .accordion {
    &-container {
      background-color: var(--transparent);
    }

    &-label {
      color: var(--primary);
      background-color: var(--transparent);
      padding: var(--indent-base) 12px;
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-medium);
      text-transform: uppercase;
      border-bottom: var(--border-black);

      &-text {
        max-width: 90%;
      }

      &:after {
        right: 14px;
        width: 9px;
        height: 14px;
        min-width: 9px;
        background: url("../common/assets/chevrons-and-arrows/arrow-red.svg") 100%/100% no-repeat;
      }
    }

    &-content {
      padding: 10px 0;
      background-color: var(--transparent);

      .item {
        padding: var(--indent-medium) var(--indent-base);
        font-weight: var(--font-weight-bold);
        font-family: var(--noto-sans);
        color: var(--primary);
        background-color: var(--secondary);
        box-shadow: var(--box-shadow-panel-secondary);

        &:not(:first-child) {
          margin-top: var(--indent-xsmall);
        }
      }
    }
  }
}

.table {
  position: relative;

  .header {
    //position: sticky;
    //top: 0;
    //z-index: 1;
    display: grid;
    grid-template-areas:
        "title . ."
        ". heading1 heading2"
        "text exporter importer";
    grid-template-columns: 60% 20% 20%;
    margin-bottom: var(--indent-medium);
    //background-color: var(--light-sand);

    .title {
      grid-area: title;
      width: 100%;
      max-width: 660px;
      font-family: var(--russo-one);
      font-weight: var(--font-weight-normal);
      font-size: 26px;
      text-transform: uppercase;
    }

    .title,
    .heading1 {
      padding-right: 12px;
    }

    .heading1,
    .heading2 {
      min-width: 130px;
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;
      text-align: center;
    }

    .heading1 {
      grid-area: heading1;
    }

    .heading2 {
      grid-area: heading2;
    }
  }

  .row {
    display: grid;
    grid-template-columns: 60% 20% 20%;
    padding: var(--indent-medium) 40px;
    background-color: var(--secondary);
    box-shadow: var(--box-shadow-panel-secondary);

    &:not(:last-child) {
      margin-bottom: var(--indent-xsmall);
    }

    .text {
      width: 100%;
      max-width: 660px;
      padding-right: var(--indent-medium);
      font-weight: var(--font-weight-bold);
    }

    & > div:nth-child(2) {
      padding-right: 12px;
    }

    .tick {
      min-width: 130px;

      &.active {
        position: relative;

        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 24px;
          height: 20px;
          background-image: url("../common/assets/other-icons/tick-red.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          transform: translate(-50%, -50%);
          content: "";
        }
      }
    }
  }
}

@include sm-screen {
  .title-block {
    padding-bottom: 40px;

    .title {
      font-size: 30px;
    }

    .subtitle {
      font-size: 18px;
    }
  }

  .section {
    &.sand-theme {
      margin-bottom: 40px;
    }

    .mobile-title {
      font-size: 22px;
    }

    .accordion {
      &-label {
        padding: var(--indent-medium) 12px;
        font-size: var(--font-size-base);
      }

      &-content .item {
        font-size: var(--font-size-base);
      }
    }
  }
}

@include md-screen {
  .title-block,
  .section {
    padding-left: var(--indent-medium);
    padding-right: var(--indent-medium);
  }

  .section .accordion {
    &-content {
      .item {
        padding: var(--indent-medium);
      }
    }
  }

  .scroll-to-top:not(.visible-always) {
    display: block;
  }
}

@include lg-screen {
  .container {
    padding: 86px var(--indent-medium) 100px;
  }

  .title-block {
    padding: 0 0 var(--indent-xxlarge);

    .title {
      margin-bottom: 42px;
      font-size: 38px;
    }

    .subtitle {
      font-size: 26px;
    }
  }

  .section {
    padding: 75px 90px;
  }

  .scroll-to-top:not(.visible-always) {
    display: none;
  }
}

@media (min-width: 1100px) {
  .table .header .title > span {
    display: block;
  }
}

@include xl-screen {
  .table .header {
    padding-right: 40px;
  }
}