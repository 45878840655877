@import "src/styles/mixins";
@import "src/styles/page-container.module.scss";

.title {
  margin: var(--indent-medium) 0 calc(2.5 * var(--indent-base));
}

.grid-container {
  position: relative;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  margin-bottom: var(--indent-medium);
}

@media (min-width: 520px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include md-screen {
  .title {
    margin: calc(2.5 * var(--indent-base)) 0;
  }
}

@media (min-width: 900px) {
  .grid-container {
    grid-gap: var(--indent-medium);
    grid-template-columns: repeat(3, 1fr);
  }
}

@include lg-screen {
  .title {
    margin: calc(2.5 * var(--indent-base)) 0;
  }

  .grid-container {
    grid-gap: var(--indent-large);
    margin-bottom: var(--indent-large);
  }
}