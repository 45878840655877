@import "../../../../../../../../styles/mixins";

.container {
  position: relative;
  display: grid;
  grid-template-rows: 210px auto 1fr;
  grid-template-columns: 1fr;
  justify-self: stretch;
  width: 100%;
  background-color: var(--light-sand);
  box-shadow: var(--box-shadow-initial);
  transition: box-shadow .3s;

  &.deleting {
    pointer-events: none;
  }

  .condition {
    padding: var(--indent-small) var(--indent-base);
    color: var(--secondary);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-base);
    text-transform: uppercase;
    background-color: var(--sand-o-80);
  }

  .options-menu {
    position: absolute;
    top: var(--indent-xsmall);
    right: var(--indent-xsmall);
    padding: var(--indent-xsmall) 0;
    background-color: var(--secondary);
  }

  .content {
    display: grid;
    grid-template-areas:
              "title id"
              "name name"
              "delivery delivery"
              "supply-conditions supply-conditions"
              "info info"
              "price price"
              "stock stock";
    grid-template-rows: repeat(4, auto) 1fr repeat(2, auto);
    grid-template-columns: auto;
    padding: var(--indent-base) var(--indent-base) var(--indent-medium);
    color: var(--dark-grey);
    font-size: var(--font-size-medium);

    .title {
      grid-area: title;
      margin-bottom: var(--indent-xsmall);
      color: var(--dark-grey);
      font-weight: var(--font-weight-bold);
    }

    .id {
      grid-area: id;
      margin-left: auto;
    }

    .name {
      @include text-overflow-ellipsis-line-clamp-2;
      grid-area: name;
      height: var(--row-height);
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-medium);
    }

    .delivery,
    .supply-conditions {
      @include text-overflow-ellipsis-line-clamp-2;
      min-height: var(--row-height);
    }

    .delivery {
      grid-area: delivery;
      margin: var(--indent-xsmall) 0;
    }

    .supply-conditions {
      grid-area: supply-conditions;
      margin-bottom: var(--indent-base);
    }

    .status {
      grid-area: info;
      margin-bottom: var(--indent-small);
    }

    .price {
      position: relative;
      grid-area: price;
      color: var(--dark-grey);
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-base);

      .info {
        position: absolute;
        top: -4px;
        margin-left: var(--indent-xsmall);
        cursor: pointer;

        &-icon {
          width: 15px;
          height: 15px;
        }
      }

      .info-content {
        padding: var(--indent-xsmall) 12px;
        color: var(--secondary);
        font-size: var(--font-size-small);
        background-color: var(--grey);
      }
    }

    .stock-count {
      grid-area: stock;
      margin-top: var(--indent-xsmall);
      color: var(--green);
      font-weight: var(--font-weight-semi-bold);
    }
  }

  .stickers {
    position: absolute;
    top: var(--indent-xsmall);
    left: var(--indent-xsmall);

    .sticker {
      padding: var(--indent-small);
      font-weight: var(--font-weight-medium);
      background: var(--secondary);
      border-radius: var(--border-btn-radius);

      &.reserved {
        color: var(--red-txt-secondary);
      }

      &.views {
        display: inline-block;
        color: var(--grey);
      }
    }

    .sticker + .sticker {
      margin-top: var(--indent-xsmall);
    }
  }
}

.hiding-container {
  @include hiding-container;

  .preloader {
    height: 45px;

    & > div {
      transform-origin: 39px 25px;

      &:after {
        width: 4px;
        height: 12px;
      }
    }
  }
}

@include lg-screen {
  .container .content {
    .title {
      font-size: 18px;
    }

    .name {
      font-size: var(--font-size-base);
      height: var(--row-height-large);
    }
  }
}

@include xl-screen {
  .container {
    &:hover {
      box-shadow: var(--box-shadow-right-left-side);
    }

    .content {
      grid-template-areas:
              "title id"
              "name name"
              "delivery delivery"
              "supply-conditions supply-conditions"
              "info info"
              "price stock";
      grid-template-rows: repeat(4, auto) 1fr auto;

      .stock-count {
        text-align: right;
      }
    }
  }
}