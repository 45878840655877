@import "../../../../../../../../styles/mixins";

.preloader {
  position: relative;
}

.container {
  color: var(--dark-grey);

  .checkbox {
    @include flex-center;
    cursor: pointer;

    span {
      @include flex-center-center;
      position: relative;
      box-sizing: border-box;
      width: 18px;
      height: 18px;
      margin-right: var(--indent-small);
      border: var(--checkbox-border);
      border-radius: var(--border-radius-checkbox);
      content: '';
    }

    input:checked + span {
      background: url("../../../../../../../common/assets/error_and_info_message-icons/check.svg") center / 13px 9px no-repeat var(--alluminium);
      border: none;
    }
  }

  .grid-group {
    display: grid;
    grid-row-gap: var(--indent-small);
    grid-column-gap: var(--indent-base);
  }

  .group-2 {
    grid-template-columns: auto 180px;
  }

  .group-even-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .group-3 {
    grid-template-columns: auto 100px 100px;
  }

  .group-even-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .group-4 {
    grid-template-columns: auto 112px auto auto;
  }

  .group-even-4 {
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: var(--indent-large);
  }

  .btn-single {
    @include flex-center;
    justify-content: flex-end;
    margin-bottom: var(--indent-xlarge);
  }

  .btn-group {
    display: grid;
    grid-column-gap: var(--indent-large);
    grid-template-columns: repeat(2, 130px);
    justify-content: flex-end;
    margin-top: 40px;

    & > button {
      min-height: var(--btn-secondary-min-height);
    }
  }
}