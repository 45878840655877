@import "src/styles/mixins";

.container {
  width: 100%;
  height: 435px;
  margin: 0 auto;

  .banner {
    position: relative;
    height: 100%;
    overflow: hidden;

    .banner-img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

@include sm-screen {
  .container {
    height: 500px;
  }
}

@include lg-screen {
  .container {
    height: 560px;
  }
}

@include xl-screen {
  .container {
    height: 650px;
  }
}

@media (min-width: 1350px) {
  .container {
    max-width: 1350px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1600px;
    height: 730px;
  }
}