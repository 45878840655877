@import "../../../../../../../../styles/mixins";

.container {
  @include flex-center-center;
  min-height: var(--container-min-height-xs-device);
  padding: var(--indent-medium);

  .text {
    display: none;
    margin-bottom: var(--indent-xlarge);
    color: var(--dark-grey);
    font-weight: var(--font-weight-medium);
  }

  .btn-block {
    .btn-add {
      width: 100%;
    }

    .btn-upload {
      display: none;
      margin-top: 38px;
    }
  }
}

@include xl-screen {
  .container {
    @include flex-column;
    min-height: 0;
    padding: 43px 0 75px;
    background-color: var(--secondary);

    .text {
      display: block;
    }

    .btn-block {
      @include flex-column;
      align-items: center;

      .btn-upload {
        display: flex;
      }
    }
  }
}