@import "/src/styles/mixins";

.container {
  @include box-shadow;
  position: relative;
  display: grid;
  grid-template-rows: 250px auto;
  grid-template-columns: 1fr;
  width: 100%;
  background-color: var(--secondary);

  .content {
    @include flex-column;
    justify-content: space-between;
    padding: var(--indent-base) 0;

    .title {
      display: block;
      color: var(--primary);
      font-weight: var(--font-weight-bold);
    }

    .preview {
      @include text-overflow-ellipsis-line-clamp-3;
      height: 68px;
      margin: var(--indent-base) 0;
      color: var(--black-o-87);
    }
  }
}

@include sm-screen {
  .container {
    .content {
      padding: var(--indent-base) 0;

      .title {
        font-size: 18px;
      }
    }
  }
}

@include lg-screen {
  .container {
    .content {
      padding: var(--indent-medium) 0;

      .preview {
        margin: var(--indent-medium) 0;
      }
    }
  }
}