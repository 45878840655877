@import "../../../../../../../../../styles/mixins";

.container {
  margin-top: var(--indent-small);
  padding: var(--indent-medium) var(--indent-small) var(--indent-large);
  background-color: var(--secondary);

  .title-block {
    @include flex-start-between;
    margin-bottom: var(--indent-xsmall);
    color: var(--dark-grey);
    font-weight: var(--font-weight-semi-bold);
    font-size: var(--font-size-medium);
    text-transform: uppercase;
  }

  .total-block {
    @include flex-start-between;
    color: var(--dark-khaki);
    font-weight: var(--font-weight-bold);
    font-size: 20px;
  }

  .progress-block {
    @include flex-center;
    position: relative;
    height: 115px;
    margin-top: var(--indent-base);
    padding: 0 var(--indent-medium);
    overflow: hidden;

    .progress-base-line {
      position: relative;
      width: 100%;
      height: 7px;
      background: var(--grey77);

      &::before,
      &::after {
        position: absolute;
        top: 0;
        width: 25px;
        height: 25px;
        background: var(--grey77);
        border-radius: 50%;
        content: "";
      }

      &::before {
        left: 0;
        transform: translate(-50%, -40%);
      }

      &::after {
        right: 0;
        transform: translate(50%, -40%);
      }

      &.before-circle-error::before {
        background: var(--red);
      }

      &.before-circle-success::before {
        background: var(--dark-khaki);
      }

      .progress-line {
        position: relative;
        width: 100%;

        .step {
          position: absolute;
          height: 7px;
          background-color: var(--grey77);

          &::after {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(50%, -50%);
            width: 25px;
            height: 25px;
            background-color: var(--grey77);
            border-radius: 50%;
            content: "";
          }

          &.success {
            background: var(--dark-khaki);

            &::after {
              background: var(--dark-khaki);
            }

            &.after-text-with-line {
              &::before {
                background-color: var(--dark-khaki);
              }
            }
          }

          &.error {
            background: var(--red);

            &::after {
              background: var(--red);
            }

            &.after-text-with-line {
              &::before {
                background-color: var(--red);
              }
            }
          }

          &.after-text-with-line {
            &::before {
              position: absolute;
              right: 0;
              width: 3px;
              height: 40px;
              background-color: var(--grey77);
              transform: translateX(50%);
              content: "";
            }

            .total {
              top: 40px;
            }
          }

          &.filled {
            &:last-child {
              &::before {
                content: none;
              }

              .total {
                display: none;
              }
            }
          }

          .lot {
            position: absolute;
            left: 50%;
            bottom: 8px;
            transform: translateX(-50%);
          }

          .total {
            position: absolute;
            top: 20px;
            right: 0;
            transform: translateX(50%);
            white-space: nowrap;
          }
        }
      }
    }
  }
}