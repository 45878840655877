@import "src/styles/mixins";

.container {
  @include container-xl;
  padding: 0 var(--indent-medium);
}

.block {
  padding: var(--indent-xxlarge) 0;
  background-color: var(--red-primary);

  .title {
    font-family: var(--russo-one);
    font-size: 20px;
    color: var(--secondary);
    text-transform: capitalize;

    &.not-capitalize-text {
      text-transform: initial;
    }
  }

  .text {
    margin: var(--indent-base) 0 var(--indent-large);
    color: var(--secondary);
  }

  .btn {
    width: 100%;
    max-width: 300px;
    color: var(--red-primary);
    background-color: var(--secondary);
  }
}

@media (min-width: 500px) {
  .block .btn {
    font-size: var(--font-size-medium);
  }
}

@include sm-screen {
  .block .title {
    font-size: 22px;
  }
}

@include md-screen {
  .block .btn {
    font-size: var(--font-size-base);
  }
}

@include lg-screen {
  .block {
    .title {
      font-size: 24px;
    }

    .text {
      max-width: 930px;
    }
  }
}

@include xl-screen {
  .block .title {
    font-size: 30px;
  }
}