@import "src/styles/mixins";

.wrapper {
  position: relative;

  h1 {
    line-height: var(--line-height-1rem-7);
  }
}

.banner-container {
  position: relative;
  z-index: -5;
  width: 100%;
  background-color: var(--light-sand);
}

.banner-text-container {
  position: absolute;
  top: 110px;
  left: 30px;

  .banner-text {
    font-family: var(--noto-sans-extra-bold);
    font-size: 18px;
    text-transform: uppercase;
    max-width: 160px;
    color: var(--sand);
  }

  .btn {
    width: 100%;
    min-height: 0;
    margin-top: 20px;
    padding: var(--indent-base) 18px;
  }
}

.container {
  @include container-xl;
  padding: 0 var(--indent-medium);
}

.request-access-btn {
  width: 100%;
  max-width: 300px;
  margin: var(--indent-large) auto 0;
}

.main-text {
  max-width: 1060px;
  padding: var(--indent-large) 0 0;
  margin: 0 auto;
  text-align: center;

  .title {
    font-size: 18px;
    font-family: var(--russo-one);
    text-transform: capitalize;
    color: var(--sand);

    &.not-capitalize-text {
      text-transform: uppercase;
    }
  }
}

.challenges {
  padding: var(--indent-xxlarge) 0;
  background-color: var(--light-sand);

  .subtitle {
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-bold);
    color: var(--red-primary);
    text-transform: uppercase;
  }

  .subtitle-text {
    margin-top: var(--indent-base);
    font-family: var(--noto-sans-extra-bold);
    font-size: 18px;
  }

  &-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--indent-xxlarge);
    margin: var(--indent-xxlarge) 0;

    .block {
      @include flex-column;

      .image {
        width: 94px;
        height: 94px;
        background-color: var(--secondary);
        background-repeat: no-repeat;
        background-position: center;
        border-radius: var(--border-radius-box);
      }

      .title {
        margin: var(--indent-medium) 0 var(--indent-base);
        font-family: var(--noto-sans);
        font-size: 17px;
        font-weight: var(--font-weight-bold);
        color: var(--primary);
      }

      .text {
        font-weight: var(--font-weight-bold);
        color: var(--sand-red);
      }
    }
  }
}

.video-block {
  padding: var(--indent-xxlarge) 0;

  .container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .video {
    width: 100%;
    max-width: 450px;
    box-shadow: var(--box-shadow-common);

    div[class="react-player__preview"] {
      & > div[class="react-player__shadow"] {
        border: 2px solid var(--red-primary);
        border-radius: 50% !important;
        background: none !important;

        & > div[class="react-player__play-icon"] {
          border-color: transparent transparent transparent var(--red-primary) !important;
        }
      }
    }
  }

  .content {
    .title {
      margin-bottom: var(--indent-large);
      font-size: 22px;
      font-family: var(--russo-one);
      text-transform: capitalize;
      color: var(--sand);

      &-item {
        display: inline;
      }
    }
  }

  &.not-capitalize-text {
    .content {
      .title {
        text-transform: uppercase;
      }
    }
  }
}

.section {
  &.news {
    margin-bottom: var(--indent-xlarge);
  }

  &.not-capitalize-text {
    & > h1 {
      text-transform: uppercase;
    }
  }

  margin-bottom: var(--indent-xxlarge);
}

@include xs-screen {
  .banner-text-container {
    .banner-text {
      font-size: 20px;
      max-width: 180px;
    }
  }

  .main-text .title {
    font-size: 20px;
  }

  .video-block .content {
    .title {
      text-align: center;
    }

    .title-item:last-child {
      display: block;
    }
  }
}

@media (min-width: 500px) {
  .banner-text-container {
    left: 50px;

    .banner-text {
      font-size: 22px;
      max-width: 200px;
    }

    .btn {
      font-size: var(--font-size-medium);
    }
  }

  .request-access-btn,
  .challenges .btn {
    font-size: var(--font-size-medium);
  }

  .main-text .title {
    font-size: 22px;
  }

  .video-block {
    .video {
      max-width: 500px;

      div[class="react-player__preview"] {
        & > div[class="react-player__shadow"] {
          width: 80px !important;
          height: 80px !important;

          & > div[class="react-player__play-icon"] {
            border-width: 22px 0 22px 32px !important;
          }
        }
      }
    }

    .content {
      width: 100%;
      max-width: 500px;

      &.middle-text {
        .title-item {
          display: block;
        }
      }
    }
  }
}

@include sm-screen {
  .banner-text-container {
    top: 140px;
    left: 55px;

    .banner-text {
      max-width: 237px;
      font-size: 26px;
    }

    .btn {
      min-height: var(--btn-primary-min-height);
    }
  }

  .main-text .title {
    font-size: 26px;
  }

  .challenges {
    &-content {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 60px;
    }

    .subtitle {
      font-size: 18px;
    }

    .subtitle-text {
      font-size: 20px;
    }
  }

  .video-block .content {
    .title {
      margin-bottom: var(--indent-xlarge);
      font-size: 24px;
    }
  }
}

@include md-screen {
  .banner-text-container {
    top: 285px;
    left: 30px;
    padding: 0 24px;

    .banner-text {
      max-width: 380px;
    }

    .btn {
      width: 100%;
      font-size: var(--font-size-base);

      &-link {
        display: block;
        min-width: 300px;
        max-width: 300px;
      }
    }
  }

  .request-access-btn,
  .challenges .btn {
    font-size: var(--font-size-base);
  }

  .main-text {
    padding-top: var(--indent-xlarge);
  }

  .challenges-content {
    grid-template-columns: repeat(3, 1fr);
  }

  .video-block {
    padding-bottom: var(--indent-xxxlarge);

    .video {
      max-width: 580px;

      div[class="react-player__preview"] {
        & > div[class="react-player__shadow"] {
          width: 100px !important;
          height: 100px !important;

          & > div[class="react-player__play-icon"] {
            border-width: 26px 0 26px 36px !important;
          }
        }
      }
    }

    .content {
      max-width: 580px;

      &.middle-text {
        .title-item {
          display: inline;
        }
      }

      .title {
        font-size: 26px;
      }
    }

    &.not-capitalize-text {
      .video {
        max-width: 600px;
      }

      .content {
        max-width: 600px;
      }
    }
  }
}

@media (min-width: 900px) {
  .challenges {
    padding: 80px 0;
  }

  .video-block {
    padding: 80px 0 100px;

    .content .title {
      font-size: 28px;
    }
  }

  .section {
    &.news {
      margin-bottom: var(--indent-xxxlarge);
    }

    margin-bottom: 80px;
  }
}

@include lg-screen {
  .wrapper {
    h1 {
      line-height: var(--line-height-2rem);
    }
  }

  .banner-text-container {
    top: 335px;
  }

  .main-text {
    padding-top: var(--indent-xxlarge);
  }

  .challenges {
    .subtitle {
      font-size: 20px;
    }

    .subtitle-text {
      font-size: 24px;
    }
  }

  .video-block {
    .container {
      flex-direction: row;
      align-items: center;
    }

    .video {
      max-width: 480px;
    }

    .content {
      max-width: none;
      margin-left: 80px;

      &.long-text {
        .title {
          font-size: 30px;
        }
      }

      .title {
        margin-bottom: 0;
        font-size: 36px;
        text-align: left;

        &-item {
          display: block;
        }
      }
    }

    &.not-capitalize-text {
      .content {
        .title {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 1100px) {
  .banner-text-container {
    left: 5%;
  }

  .video-block .content .title {
    font-size: 38px;
  }
}

@include xl-screen {
  .banner-text-container {
    top: 400px;

    &.less-top {
      top: 385px;
    }

    .banner-text {
      max-width: 500px;
      font-size: 32px;
    }
  }

  .main-text {
    padding-top: var(--indent-xxxlarge);
    padding-bottom: var(--indent-large);

    .title {
      font-size: 32px;
    }
  }

  .challenges,
  .video-block {
    padding: 100px 0;
  }

  .challenges {
    .subtitle {
      font-size: 24px;
    }
  }

  .video-block {
    padding: 100px 0 120px;

    .video {
      position: relative;
      max-width: 580px;

      .control-button {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        background: radial-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 80%);
        border-radius: 64px;
        opacity: 0;
        transform: translate(-50%, -50%) scale(1);
        cursor: pointer;

        &.play-button {
          &.animation {
            animation: control-button-play .5s;
          }

          .icon {
            border-style: solid;
            border-width: 14px 0 14px 24px;
            border-color: transparent transparent transparent white;
            margin-left: 7px;
          }
        }

        &.pause-button {
          &.animation {
            animation: control-button-pause .5s;
          }

          .icon {
            width: 32px;
            height: 32px;
            background-image: url("../common/assets/other-icons/pause-icon.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }

    .content {
      .title {
        font-size: 48px;

        &-item {
          display: block;
        }
      }
    }

    &.not-capitalize-text {
      .content {
        .title {
          font-size: 38px;
        }
      }
    }
  }

  .section {
    &.news {
      margin-bottom: 104px;
    }

    margin-bottom: 120px;
  }
}

@media (min-width: 1350px) {
  .banner-text-container {
    left: 50%;
    width: 100%;
    max-width: 1250px;
    transform: translateX(-50%);
  }

  .video-block {
    .video {
      max-width: 600px;
    }

    .content {
      .title {
        font-size: 50px;
      }
    }
  }
}

@media (min-width: 1600px) {
  .banner-text-container {
    top: 420px;
  }

  .challenges-content {
    grid-column-gap: 80px;
  }
}

@keyframes control-button-play {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }

  70% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.2);
  }
}

@keyframes control-button-pause {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }

  70% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.07);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.07);
  }
}