@import "src/styles/mixins";

.container {
  .table-head {
    display: grid;
    grid-template-columns: 10% 12% 9.5% 11% 9.5% 18% 16% 14%;
    align-items: center;
    padding: var(--indent-base) var(--indent-small);
    color: var(--grey);
    font-size: var(--font-size-medium);
    border-bottom: var(--border-black);
    background-color: var(--secondary);

    & > div:not(:first-child) {
      padding-left: var(--indent-small);
    }

    & > div:last-child {
      padding-left: 20px;
    }
  }

  .table-items {
    .item {
      color: var(--dark-grey);
      font-size: var(--font-size-medium);
      background-color: var(--secondary);
    }

    .preloader {
      top: 45%;
      transform: translate(-50%, -45%);
    }
  }
}

.empty {
  padding: var(--indent-xlarge) var(--indent-medium);
  color: var(--grey);
  text-align: center;
}