@import "src/styles/mixins";

.container {
  position: relative;
  height: var(--input-height);
  margin-bottom: var(--indent-base);
  border: var(--border-black);
  border-radius: var(--border-radius-input);
  transition: margin-bottom .1s;

  &:last-child {
    margin-bottom: var(--indent-medium);
  }

  .label {
    position: absolute;
    top: 50%;
    left: var(--indent-base);
    z-index: -1;
    color: var(--black-o-60);
    transform: translateY(-50%);
    opacity: 0;
    transition: 0.3s, opacity .15s;

    &.static {
      @include text-overflow-ellipsis-line-clamp-2;
      top: 0;
      left: var(--indent-small);
      max-width: 90%;
      padding: 0 var(--indent-small);
      padding-right: var(--indent-medium);
      font-size: var(--font-size-small);
      background-color: var(--secondary);
      transform: translateY(-60%);
      opacity: 1;
    }
  }

  .input {
    width: 100%;
    height: 100%;
    padding: var(--indent-base);
    color: var(--grey);
    font-size: var(--font-size-base);
    font-family: var(--noto-sans);
    background-color: var(--secondary);
    border: none;
    cursor: pointer;

    &[name="password"] {
      padding-right: var(--indent-xlarge);
    }

    &::placeholder {
      font-size: var(--font-size-base);
      font-family: var(--noto-sans);
    }

    &:focus + .label:not(.static),
    &:not(:placeholder-shown) + .label:not(.static) {
      top: 0;
      left: var(--indent-small);
      z-index: 0;
      padding: 0 var(--indent-small);
      font-size: var(--font-size-small);
      background-color: var(--secondary);
      opacity: 1;
    }
  }

  .eye-icon {
    position: absolute;
    top: 50%;
    right: var(--indent-base);
    width: 24px;
    height: 24px;
    background-image: url("../assets/other-icons/eye.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    cursor: pointer;

    &.active {
      background-image: url("../assets/other-icons/active-eye.svg");
      background-position: center -1px;
    }
  }

  .error-message {
    position: absolute;
    bottom: calc(-1 * var(--indent-xsmall));
    left: var(--indent-base);
    display: none;
    color: var(--red);
    font-size: var(--font-size-small);
    transform: translateY(100%);
  }

  .error-icon {
    position: absolute;
    top: 50%;
    right: 4%;
    display: none;
    width: 20px;
    height: 20px;
    background: url("../assets/error_and_info_message-icons/exclamation-mark.svg") 100% / 100% no-repeat;
    transform: translateY(-50%);
  }

  &.error {
    margin-bottom: var(--indent-large);

    .input {
      padding-right: calc(2.5 * var(--indent-base));
      border-color: var(--red);

      &::placeholder,
      &:focus + .label:not(.static),
      &:not(:placeholder-shown) + .label:not(.static) {
        color: var(--red);
      }
    }

    .eye-icon {
      display: none;
    }

    .error-message,
    .error-icon {
      display: block;
    }
  }
}