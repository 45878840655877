@import "src/styles/mixins";

.selected {
  cursor: pointer;
  user-select: none;

  &.secondary {
    position: relative;
    padding: var(--indent-base);
    padding-right: calc(2 * var(--indent-medium));
    border: var(--border-black);
    border-radius: var(--border-radius-input);

    &:after {
      position: absolute;
      top: 45%;
      right: 14px;
      width: 11px;
      height: 7px;
      background: url("./assets/chevron_down.svg") 100% / 100% no-repeat;
      content: "";
    }

    .label {
      @include text-overflow-ellipsis-line-clamp-2;
      position: absolute;
      top: 0;
      left: var(--indent-small);
      max-width: 90%;
      padding: 0 var(--indent-small);
      padding-right: var(--indent-medium);
      color: var(--black-o-60);
      font-size: var(--font-size-small);
      background-color: var(--secondary);
      transform: translateY(-60%);

      &.without-tooltip {
        padding-right: var(--indent-small);
      }
    }

    .selected-item {
      @include text-overflow-ellipsis;
    }
  }

  &.not-available {
    color: var(--light-grey);
  }

  &.disabled {
    color: var(--silver) !important;
    cursor: auto;

    &::after {
      background: url("../assets/chevrons-and-arrows/chevron-down-grey.svg") 100% / 100% no-repeat;
    }

    .label,
    .selected-item {
      color: var(--silver) !important;
    }
  }
}

.options {
  width: 100%;
  max-height: 250px;
  margin: 0;
  padding: var(--indent-small) 0 0;
  overflow-y: auto;
  font-size: 18px;
  list-style: none;
  background-color: var(--secondary);
  box-shadow: var(--box-shadow-input);
  @include scroll;

  & > li,
  & > a,
  & > div,
  & > span,
  & > button {
    position: relative;
    padding: 6px 12px;
    color: var(--dark-grey);
    cursor: pointer;

    &:not(:last-child) {
      &::after {
        position: absolute;
        right: 12px;
        bottom: 0;
        left: 12px;
        display: inline-block;
        height: 1px;
        margin: 0 auto;
        background-color: var(--light-grey);
        content: "";
      }
    }
  }

  &.secondary {
    border: var(--border-black);
    font-size: var(--font-size-medium);
  }

  &.with-search {
    .search-block {
      @include flex-center;
      padding: 2px 12px 10px;

      &::before {
        display: inline-block;
        width: 18px;
        height: 18px;
        min-width: 18px;
        background: url("../assets/other-icons/search-icon.svg") 100% / 100% no-repeat;
        content: "";
      }

      &::after {
        display: none !important;
      }

      .search {
        width: 100%;
        max-width: 350px;
        min-width: 130px;
        margin-left: var(--indent-base);
        padding: var(--indent-xsmall);
        font-size: var(--font-size-medium);
        background: var(--transparent);
        border-top: none;
        border-right: none;
        border-bottom: var(--border-black);
        border-left: none;
      }
    }

    .empty-message {
      display: block;
      padding: 10px 12px;
      color: var(--grey);
      font-size: var(--font-size-base);
      text-align: center;
    }
  }
}

.required-icon {
  margin-left: var(--indent-xsmall);
  color: var(--red);
}

@include xl-screen {
  .options {
    & > li:not(.search-block):not(.empty-message):hover,
    & > a:hover,
    & > div:hover,
    & > span:hover,
    & > button:hover {
      background-color: var(--white-green);
    }
  }
}