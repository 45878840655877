@import "../../../../../../../../../styles/mixins";

.container {
  display: grid;
  grid-template-columns: 10% 15% repeat(3, 9.5%) 20% 19.5% 7%;
  align-items: center;
  padding: 14px var(--indent-small);

  .column {
    &:not(:first-child) {
      padding-left: var(--indent-small);
    }

    .link {
      max-width: 24px;
      max-height: 24px;
    }
  }
}

.number {
  position: relative;

  &::after {
    position: absolute;
    bottom: -4px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: var(--grey);
    content: "";
  }
}

.description {
  padding: var(--indent-base) var(--indent-small);
}

.total {
  position: relative;

  .price {
    display: inline-block;
  }

  .info {
    position: absolute;
    top: -20%;
    display: inline-block;

    &-icon {
      width: 12px;
      height: 12px;
    }
  }

  .info-text {
    padding: var(--indent-small) var(--indent-base);
  }
}

@include xl-screen {
  .container:hover {
    background-color: var(--white-green);
  }
}