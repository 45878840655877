@import "src/styles/mixins";

.wrapper {
  position: relative;
}

.hiding-container {
  @include hiding-container;
}

.tabs-labels {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;

  .tab {
    width: 100%;
    background-color: var(--secondary);

    & > input[type="radio"] {
      display: none;

      &:checked + .label {
        position: relative;
        color: var(--dark-khaki);
        font-weight: var(--font-weight-semi-bold);

        &::before {
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: var(--dark-khaki);
          content: "";
        }
      }
    }

    .label {
      display: block;
      height: 100%;
      padding: 23px var(--indent-small);
      color: var(--grey);
      font-size: var(--font-size-medium);
      text-align: center;
      text-transform: uppercase;
      border-bottom: var(--border-black-strong);
      cursor: pointer;
    }
  }
}

.btn-block {
  @include flex-start-end;
  margin-top: 40px;

  .btn {
    width: 100%;
    max-width: 150px;
  }
}