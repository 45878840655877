@import "../../../../../../../../../styles/mixins";

.container {
  width: 100%;
}

.payment-container {
  @include flex-column;
  width: 100%;
  margin-bottom: var(--indent-large);

  .payment-means,
  .payment-options {
    width: 100%;
  }

  .payment-means {
    order: 2;
  }

  .payment-options {
    order: 1;
    margin-bottom: var(--indent-base);
  }

  .title {
    margin-bottom: var(--indent-xsmall);
    color: var(--dark-grey);
    font-family: var(--noto-sans-semi-bold);
    font-size: var(--font-size-medium);
  }

  .subtitle {
    margin-bottom: var(--indent-large);
    color: var(--grey);
    font-size: var(--font-size-medium);
  }

  .open-invoice {
    color: var(--grey);
    font-size: var(--font-size-medium);
  }
}

.description-container {
  .title {
    margin-bottom: var(--indent-small);
    color: var(--dark-grey);
    font-family: var(--noto-sans-semi-bold);
    font-size: var(--font-size-medium);
    text-transform: uppercase;
  }

  &.secondary {
    .description-block {
      &:not(:last-child) {
        margin-bottom: var(--indent-large);
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: var(--indent-large);
  }
}

@include md-screen {
  .payment-container {
    flex-direction: row;
    justify-content: space-between;

    .payment-means,
    .payment-options {
      flex-basis: 48%;
    }

    .payment-means {
      order: 1;
    }

    .payment-options {
      order: 2;
      margin-bottom: 0;
    }

    .title {
      text-transform: uppercase;
    }

    .subtitle {
      font-size: var(--font-size-base);
    }

    .open-invoice {
      color: var(--dark-grey);
      font-size: var(--font-size-base);
    }
  }
}

@include lg-screen {
  .container {
    padding: 0;
    background-color: var(--transparent) !important;
  }

  .payment-container {
    margin-bottom: var(--indent-base);
    padding: 40px var(--indent-medium) calc(3.75 * var(--indent-base));
    background-color: var(--secondary);
  }

  .description-container {
    padding: var(--indent-medium) var(--indent-medium) calc(2 * var(--indent-medium));
    background-color: var(--secondary);

    &.documents > div {
      padding: 0;
    }

    &:not(:last-child) {
      margin-bottom: var(--indent-base);
    }

    .description-item {
      & > div[class*='value'] {
        flex-basis: 50%;
      }
    }
  }
}

@include xl-screen {
  .description-container {
    &.secondary {
      display: flex;
      justify-content: space-between;

      .description-block {
        flex-basis: 50%;

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }

      .description-item {
        padding-right: 7%;

        & > div[class*='value'] {
          flex-basis: 25%;
        }
      }
    }

    .description-item {
      & > div[class*='value'] {
        flex-basis: 65%;
      }
    }
  }
}