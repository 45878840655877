@import "../../../../../../../../../../../../../styles/mixins";

.container {
  padding: 14px var(--indent-small);
  color: var(--black-o-87);
  font-size: var(--font-size-medium);
  border-bottom: var(--border-black);

  .row:not(:last-child) {
    padding-bottom: 14px;
    margin-bottom: 14px;
    border-bottom: 1px solid var(--black-o-10);
  }

  .item {
    display: grid;
    grid-template-columns: 38px 65% auto 72px;
  }

  .item {
    .column {
      position: relative;

      &:first-child {
        justify-self: flex-end;
        color: var(--dark-khaki)
      }

      &:not(:first-child) {
        padding-left: var(--indent-medium);
      }

      &:nth-child(2) {
        .row {
          display: grid;
          grid-template-columns: 32% 20% repeat(2, 11%) repeat(2, 13%);

          & > div:first-child {
            min-width: 100px;
            padding-left: var(--indent-small);
          }

          & > div:not(:first-child) {
            padding-left: var(--indent-base);
          }

          .serial {
            min-width: 100px;
          }
        }
      }

      .icons-block {
        @include flex-center-between;
        position: absolute;
        top: -3px;
        right: 0;
        min-width: 56px;
        visibility: hidden;
        opacity: 0;

        .unpack-block {
          display: flex;

          .unpack {
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url("../../../../../../../../../../../../common/assets/other-icons/open_box.svg") 100% / 100% no-repeat;
            cursor: pointer;
          }

          .info {
            position: relative;

            .info-icon {
              position: absolute;
              top: -5px;
              right: -12px;
              width: 12px;
              height: 12px;
            }
          }

          .tooltip {
            padding: var(--indent-small);
          }
        }

        .edit {
          display: inline-block;
          width: 18px;
          height: 18px;
          background: url("../../../../../../../../../../../../common/assets/other-icons/edit_icon.svg") 100% / 100% no-repeat;
          cursor: pointer;
        }
      }
    }
  }
}

@include xl-screen {
  .container:hover {
    background-color: var(--white-green);

    .item {
      .column {
        .icons-block {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}