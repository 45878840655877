@import "src/styles/mixins";

.container {
  display: grid;
  grid-gap: var(--indent-medium);
  grid-template-columns: repeat(3, 1fr);

  .message {
    grid-column: 2 / 3;
    align-self: center;
    color: var(--grey);
    font-size: var(--font-size-base);
    text-align: center;
  }

  .card {
    min-width: 0;
    border: var(--border-light-grey);

    .label {
      @include flex-center;
      padding: 6px var(--indent-small);
      color: var(--grey);
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-medium);
      text-transform: uppercase;
      background: var(--white-green);
      cursor: pointer;

      &.active {
        color: var(--white-blue);
        background: var(--sand-o-80);
        cursor: auto;

        &::before {
          width: 16px;
          height: 12px;
          margin-right: var(--indent-small);
          background: url('../../../../../../../../../../common/assets/error_and_info_message-icons/check.svg') 100% / 100% no-repeat;
          content: '';
        }
      }
    }

    .content {
      position: relative;
      padding: var(--indent-medium) var(--indent-base) 19px;
      color: var(--dark-grey);
      font-size: var(--font-size-base);

      .name {
        @include text-overflow-ellipsis;
        margin-bottom: var(--indent-xsmall);
      }

      .buttons {
        @include flex-center;
        margin-top: 26px;

        .delete {
          width: 14px;
          height: 18px;
          margin-right: var(--indent-medium);
          background: url("../../../../../../../../../../common/assets/other-icons/trash-icon-grey.svg") center no-repeat;
          cursor: pointer;
        }

        .edit {
          width: 18px;
          height: 18px;
          background: url("../../../../../../../../../../common/assets/other-icons/edit_icon.svg") center no-repeat;
          cursor: pointer;
        }
      }

      .preloader-block {
        position: absolute;
        right: var(--indent-base);
        bottom: var(--indent-base);
        width: 24px;
        height: 24px;

        .preloader {
          right: 0;
          left: auto;
          width: 50px;
          height: 26px;
          transform: none;

          & > div {
            transform-origin: 38px 15px;

            &:after {
              width: 2px;
              height: 5px;
            }
          }
        }
      }
    }
  }
}