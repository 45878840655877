@import "../../../../../styles/mixins";
@import "src/styles/page-container.module.scss";

.grid-items {
  position: relative;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  margin-bottom: var(--indent-medium);
}

.views {
  @include flex-center;
  margin: var(--indent-medium) 0 var(--indent-base);

  & > svg {
    cursor: pointer;
  }

  & > svg:first-child {
    margin-right: var(--indent-base);
  }
}

.list-header {
  @include flex-start-between;
  padding: 18px var(--indent-base);
  color: var(--half-black);
  font-size: var(--font-size-small);
  text-transform: uppercase;
  background-color: var(--secondary);
  border-bottom: var(--border-black);

  &.with-price {
    .column {
      &:nth-child(2) {
        display: none;
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }

      &.price {
        display: block;
      }
    }
  }

  .column {
    flex-basis: calc(100% / 3);

    &.price {
      display: none;
    }

    &:nth-child(2) {
      text-align: right;
    }
  }
}

.list-items {
  margin-bottom: var(--indent-medium);
}

.filters-and-sorting-block {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: var(--indent-medium);

  &.sorting-only > div > div {
    border-left: var(--border-black) !important;
  }
}

.products-block {
  position: relative;

  /* filter open bg on content */
  //.hiding-container {
  //  position: absolute;
  //  top: 0;
  //  bottom: 0;
  //  left: 50%;
  //  z-index: -1;
  //  width: 100vw;
  //  background-color: var(--black-o-20);
  //  transform: translateX(-50%);
  //  opacity: 0;
  //
  //  &.active {
  //    z-index: 1;
  //    opacity: 1;
  //    transition: opacity .3s;
  //    transition-delay: 60ms;
  //  }
  //}
}

@include xs-screen {
  .title-container {
    @include flex-start-between;
    margin: var(--indent-medium) 0 calc(2.5 * var(--indent-base));
  }

  .grid-items {
    grid-template-columns: repeat(2, 1fr);
  }

  .views {
    margin: 0;
  }
}

@include sm-screen {
  .grid-items {
    grid-gap: var(--indent-medium);
    grid-template-columns: repeat(3, 1fr);
  }

  .list-header {
    &.with-price {
      .column:nth-child(2) {
        display: block;
        padding: 0 var(--indent-base);
      }
    }

    .column {
      flex-basis: 35%;

      &:last-child {
        flex-basis: 30%;
      }
    }
  }
}

@include md-screen {
  .title-container {
    margin: calc(2.5 * var(--indent-base)) 0;
  }

  .grid-items {
    grid-gap: var(--indent-large);
    margin-bottom: var(--indent-large);
  }

  .list-header {
    .column {
      flex-basis: 40%;

      &:last-child {
        flex-basis: 20%;
        text-align: center;
      }
    }
  }

  .list-items {
    margin-bottom: var(--indent-large);
  }

  .filters-and-sorting-block {
    display: block;
    margin-bottom: 0;

    &.sorting-only > div > div {
      border-left: none !important;
    }
  }
}

@include lg-screen {
  .grid-items {
    grid-template-columns: repeat(4, 1fr);
  }

  .views {
    @include flex-center;
  }

  .list-header {
    font-size: var(--font-size-medium);

    &.with-price {
      .column:nth-child(2) {
        padding: 0 var(--indent-large);
      }
    }

    .column {
      flex-basis: 42%;

      &:last-child {
        flex-basis: 16%;
      }
    }
  }
}