@import "../../../../../../../../../styles/mixins";

.container {
  position: relative;
  margin-bottom: var(--indent-base);

  & > [data-tippy-root] {
    position: relative !important;
    transform: translate(0) !important;

    & > div[class~="tippy-box"][data-animation=shift-away][data-state=hidden][data-placement^=bottom] {
      transition: 50ms !important;
    }
  }

  .label {
    position: relative;
    padding: var(--indent-base) var(--indent-medium);
    color: var(--dark-grey);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    background-color: var(--light-sand);
    cursor: pointer;

    &-text {
      display: inline-block;
      max-width: 75%;
      user-select: none;
    }

    .info {
      position: absolute;
      top: 10px;
      margin-left: var(--indent-xsmall);

      &-icon {
        width: 16px;
        height: 16px;
      }
    }

    .info-content {
      padding: var(--indent-medium);
      color: var(--grey);
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-base);
      text-transform: none;

      .info-title {
        margin-bottom: var(--indent-medium);
        color: var(--dark-grey);
        font-weight: var(--font-weight-semi-bold);
        font-size: 20px;
      }
    }
  }

  .content {
    padding: var(--indent-medium);
    background-color: var(--secondary);
    border: var(--border-light-grey);
    border-top: none;
    border-radius: 0;
  }

  .hiding-container {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: var(--white-o-40);
    user-select: none;

    & + .label {
      &:after {
        content: none;
      }
    }
  }
}

@include lg-screen {
  .container {
    .label {
      cursor: pointer;
    }

    .hiding-container + .label {
      cursor: auto;
    }
  }
}