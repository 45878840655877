@import "src/styles/mixins";

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: var(--font-weight-medium);

  .number {
    flex-basis: 35%;
  }

  .organization {
    flex-basis: 63%;
    @include text-overflow-ellipsis;
  }
}

.accordion {
  &-container {
    border-bottom: var(--border-black);
  }

  &-label {
    padding-right: calc(2 * 16px + 11px);

    &::after {
      right: var(--indent-base);
    }

    & > div {
      width: 100%;
      max-width: none;
    }
  }

  &-content {
    padding: 0;
  }
}

@include md-screen {
  .container .organization {
    overflow: visible;
    white-space: normal;
  }
}