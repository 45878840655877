@import "src/styles/mixins";

.container {
  position: relative;
  min-height: 543px;
  overflow: hidden;

  .notifications-block {
    &.hidden {
      visibility: hidden;
      opacity: 0;
      transition: .3s;
      max-height: 543px;
    }

    .options-block {
      @include flex-start-between;
      margin-bottom: var(--indent-medium);

      &.extended {
        justify-content: flex-start;
        margin-bottom: var(--indent-small);

        .panel {
          display: flex;
          width: 100%;
          padding: var(--indent-small) var(--indent-base);
          padding-left: var(--indent-medium);
          background-color: var(--secondary);

          .delete,
          .cancel {
            margin-right: var(--indent-large);
            font-size: var(--font-size-medium);
            color: var(--black-o-60);
            cursor: pointer;
          }

          .delete {
            &.disable {
              color: var(--silver);
              pointer-events: none;
            }
          }
        }
      }

      .filters {
        @include flex-center;

        .filter {
          color: var(--black-o-60);
          font-size: var(--font-size-medium);
          cursor: pointer;

          &.active {
            color: var(--dark-khaki);
            font-family: var(--noto-sans-bold);
          }

          &:first-child {
            margin-right: var(--indent-large);
          }
        }
      }

      .select {
        font-size: var(--font-size-medium);
        color: var(--black-o-60);
        cursor: pointer;

        &.hidden {
          display: none;
        }
      }
    }

    .notifications {
      .notification-item-block {
        display: flex;

        &:not(:last-child) {
          margin-bottom: var(--indent-xsmall);
        }

        .notification-item {
          width: 100%;

          &.default-pointer {
            cursor: default;
          }
        }
      }

      .empty-message {
        padding: var(--indent-base);
        color: var(--grey);
        font-size: var(--font-size-medium);
        text-align: center;
      }
    }

    .show {
      display: none;
      font-size: 18px;
      color: var(--blue);
      font-weight: var(--font-weight-medium);
      margin-top: var(--indent-base);
      cursor: pointer;
    }
  }

  .notification {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 543px;

    &.open {
      animation: show-notification .5s;
    }
  }
}

@include md-screen {
  .container {
    .notifications-block {
      .notifications {
        overflow-y: auto;

        &.scrollable {
          padding-right: var(--indent-small);
        }
      }

      .show {
        display: block;
      }
    }
  }
}

@include lg-screen {
  .container {
    display: flex;

    .notifications-block {
      flex: 1 1 40%;
    }

    .notification {
      position: static;
      flex: 1 0 55%;
      margin-left: var(--indent-base);
    }
  }
}

@keyframes show-notification {
  0% {
    display: none;
    transform: translateX(80%);
    opacity: 0;

  }

  100% {
    display: block;
    transform: translateX(0);
    opacity: 1;
  }
}