.container {
  min-width: 0;
  color: var(--dark-grey);
  font-size: var(--font-size-base);

  .select-container {
    position: relative;
    height: var(--input-height);

    & > div[data-tippy-root] {
      width: 100%;
    }

    .select-item {
      height: 100%;
    }

    .item-in-list {
      &-active {
        color: var(--dark-grey);
        background-color: var(--white-green);
      }

      &-not-available {
        color: var(--light-grey);
        pointer-events: none;
      }
    }
  }

  .checkbox {
    margin-top: var(--indent-base);
    user-select: none;
  }
}