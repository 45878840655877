.container {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: -99;
  max-width: 250px;
  padding: var(--indent-base) var(--indent-medium);
  background-color: var(--secondary);
  box-shadow: var(--box-shadow-pop-up-box);
  border-radius: 4px;
  text-align: center;
  opacity: 0;

  &.ERROR {
    color: var(--red);
  }

  &.shown {
    z-index: 99;
    animation-fill-mode: forwards;
    animation-name: show-alert, hidden-alert;
    animation-delay: 0s, 2s;
    animation-duration: .3s;
  }
}

@keyframes show-alert {
  from {
    opacity: 0;
    transform: translate(-50%, 5px);
  }

  to {
    opacity: 1;
    transform: translate(-50%, 20px);
  }
}

@keyframes hidden-alert {
  from {
    opacity: 1;
    transform: translate(-50%, 20px);
  }

  to {
    opacity: 0;
    transform: translate(-50%, 5px);
  }
}