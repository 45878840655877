@import "src/styles/mixins";

.wrapper {
  position: relative;

  .scroll-to-top {
    position: sticky;
    margin: var(--indent-xxlarge) 0 0 auto;
  }
}

.container {
  .title {
    margin-bottom: var(--indent-medium);
    font-size: var(--indent-base);
    color: var(--dark-grey);
    text-align: center;

    @include md-screen {
      font-size: 20px;
    }
  }

  .text {
    text-align: justify;

    & > h1 {
      margin: var(--indent-large) 0 var(--indent-medium);
      font-size: 18px;
      font-weight: var(--font-weight-bold);
      font-family: var(--noto-sans);
      text-align: center;
      line-height: normal;
    }

    & > h2 {
      font-size: 18px;
    }

    & > h3 {
      font-size: var(--font-size-base);
    }

    & > h2, h3, p:not(:first-child) {
      margin-top: var(--indent-base);
    }

    & > p + h2,
    & > ul + h2 {
      margin-top: var(--indent-large);
    }

    .list {
      list-style: none;
      padding-left: var(--indent-base);

      & > li:not(:first-child) {
        margin-top: var(--indent-base);
      }

      & > li .list {
        margin: 1rem 0;
      }

      .first-nesting:before,
      .second-nesting:before,
      .third-nesting:before {
        display: inline-block;
        margin-right: 12px;
        content: "";
      }

      .first-nesting:before {
        width: 10px;
        height: 10px;
        min-width: 10px;
        background-color: var(--sand-o-70);
      }

      .second-nesting:before,
      .third-nesting:before {
        border-radius: 50%;
      }

      .second-nesting:before {
        width: 12px;
        height: 12px;
        min-width: 12px;
        background-color: var(--sand-o-70);
      }

      .third-nesting:before {
        width: 8px;
        height: 8px;
        min-width: 8px;
        border: 2px solid var(--sand-o-70)
      }
    }

    .link {
      color: var(--primary);
      text-decoration: underline;
      word-break: break-word;
    }

    .updated {
      margin-bottom: var(--indent-large);
      font-style: italic;
    }

    @include md-screen {
      & > h1 {
        font-size: 20px;
      }

      & > h2 {
        font-size: 20px;
      }

      & > h2 {
        font-size: 19px;
      }

      .list {
        padding-left: 40px;
      }
    }
  }
}
