@import "src/styles/mixins";

.container {
  @include box-shadow;
  position: relative;
  display: grid;
  grid-template-rows: 210px auto 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  background-color: var(--secondary);

  &.self {
    .content {
      background-color: var(--light-sand);

      .part-number,
      .name {
        font-weight: var(--font-weight-bold);
      }

      .organization-name,
      .supply-condition {
        color: var(--dark-grey);
      }
    }

    .btn-block {
      background-color: var(--secondary);

      .btn-wrapper {
        background-color: var(--light-sand);
      }
    }
  }

  &.small-product-card {
    position: relative;

    .sold-label {
      position: absolute;
      top: calc(var(--indent-small) * 0.5);
      left: calc(var(--indent-small) * 0.5);
      padding: var(--indent-small) 10px;
      color: var(--red);
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;
      background-color: var(--white-o-80);
    }
  }

  .condition {
    padding: var(--indent-small);
    color: var(--secondary);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-medium);
    text-transform: uppercase;
    background-color: var(--sand-o-80);
  }

  .content {
    @include flex-column;
    padding: var(--indent-small) var(--indent-small) calc(0.75 * var(--indent-base));
    color: var(--grey);
    font-size: var(--font-size-medium);

    & > div {
      &:first-child {
        word-break: break-all;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .part-number,
    .name {
      color: var(--dark-grey);
      font-weight: var(--font-weight-medium);
    }

    .part-number {
      margin-bottom: calc(var(--indent-small) / 2);
      font-size: var(--font-size-base);
    }

    .name,
    .organization-name,
    .supply-condition {
      @include text-overflow-ellipsis-line-clamp-2;
    }

    .name,
    .organization-name {
      height: var(--row-height);
    }

    .organization-name {
      margin: calc(var(--indent-small) / 2) 0;
    }

    .supply-condition {
      margin-bottom: 10px;
    }

    .price-block {
      margin-top: auto;

      .price {
        color: var(--dark-grey);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-base);
        white-space: nowrap;
      }

      .in-stock {
        color: var(--green);
      }

      .out-of-stock {
        color: var(--red);
      }
    }

    .btn {
      width: 100%;
      min-height: var(--btn-secondary-min-height);
      margin-top: var(--indent-base);
    }
  }

  .btn-block {
    @include box-shadow;
    position: absolute;
    bottom: 0;
    z-index: 1;
    display: none;
    width: 100%;
    background-color: var(--secondary);
    transform: translateY(calc(100% - 6px));
    opacity: 0;
    transition: box-shadow 0.3s;

    .btn-wrapper {
      padding: 6px var(--indent-base) var(--indent-base);
    }

    .btn {
      width: 100%;
      min-height: var(--btn-secondary-min-height);
    }
  }
}

@include sm-screen {
  .container {
    .content {
      padding-bottom: var(--indent-base);

      .btn {
        padding: 10px 20px;
      }
    }
  }
}

@media (min-width: 600px) {
  .container {
    .content {
      .btn {
        padding: 10px var(--indent-medium);
      }
    }
  }
}

@include lg-screen {
  .container {
    &.small-product-card {
      .content {
        padding: var(--indent-base) var(--indent-base) var(--indent-large);
      }
    }

    .condition {
      padding: var(--indent-small) var(--indent-base);
      font-size: var(--font-size-base);
    }

    .content {
      padding: var(--indent-base) var(--indent-base) var(--indent-medium);

      .supply-condition {
        margin-bottom: var(--indent-base);
      }
    }
  }
}

@include xl-screen {
  .container:hover {
    box-shadow: var(--box-shadow-right-left-side);

    &.small-product-card {
      box-shadow: var(--box-shadow-hover);
    }

    .btn-block {
      display: block;
      box-shadow: var(--box-shadow-hover);
      opacity: 1;
    }
  }
}