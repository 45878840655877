@import "../../../../../../../../../../styles/mixins";

.container {
  .table-container {
    padding: var(--indent-base) var(--indent-medium);
    background-color: var(--secondary);

    &.hiding-container {
      position: relative;
      pointer-events: none;

      &::after {
        @include hiding-container;
        content: "";
      }
    }

    .table-head {
      display: grid;
      grid-template-columns: 7% repeat(2, 26%) repeat(2, 10.5%) 20%;
      align-items: center;
      padding: 18px 5px 18px 58px;
      color: var(--grey);
      font-size: var(--font-size-medium);
      border-bottom: var(--border-black);

      .column {
        &:not(:first-child) {
          padding-left: var(--indent-small);
        }

        &:nth-child(4) {
          justify-self: flex-end;
          padding-right: var(--indent-large);
        }
      }
    }

    .empty {
      padding: 40px 0 var(--indent-medium);
      color: var(--dark-grey);
      font-size: var(--font-size-medium);
      text-align: center;
    }
  }

  .settings-block {
    margin-top: var(--indent-base);
    padding: var(--indent-medium) var(--indent-medium) var(--indent-base);
    background-color: var(--secondary);

    &.hiding-container {
      position: relative;
      pointer-events: none;

      &::after {
        @include hiding-container;
        content: "";
      }

      .preloader {
        top: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
      }
    }

    .select-block {
      position: relative;
      max-width: 351px;

      & > div[data-tippy-root] {
        width: 100%;
        padding: var(--indent-xsmall) 0;
      }

      .select-item {
        color: var(--grey);
      }

      .item {
        &-active {
          background-color: var(--white-green);
        }

        &-not-available {
          color: var(--light-grey);
          pointer-events: none;
        }
      }
    }

    .suggest-select-preloader {
      display: block;
    }
  }
}