@import "src/styles/mixins";
@import "src/styles/page-container.module.scss";

.page-container {
  position: relative;
}

.container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: var(--indent-xxxlarge) var(--indent-base);

  .title {
    margin-bottom: var(--indent-base);
    font-weight: var(--font-weight-bold);
    text-align: center;

    & > span {
      display: block;

      &:first-child {
        margin-bottom: var(--indent-xsmall);
        text-transform: uppercase;
      }
    }
  }

  .subtitle {
    font-size: var(--font-size-medium);
    color: var(--grey);
    text-align: center;
  }

  .content {
    margin-top: var(--indent-medium);
    background-color: var(--secondary);

    .passwords {
      & > div:last-child {
        margin-top: var(--indent-medium);
      }
    }

    .description {
      margin-top: var(--indent-large);

      .list {
        margin: 0;
        padding-left: var(--indent-medium);
        font-size: var(--font-size-medium);
        font-weight: var(--font-weight-bold);

        & > li {
          font-weight: var(--font-weight-normal);
          margin-top: var(--indent-xsmall);

          &:first-child {
            margin-top: var(--indent-small);
          }
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: var(--indent-medium);

    .confirm-btn,
    .cancel-btn {
      min-width: 200px;
    }

    .confirm-btn {
      min-height: var(--btn-secondary-min-height);
      margin-bottom: var(--indent-base);
    }
  }
}

.required {
  @include required-field-error;
}

.error-block {
  @include response-error-block-on-page;
}

@include sm-screen {
  .container {
    .buttons-container {
      flex-direction: row;
      justify-content: flex-end;
      margin: var(--indent-xxlarge) auto 0;

      .confirm-btn,
      .cancel-btn {
        min-width: 160px;
      }

      .confirm-btn {
        margin-bottom: 0;
        margin-left: var(--indent-large);
      }
    }
  }
}

@include md-screen {
  .container {
    padding: 80px var(--indent-medium);
  }
}

@include lg-screen {
  .container {
    padding: 100px var(--indent-medium) 170px;

    .title {
      font-size: 22px;
    }

    .subtitle {
      font-size: var(--font-size-base);
    }

    .content {
      margin-top: var(--indent-xxlarge);

      .description .list {
        font-size: var(--font-size-base);
      }
    }
  }
}