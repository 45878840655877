@import "src/styles/mixins";

.container {
  .info {
    display: inline-flex;
    align-items: center;
    font-weight: var(--font-weight-semi-bold);
    font-size: var(--font-size-medium);
    cursor: pointer;

    &.moderation {
      color: var(--orange-bright);
    }

    &.reject {
      color: var(--red);
    }

    .info-icon {
      min-width: 18px;
      min-height: 18px;
      margin-right: var(--indent-small);
    }
  }

  .info-content {
    padding: var(--indent-xsmall) 12px;
    color: var(--grey);
    font-size: var(--font-size-small);
  }
}