@import "src/styles/mixins";

.container {
  &.invalid-file {
    .file-block {
      grid-template-rows: auto auto;

      .error-icon {
        margin-bottom: var(--indent-xsmall);
      }
    }
  }

  &.download-file {
    .file-block {
      grid-template-areas:
      "icon name size btn download-btn";
      align-items: center;
    }

    .download {
      grid-area: download-btn;
    }
  }

  .file-block {
    display: grid;
    grid-template-areas:
      "icon name size btn status-icon"
      "icon progressbar progressbar progressbar progressbar";
    grid-template-rows: auto 16px;
    grid-template-columns: 24px auto repeat(2, 100px) 30px;
    align-items: flex-end;
    max-width: 650px;
    color: var(--dark-grey);

    .icon {
      width: 24px;
      height: 24px;
    }

    .name,
    .size {
      align-self: center;
      padding-left: var(--indent-base);
      cursor: default;
    }

    .name {
      min-width: 100px;

      & > [data-tippy-root] > div {
        border-radius: 0 !important;
      }

      &-tooltip {
        text-align: center;
      }
    }

    .size {
      @include text-overflow-ellipsis;
      min-width: 100px;
    }

    .file-delete {
      @include flex-center;
      padding-left: var(--indent-base);
      color: var(--grey);
      font-size: var(--font-size-medium);
      cursor: pointer;

      .delete {
        border-bottom: var(--border-grey);
      }

      &::before {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        background: url("../assets/cross-icons/cross-grey.svg") center no-repeat;
        content: "";
      }

      &-loading {
        @include flex-center-center;

        .delete-preloader {
          position: initial;
          height: 24px;
          transform: none;

          & > div {
            transform-origin: 38px 14px;

            &:after {
              width: 2px;
              height: 5px;
            }
          }
        }
      }
    }

    .status-icon,
    .error-icon,
    .download {
      justify-self: flex-end;
    }

    .status-icon {
      display: inline-block;
      width: 14px;
    }

    .success-icon {
      height: 16px;
      background: url("../assets/error_and_info_message-icons/successful-upload-icon.svg") center no-repeat;
    }

    .error-icon {
      height: 14px;
      background: url("../assets/error_and_info_message-icons/error-upload-icon.svg") center no-repeat;
      background-size: contain;
    }

    .download > div {
      width: 18px;
      height: 18px;
      background: url("../assets/documents-icons/save_icon.svg") center no-repeat;
      border: none;
      cursor: pointer;
    }

    .progressbar {
      display: block;
      grid-area: progressbar;
      padding-left: var(--indent-base);
      visibility: visible;
      opacity: 1;

      & > input[type="range"] {
        width: 100%;
        height: 2px;
        overflow: hidden;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        pointer-events: none;
      }

      & > input[type="range"]::-webkit-slider-runnable-track {
        height: 2px;
        margin-top: -1px;
        color: var(--green);
        background-color: var(--light-grey);
        -webkit-appearance: none;
      }

      & > input[type="range"]::-webkit-slider-thumb {
        width: 0;
        height: 0;
        background: var(--green);
        box-shadow: -1000px 0 0 1000px var(--green);
        -webkit-appearance: none;
      }

      & > input[type="range"]::-moz-range-progress {
        background-color: var(--green);
      }

      & > input[type="range"]::-moz-range-thumb {
        width: 0;
        height: 0;
        background: var(--green);
        box-shadow: -1000px 0 0 1000px var(--green);
        -moz-appearance: none;
      }

      & > input[type="range"]::-moz-range-track {
        background-color: var(--light-grey);
      }

      /* IE*/
      & > input[type="range"]::-ms-fill-lower {
        background-color: var(--green);
      }

      & > input[type="range"]::-ms-fill-upper {
        background-color: var(--light-grey);
      }

      &.hidden {
        visibility: hidden;
        opacity: 0;
        transition-delay: 2s;
        transition-duration: .5s;
      }

      &.removed {
        display: none;
      }
    }
  }

  .error-message {
    @include flex-center;
    align-self: flex-end;
    margin-top: var(--indent-small);
    color: var(--red);
    font-size: var(--font-size-medium);

    &:before {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: var(--indent-small);
      background: url("../assets/error_and_info_message-icons/exclamation-mark.svg") center no-repeat;
      content: "";
    }
  }
}

@media (min-width: 1350px) {
  .container {
    .file-block {
      grid-template-columns: 24px auto repeat(2, 120px) 40px;
    }
  }
}