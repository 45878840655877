@import "src/styles/mixins";

.container {
  position: relative;
  height: var(--input-height);
  border: var(--border-black);
  border-radius: var(--border-radius-input);
  cursor: pointer;

  .label {
    @include text-overflow-ellipsis-line-clamp-2;
    position: absolute;
    top: 0;
    left: var(--indent-small);
    max-width: 90%;
    padding: 0 var(--indent-small);
    color: var(--black-o-60);
    font-size: var(--font-size-small);
    background-color: var(--secondary);
    transform: translateY(-60%);

    &.with-tooltip {
      padding-right: var(--indent-medium);
    }
  }

  .required-icon {
    margin-left: var(--indent-xsmall);
    color: var(--red);
  }

  .calendar-input {
    width: 100%;
    height: 100%;

    & > div[class*="wrapper"] {
      padding: var(--indent-base);
      color: var(--dark-grey);
      font-size: var(--font-size-base);
      font-family: var(--noto-sans);
      border: none;

      & > div[class*="inputGroup"] {
        min-width: auto;

        & > input[class*="input--hasLeadingZero"] {
          padding-left: calc(1px + 0.54rem) !important;
        }
      }

      & > button[class*="calendar-button"] {
        padding: 0;
      }
    }

    & > span {
      & > div[class*="calendar"] {
        inset: 100% auto auto !important;
      }
    }
  }

  .calendar {
    margin-top: var(--indent-small);
  }

  .icon {
    width: 18px;
    min-width: 18px;
    height: 100%;
    background: url("../assets/other-icons/calendar-icon.svg") center no-repeat;
    background-size: contain;
  }
}