@import "src/styles/mixins";

.container {
  &:not(:last-child) {
    margin-bottom: var(--indent-xsmall);
  }

  & > [data-tippy-root] {
    position: relative !important;
    transform: translate(0) !important;

    & > div[class*='tippy-box'] {
      background-color: var(--transparent);
    }
  }

  .accordion-label {
    position: initial;
    padding: 0;
    background-color: var(--light-sand);

    & > div {
      width: 100%;
      max-width: none;
    }

    &::after {
      content: none;
    }
  }

  .accordion-link {
    @include flex-center-between;
    width: 100%;

    &-text {
      display: inline-block;
      padding: 14px var(--indent-small);
      color: var(--dark-grey);
      font-weight: var(--font-weight-normal);
      font-family: var(--russo-one);
    }

    .arrow {
      width: 20px;
      height: 20px;
      margin: 0 var(--indent-small);
      background: url("../../../../../common/assets/chevrons-and-arrows/arrow_right_base.svg") no-repeat;
      background-size: contain;
    }
  }

  .accordion-content {
    margin: var(--indent-xsmall) 0;
    padding: 0;
    background-color: var(--transparent);
  }
}