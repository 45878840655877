@import "../../../../../../../../../styles/mixins";

.container {
  display: grid;
  grid-template-areas: 'name date status action';
  grid-template-columns: 30% 20% 20% 30%;
  align-items: center;
  height: 55px;
  padding: var(--indent-small);
  border-top: var(--border-black);

  .column {
    font-size: var(--font-size-medium);

    &:not(:first-child) {
      color: var(--dark-grey);
      font-weight: var(--font-weight-medium);
    }

    &:not(:last-child) {
      padding-right: var(--indent-base);
    }
  }

  .btn-block {
    @include flex-center-between;
    grid-area: action;

    &.content-center {
      justify-content: center;
    }

    .btn-short {
      flex: 0 0 47%;
      max-width: 47%;
      min-height: 36px;
      padding-right: var(--indent-small);
      padding-left: var(--indent-small);

      & > div,
      & > div > span {
        max-width: 100%;
      }

      & > div > span {
        @include text-overflow-ellipsis;
      }

      &.secondary {
        min-width: 100px;
      }
    }
  }
}