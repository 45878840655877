@import "src/styles/mixins";

.tabs-labels {
  display: flex;
  margin-bottom: 80px;

  .tab {
    width: 100%;

    & > input[type="radio"] {
      display: none;

      &:checked + .label {
        position: relative;
        color: var(--dark-khaki);
        font-weight: var(--font-weight-semi-bold);

        &::before {
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: var(--dark-khaki);
          content: "";
        }
      }
    }

    .label {
      @include flex-center-center;
      height: 100%;
      padding: 0 var(--indent-small) 23px;
      color: var(--grey);
      font-weight: var(--font-weight-medium);
      font-size: 18px;
      text-transform: uppercase;
      border-bottom: var(--border-black-strong);
      cursor: pointer;
    }
  }
}