@import "src/styles/mixins";

.item {
  margin-top: var(--indent-small);
  padding: var(--indent-small);
  background-color: var(--secondary);
  border-bottom: var(--border-black);

  &.self {
    background-color: var(--light-sand);

    .column {
      .title,
      .name {
        font-weight: var(--font-weight-bold);
      }
    }

    .description .content .row .txt {
      color: var(--dark-grey);
    }
  }

  .main-info {
    @include flex-start-between;
  }

  .column {
    .title,
    .name,
    &.condition {
      font-weight: var(--font-weight-medium);
    }

    .title {
      color: var(--dark-grey);
      word-break: break-all;
    }

    .name {
      margin-top: calc(var(--indent-small) / 2);
      font-size: var(--font-size-medium);
    }

    &.title-block {
      margin-right: calc(0.75 * var(--indent-base));
    }

    &.condition {
      display: none;
      padding: 0 var(--indent-base);
    }

    &.price-block {
      flex-shrink: 0;
      text-align: right;

      .price {
        font-weight: var(--font-weight-bold);
      }

      .availability {
        margin: calc(var(--indent-small) / 2) 0;
      }

      .condition {
        font-size: var(--font-size-medium);
      }
    }
  }

  .description {
    @include flex-column;
    flex-basis: 100%;
    margin: var(--indent-base) 0;

    .content {
      flex-basis: 100%;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-medium);

      .row {
        margin-bottom: var(--indent-small);

        .label {
          margin-bottom: calc(var(--indent-small) / 2);
          color: var(--dark-grey);
        }

        .txt {
          color: var(--grey);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .btn-block {
      align-self: flex-end;
      margin-top: var(--indent-base);

      .availability {
        display: none;
      }

      .btn {
        min-height: var(--btn-secondary-min-height);
      }
    }
  }

  .availability {
    font-size: var(--font-size-medium);
  }

  .in-stock {
    color: var(--green);
  }

  .out-of-stock {
    color: var(--red);
  }
}

@include sm-screen {
  .item {
    padding: var(--indent-base);

    .column {
      flex-basis: 35%;

      &.title-block {
        margin-right: 0;
      }

      &.condition {
        display: block;
      }

      &.price-block {
        flex-basis: 30%;

        .condition.private-marketplace-mode {
          display: none;
        }
      }
    }

    .description {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;

      .content {
        flex-basis: 65%;

        .row {
          display: flex;

          .label {
            width: 100%;
            max-width: 120px;
            margin-right: var(--indent-medium);
            margin-bottom: 0;
          }
        }
      }

      .btn-block {
        align-self: auto;
        margin-left: var(--indent-base)
      }
    }
  }
}

@include md-screen {
  .item {
    .column {
      flex-basis: 40%;

      &.price-block {
        flex-basis: 20%;
      }
    }

    .description {
      flex-direction: row;
      margin-bottom: 0;

      .content {
        flex-basis: 75%;
      }

      .btn-block {
        margin-top: 0;
      }
    }
  }
}

@include lg-screen {
  .item {
    .column {
      flex-basis: 42%;

      &.condition {
        padding: 0 var(--indent-large);
      }

      &.price-block {
        flex-basis: 16%;

        .availability {
          display: none;
        }
      }
    }

    .description {
      .btn-block {
        margin-left: 0;

        .availability {
          display: block;
          margin-bottom: calc(var(--indent-small) / 2);
          text-align: right;
        }
      }
    }
  }
}

@include xl-screen {
  .item {
    .description {
      .btn-block {
        .btn {
          opacity: 0;
        }
      }
    }

    &:hover {
      background-color: var(--light-sand-o-40);

      .description .btn-block .btn {
        opacity: 1;
        transition: opacity .5s;
      }
    }
  }
}