@import "src/styles/mixins";

.container {
  position: relative;
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  transition: max-height .3s;

  & > [data-tippy-root] {
    position: static !important;
    z-index: 1 !important;
    transform: none !important;

    & > [class~='tippy-box'] {
      background-color: var(--transparent) !important;
    }
  }

  &.show {
    max-height: 350px;
    visibility: visible;
    opacity: 1;
  }

  .content {
    padding: var(--indent-medium) 0;

    .title {
      margin-bottom: var(--indent-medium);
      color: var(--dark-grey);
      font-size: var(--font-size-medium);
      font-family: var(--russo-one);
      text-transform: uppercase;
    }

    .text {
      color: var(--dark-grey);
      font-size: var(--font-size-medium);
    }
  }
}

.slider-close-btn {
  top: 25px;
  right: var(--indent-xsmall);
}

@include md-screen {
  .container {
    .content {
      padding: 40px 26px;

      .title {
        max-width: 90%;
        font-size: var(--font-size-base);
      }

      .text {
        font-size: var(--font-size-base);
      }
    }
  }

  .slider-close-btn {
    top: 40px;
    right: var(--indent-xsmall);
  }
}