@import "src/styles/mixins";

.container {
  position: relative;
  padding: 12px 12px 12px var(--indent-small);
  background-color: var(--secondary);
  border-bottom: var(--border-grey77);

  &:not(:last-child) {
    margin-bottom: var(--indent-xsmall);
  }

  .hiding {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: var(--white-green-o-50);
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s;

    .preloader {
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.loading {
    .hiding {
      z-index: 1;
      visibility: visible;
      opacity: 1;
    }
  }

  &.hiding-items {
    .hiding {
      z-index: 1;
      visibility: visible;
      opacity: 1;

      .preloader {
        display: none;
      }
    }
  }

  .number {
    font-weight: var(--font-weight-medium);

    &-lg {
      display: none;
    }
  }

  .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;

    &-skeleton {
      width: 100%;
      max-width: 38px;
      height: 26px;
      margin: 0 var(--indent-small) 0 6px;

      &-lg {
        display: none;
        max-width: 110px;
        height: 75px;
        margin: 0 20px 0 var(--indent-base);
      }
    }
  }

  .data-column {
    .title-row {
      display: flex;
      margin-bottom: var(--indent-base);

      .title {
        margin-right: var(--indent-base);
        color: var(--dark-grey);

        .subtitle {
          display: block;
          color: var(--grey);
        }
      }

      .price-block {
        display: none;
      }

      .delete {
        padding: 0;
        background-color: var(--transparent);

        .trash-icon {
          width: 24px;
          height: 24px;
          background: url("../../../../../../../common/assets/other-icons/trash-icon.svg") center no-repeat;
        }

        &-btn-block {
          display: flex;
          align-items: flex-start;
          margin-left: auto;
        }
      }
    }

    .data-row {
      .price-block {
        @include flex-start-between;
        margin-bottom: var(--indent-base);

        .subtotal-block {
          display: flex;
          flex-basis: 40%;
          flex-direction: column-reverse;

          .price {
            color: var(--dark-grey);
            font-size: 10px;
            white-space: nowrap;

            .mark {
              color: var(--grey);
            }
          }

          .counter {
            height: 40px;
            margin-bottom: var(--indent-small);
            padding: 0 12px;

            & > div {
              width: 12px;
              height: 12px;
            }
          }
        }

        .total-block {
          display: grid;
          flex-basis: 55%;
          grid-template-rows: 40px auto;
          justify-items: flex-end;

          .total {
            align-self: center;
            color: var(--dark-grey);
            font-weight: var(--font-weight-semi-bold);
            font-size: var(--font-size-medium);
          }

          .show-offers {
            align-self: flex-end;
            max-width: 80%;
            font-size: 8px;

            &.active {
              color: var(--dark-red);
            }
          }
        }
      }

      .description {
        .item {
          margin-bottom: 6px;
          font-weight: var(--font-weight-medium);
          font-size: var(--font-size-medium);

          .label {
            margin-bottom: var(--indent-xsmall);
            color: var(--dark-grey);
          }

          .value {
            color: var(--grey);
          }
        }
      }

      .btn-block {
        display: none;

        .show-offers {
          font-size: 8px;

          &.active {
            color: var(--dark-red);
          }
        }

        .delete {
          @include flex-center;
          padding: 0;
          color: var(--grey);
          font-size: var(--font-size-medium);
          font-family: var(--noto-sans);
          text-transform: capitalize;
          background-color: var(--transparent);

          .cross-icon {
            display: inline-block;
            width: 9px;
            height: 9px;
            margin-right: 6px;
            background: url("../../../../../../../common/assets/cross-icons/cross-grey.svg") 100% / 100% no-repeat;
          }

          &:after {
            right: 0;
            width: calc(100% - 14px);
            background-color: var(--grey);
          }

          &-btn-block {
            @include flex-center;
            height: 24px;
          }
        }
      }
    }
  }
}

.delete-preloader {
  position: initial;
  width: 60px;
  height: 24px;
  transform: none;

  & > div {
    transform-origin: 38px 14px;

    &:after {
      width: 2px;
      height: 5px;
    }
  }
}

@include md-screen {
  .container {
    display: flex;
    padding: 14px var(--indent-medium) var(--indent-large);

    &:not(:last-child) {
      margin-bottom: 0;
    }

    .number {
      display: none;

      &-lg {
        display: block;
      }
    }

    .img-skeleton {
      display: none;

      &-lg {
        display: block;
      }
    }

    .data-column {
      width: 100%;

      .title-row {
        .title {
          flex-basis: 45%;
          flex-shrink: 0;
          margin-right: var(--indent-medium);
          font-weight: var(--font-weight-semi-bold);

          .subtitle {
            display: initial;
            color: var(--dark-grey);
          }
        }

        .price-block {
          @include flex-start-between;
          flex-grow: 1;

          .subtotal-block {
            display: flex;
            flex-direction: column-reverse;

            .price {
              margin-right: var(--indent-small);
              color: var(--dark-grey);
              font-size: var(--font-size-medium);
              white-space: nowrap;

              .mark {
                color: var(--grey);
              }
            }

            .counter {
              max-width: 150px;
              height: 42px;
              padding: 0 var(--indent-base);
            }
          }

          .total-block {
            margin-left: var(--indent-medium);

            .total {
              color: var(--dvaark-grey);
              font-weight: var(--font-weight-bold);
              white-space: nowrap;
            }
          }

          .show-offers {
            display: none;
          }
        }

        .delete {
          &-btn-block {
            display: none;
          }
        }
      }

      .data-row {
        @include flex-start-between;

        .price-block {
          display: none;
        }

        .description {
          .item {
            @include flex-start-between;

            .label {
              flex-basis: 45%;
              margin-bottom: 0;
            }

            .value {
              flex-basis: 50%;
            }
          }
        }

        .btn-block {
          display: flex;
          flex-basis: 134px;
          flex-direction: column;
          flex-shrink: 0;
          align-items: flex-end;
          justify-content: space-between;

          .delete {
            &-btn-block {
              margin-top: auto;
            }
          }
        }
      }
    }
  }
}

@include xl-screen {
  .container {
    .data-column {
      .title-row {
        .title {
          flex-basis: 40%;
        }

        .price-block {
          .subtotal-block {
            flex-basis: 70%;
            flex-direction: row;
            justify-content: space-between;

            .price {
              font-size: var(--font-size-base);
            }
          }
        }
      }

      .data-row {
        .description {
          flex-grow: 1;
          margin-right: var(--indent-xxlarge);

          .item {

            .label {
              flex-basis: 35%;
            }

            .value {
              flex-basis: 63%;
            }
          }
        }
      }
    }
  }
}