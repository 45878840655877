@import "../../../../../../../../../styles/mixins";

.container {
  width: 100%;
}

.name {
  padding: 0 var(--indent-small) var(--indent-small);
  font-size: var(--font-size-medium);
}

.list-header {
  @include flex-start-between;
  padding: var(--indent-small);
  color: var(--half-black);
  font-size: var(--font-size-small);
  text-transform: uppercase;
  background-color: var(--secondary);
  border-bottom: var(--border-black);

  .column {
    flex-basis: calc(100% / 3);

    &:nth-child(2) {
      display: none;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.list-items {
  & > div {
    margin-top: 0;
  }
}

@include sm-screen {
  .list-header {
    padding: 18px var(--indent-base);

    .column {
      flex-basis: 35%;

      &:nth-child(2) {
        display: block;
        padding: 0 var(--indent-base);
      }

      &:last-child {
        flex-basis: 30%;
      }
    }
  }
}

@include md-screen {
  .list-header {
    .column {
      flex-basis: 40%;

      &:last-child {
        flex-basis: 20%;
        text-align: center;
      }
    }
  }
}

@include lg-screen {
  .container {
    padding: calc(2.5 * var(--indent-base)) 0;
  }

  .list-header {
    font-size: var(--font-size-medium);

    .column {
      flex-basis: 42%;

      &:nth-child(2) {
        padding: 0 var(--indent-large);
      }

      &:last-child {
        flex-basis: 16%;
      }
    }
  }

  .name {
    padding: 0 var(--indent-base) var(--indent-medium);
    font-size: var(--font-size-base);
  }
}