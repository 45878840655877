@import "../../../../../../../styles/mixins";

.container {
  padding: 10px var(--indent-base) var(--indent-base) var(--indent-medium);
  color: var(--grey);
  background-color: var(--secondary);
  cursor: pointer;

  &.open {
    background-color: var(--white-green);
  }

  .sender {
    margin-bottom: var(--indent-xsmall);
    font-size: var(--font-size-small);
    text-transform: uppercase;
  }

  .topic-block {
    @include flex-start-between;
    margin-bottom: 12px;
    font-size: var(--font-size-small);

    .topic {
      color: var(--dark-grey);
      font-weight: var(--font-weight-semi-bold);
      text-transform: uppercase;
      word-break: break-word;

      &.unread {
        position: relative;

        &::before {
          position: absolute;
          top: 50%;
          left: -12px;
          width: 8px;
          height: 8px;
          background-color: var(--red);
          border-radius: 50%;
          transform: translateY(-50%);
          content: "";
        }
      }
    }

    .date {
      flex-shrink: 0;
      padding-left: var(--indent-base);
    }
  }

  .message {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

@include xl-screen {
  .container {
    &:hover {
      background-color: var(--white-green);
    }
  }
}