@import "src/styles/mixins";

.list-button {
  width: 24px;
  height: 24px;
  background: url("../../../../../../../common/assets/other-icons/dots.svg") center no-repeat;
  cursor: pointer;
}

.options-block {
  min-width: 220px;

  .item {
    display: block;
    width: 100%;
    color: var(--dark-grey);
    font-family: var(--noto-sans);
    font-size: 18px;
    text-transform: none;
    text-align: left;

    & > div > span {
      letter-spacing: 0.15px;
    }

    &.disabled {
      position: relative;
      cursor: default;

      &:after {
        @include hiding-container;
        content: "";
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}

@include md-screen {
  .list-button {
    transform: scale(1.3);
  }
}