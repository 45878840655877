@import "../../../../../../../../../styles/mixins";

.container {
  display: grid;
  grid-template-columns: 30% 20% 15% 15% 20%;
  align-items: center;
  min-height: 54px;
  padding: var(--indent-small);
  border-bottom: var(--border-black);

  .column {
    color: var(--dark-grey);
    font-size: var(--font-size-medium);

    &:not(:first-child) {
      padding-left: var(--indent-small);
      font-weight: var(--font-weight-medium);
    }


    &.days {
      color: var(--red);
      font-weight: var(--font-weight-semi-bold);
      font-size: var(--font-size-base);
      text-align: center;
    }

    &.status > div:first-child {
      margin-top: 2px;
    }

    &.button-block {
      @include flex-center-center;
    }
  }
}

@include xl-screen {
  .container:hover {
    background-color: var(--white-green);
  }
}