@import "src/styles/mixins";

.container {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: calc(3.5 * var(--indent-base));

  &.disabled {
    pointer-events: none;
  }

  & > [data-tippy-root] {
    position: absolute !important;
    left: 50% !important;
    width: 100vw;
    transform: translate(-50%, 62px) !important;
  }
}

.input-block {
  flex: 1 1 25%;
  max-width: none;
  height: 100%;
  margin: 0;
  border: var(--border-black);

  & > [data-tippy-root] {
    position: absolute !important;
    width: 100%;
    transform: translate3d(0, 60px, 0) !important;

    div[class*="proposed-option"] {
      & > div[class*="search-icon"] {
        margin-right: 12px;
      }
    }
  }
}

.input {
  padding: var(--indent-base);
  padding-right: var(--indent-large);
  color: var(--primary);
  font-size: var(--font-size-base);
  border: none;

  &::placeholder {
    color: var(--grey);
    font-size: var(--font-size-medium);
    text-transform: uppercase;
  }
}

.filter {
  @include flex-center;
  flex: 1 1 25%;
  background-color: var(--transparent);
  border-left: none !important;

  &.disabled {
    position: relative;
    pointer-events: none;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: var(--white-o-40);
      content: "";
    }
  }

  .skeleton-preloader {
    max-width: 100px;
    max-height: 19px; // height block with text (uppercase)
  }
}