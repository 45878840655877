@import "src/styles/mixins";

.container {
  padding: var(--indent-large) var(--indent-medium) 32px;
  background: var(--secondary);

  .password-block {
    display: flex;

    .password {
      flex-basis: 45%;

      & > div:last-child {
        margin-top: var(--indent-medium);
      }
    }

    .description {
      margin-left: var(--indent-large);

      .list {
        margin: 0;
        padding-left: var(--indent-medium);
        font-size: var(--font-size-base);
        font-weight: var(--font-weight-bold);

        & > li {
          font-weight: var(--font-weight-normal);
          margin-top: var(--indent-xsmall);

          &:first-child {
            margin-top: var(--indent-small);
          }
        }
      }
    }
  }

  .two-factor {
    margin-top: var(--indent-large);
    padding-top: var(--indent-large);
    border-top: 2px solid var(--grey77);
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    .save-btn {
      min-height: var(--btn-secondary-min-height);
      margin-left: var(--indent-large);
    }
  }
}

.required {
  @include required-field-error;
}

.error-block {
  @include response-error-block-on-page;
}