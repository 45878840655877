@import "src/styles/mixins";

.container {
  @include more-about-general-params;
}

.title,
.subtitle {
  &.not-capitalize-text {
    text-transform: none;
  }
}

.subtitle {
  margin-bottom: var(--indent-large);
  font-weight: var(--font-weight-normal);
  font-family: var(--russo-one);
  text-transform: capitalize;
  line-height: normal;
}

@include sm-screen {
  .subtitle {
    font-size: 18px;
  }
}

@include lg-screen {
  .subtitle {
    font-size: 26px;
    margin-bottom: 80px;
  }
}