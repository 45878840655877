.container {
  position: relative;

  & > [data-tippy-root] {
    position: static !important;
    transform: translate(0) !important;
  }

  .label {
    transition: background-color .3s;

    &-active {
      background-color: var(--white-green);
    }
  }
}