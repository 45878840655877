@import "../../../../../../../../styles/mixins";

.main-preloader {
  position: relative;
}

.container {
  color: var(--dark-grey);

  .certificates {
    .item-block {
      .certificate {
        display: grid;
        grid-column-gap: var(--indent-medium);
        grid-template-columns: 55px auto;
        align-items: center;

        .certificate-logo {
          @include flex-column;
          align-items: center;
          justify-content: center;
        }

        .logo-img {
          width: 50px;
          height: 50px;

          & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .logo-name {
          color: var(--grey);
          font-size: var(--font-size-small);
        }

        .dropzone {
          max-width: 300px;
        }
      }

      .certificate + .certificate {
        margin-top: var(--indent-medium);
      }
    }

    .files-block {
      max-width: 80%;
      margin: var(--indent-base) 0 var(--indent-medium);

      .file {
        &:not(:first-child) {
          margin-top: var(--indent-small);
        }
      }
    }
  }

  .btn-group {
    display: grid;
    grid-column-gap: var(--indent-large);
    grid-template-columns: repeat(2, 130px);
    justify-content: flex-end;
    margin-top: 40px;

    & > button {
      min-height: var(--btn-secondary-min-height);
    }
  }
}