@import "../../../../styles/mixins";

.container {
  display: none;

  .user-block {
    margin-bottom: var(--indent-xsmall);
    padding: 12px var(--indent-small);
    font-size: var(--font-size-medium);
    border-bottom: var(--border-black);

    .name {
      display: flex;
      color: var(--dark-grey);
      font-weight: var(--font-weight-semi-bold);
      text-transform: uppercase;

      .icon {
        margin: 0 var(--indent-small);
      }
    }

    .email {
      @include text-overflow-ellipsis;
      margin-top: var(--indent-small);
      color: var(--grey);
      font-weight: var(--font-weight-thin);
    }
  }

  .menu-items {
    .item {
      color: var(--grey);
      border: none;

      &.active {
        color: var(--dark-grey);
        font-weight: var(--font-weight-semi-bold);
        background-color: var(--white-green);
      }

      &.with-icon {
        justify-content: flex-start;

        &::after {
          margin-left: 20px;
        }
      }
    }
  }
}

@media (min-width: 810px) {
  .container {
    display: block;
  }
}