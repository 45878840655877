@import "src/styles/mixins";

.container {
  @include flex-start-end;
  position: relative;
  margin-bottom: var(--indent-medium);

  .option {
    font-size: 1.125rem;

    &:last-child {
      padding-right: 0;
    }

    .skeleton-preloader {
      max-width: 100px;
      max-height: 24px; // height block with text (uppercase)
    }
  }
}