@import "../../../../../../../../styles/mixins";

.main-preloader {
  position: relative;
}

.container {
  @include flex-column;
  min-height: 276px;
  color: var(--dark-grey);

  &.without-min-height {
    min-height: 0;
  }

  .dropzone {
    max-width: 350px;
    min-height: 200px;

    &.dropzone-small {
      min-height: 84px;
    }
  }

  .title,
  .subtitle {
    margin-top: var(--indent-base);
    font-size: var(--font-size-medium);
  }

  .title {
    font-weight: var(--font-weight-semi-bold);
  }

  .subtitle {
    & + .photo-block {
      margin-top: var(--indent-small);
    }
  }

  .photo-block {
    display: grid;
    grid-gap: var(--indent-base);
    grid-template-columns: repeat(auto-fill, 100px);
    margin-top: var(--indent-base);

    .img-wrapper {
      position: relative;
      width: 100px;
      height: 100px;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      .hiding-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--half-black);
        opacity: 0;
        transition: opacity .3s;

        &.show {
          opacity: 1;
        }

        .delete-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 1;
          width: 14px;
          height: 18px;
          background: url("../../../../../../../common/assets/other-icons/trash-icon-with-cross.svg") center no-repeat;
          transform: translate(-50%, -50%);
          visibility: hidden;
          cursor: pointer;
          opacity: 0;
          transition: .3s;
        }
      }

      &.with-hover {
        &:hover {
          .hiding-container {
            opacity: 1;

            .delete-btn {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }

      .preloader {
        top: 50%;
        right: auto;
        left: 50%;
        width: 24px;
        height: 24px;
        transform: translate(-50%, -50%);

        & > div {
          transform-origin: 12px 12px;

          &:after {
            top: 2px;
            left: 11px;
            width: 2px;
            height: 5px;
            background: var(--secondary);
          }
        }
      }
    }
  }

  .btn-group {
    display: grid;
    grid-column-gap: var(--indent-large);
    grid-template-columns: repeat(2, 130px);
    justify-content: flex-end;
    margin-top: 40px;

    & > button {
      min-height: var(--btn-secondary-min-height);
    }
  }
}