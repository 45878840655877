@import "src/styles/mixins";

.container {
  display: grid;
  grid-template-columns: 692px auto;
  grid-column-gap: var(--indent-large);
  padding: 40px var(--indent-medium) 64px;
  background: var(--secondary);

  .grid-group {
    display: grid;
    grid-row-gap: var(--indent-medium);
    grid-column-gap: var(--indent-large);
    grid-template-columns: repeat(2, 1fr);
  }

  .radio-group {
    transform: translateY(-10px);
  }

  .access-btn {
    margin: 0 auto auto 0;
  }
}

@media (min-width: 1350px) {
  .container {
    grid-column-gap: var(--indent-xxlarge);
  }
}