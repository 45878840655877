@import "../../../../styles/mixins";

.item {
  display: block;
  padding: 14px var(--indent-base);
  color: var(--dark-grey);
  font-size: var(--font-size-base);
  cursor: pointer;
  line-height: 24px;

  &:not(:last-child) {
    border-bottom: var(--border-black);
  }

  &.disabled {
    color: var(--white-red);
  }

  &.with-icon {
    &-notification,
    &-logout {
      @include flex-start-between;
    }

    &-notification {
      .icon {
        flex-shrink: 0;
        display: inline-block;
        max-width: 36px;
        min-width: 24px;
        height: 24px;
        padding: 0 4px;
        font-weight: var(--font-weight-bold);
        color: var(--secondary);
        background-color: var(--red);
        text-align: center;
        @include text-overflow-ellipsis;
      }
    }

    &-logout {
      @include flex-start-between;

      &:after {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url("../../assets/other-icons/logout-icon.svg");
        content: "";
      }
    }
  }
}

@include xl-screen {
  .item {
    &:hover:not(.disabled) {
      color: var(--dark-grey);
      background: var(--white-green);
    }
  }
}