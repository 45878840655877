@import "../../../../../../../../styles/mixins";

.preloader {
  position: relative;
}

.form {
  position: relative;
  color: var(--dark-grey);

  .section {
    margin-bottom: var(--indent-large);
    padding-bottom: var(--indent-large);
    border-bottom: 2px solid var(--grey77);

    & > div:last-child {
      margin-bottom: 0;
    }
  }

  .radio-group {
    margin-bottom: var(--indent-large);

    & > div:last-child {
      @include flex-start-between;
      max-width: 95%;

      & > label {
        margin-left: 0;
      }
    }
  }

  .checkbox-group {
    margin-bottom: 40px;
  }

  .grid-group {
    display: grid;
    grid-row-gap: var(--indent-small);
    grid-column-gap: var(--indent-base);
    margin-bottom: var(--indent-medium);
  }

  .grid-group-of-three {
    display: grid;
    grid-column-gap: var(--indent-base);
    grid-template-areas:
            'select-input datepicker'
            'select-input input-text';
    grid-template-columns: auto 224px;
    margin-bottom: var(--indent-medium);

    & > div:nth-child(1) {
      grid-area: select-input;
    }

    & > div:nth-child(2) {
      grid-area: datepicker;
    }

    & > div:nth-child(3) {
      grid-area: input-text;
      margin-top: var(--indent-base);
    }
  }

  .group {
    margin-bottom: var(--indent-medium);
  }

  .group-2 {
    grid-template-columns: auto 224px;
  }

  .group-even-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .group-even-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .btn-group {
    position: relative;
    display: grid;
    grid-column-gap: var(--indent-large);
    grid-template-columns: repeat(2, 130px);
    justify-content: flex-end;
    margin-top: 40px;

    .error-text {
      position: absolute;
      top: -6px;
      right: 0;
      color: var(--red);
      transform: translateY(-100%);
      @include flex-center;

      &:before {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        margin-right: 12px;
        background-image: url("../../../../../../../common/assets/error_and_info_message-icons/exclamation-mark.svg");
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
      }
    }

    .btn-submit {
      min-height: var(--btn-secondary-min-height);
    }
  }

  .section-hiding-container {
    @include hiding-container;
    z-index: 2;
    height: calc(100% + 24px);
    transform: translateY(-24px);

    .preloader {
      position: absolute;
      top: 30%;
      transform: translate(-50%, -50%);
    }
  }
}

.input-pnum-container {
  .input-pnum {
    position: relative;
    height: var(--input-height);
    border: var(--border-black);
    border-radius: var(--border-radius-input);

    & > div[data-tippy-root] {
      width: 100%;
    }

    .input {
      width: 100%;
      height: 100%;
      padding: var(--indent-base);
      color: var(--dark-grey);
      font-size: var(--font-size-base);
      font-family: var(--noto-sans);
      border: none;
      cursor: pointer;

      &:read-only + .read-only {
        display: block;
      }
    }

    .label {
      @include text-overflow-ellipsis-line-clamp-2;
      position: absolute;
      top: 0;
      left: var(--indent-small);
      max-width: 90%;
      padding: 0 var(--indent-small);
      color: var(--black-o-60);
      font-size: var(--font-size-small);
      background-color: var(--secondary);
      transform: translateY(-60%);
    }

    .required-icon {
      margin-left: var(--indent-xsmall);
      color: var(--red);
    }

    .read-only {
      @include read-only-input;
    }
  }

  .content {
    position: relative;
    background-color: var(--secondary);
    box-shadow: var(--box-shadow-input);

    .suggested-item {
      @include flex-center;
      padding: var(--indent-small) 18px;
      color: var(--grey);
      cursor: pointer;

      &:hover {
        background: var(--white-green);
      }
    }
  }
}