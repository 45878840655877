@import "src/styles/mixins";

.options-block {
  width: 260px;
}

.link {
  display: inline-block;
  width: 100%;
  cursor: pointer;

  &.disabled {
    color: var(--white-red);
    cursor: default;
  }
}

.btn {
  min-height: var(--btn-secondary-min-height);
}

@include xl-screen {
  .disabled:hover {
    background-color: transparent !important;
  }
}