@import "src/styles/mixins";

.container {
  &.read-only {
    padding-top: 0;
    div[class*='ck-toolbar'] {
      display: none;
    }
  }

  div[class*='ck-toolbar'],
  div[class*='ck-content'] {
    border: none;
  }

  div[class*='ck-focused'] {
    border: none !important;
    box-shadow: none !important;
  }
}

.label {
  @include text-overflow-ellipsis-line-clamp-2;
  position: absolute;
  top: 0;
  left: var(--indent-small);
  z-index: 1;
  max-width: 90%;
  padding: 0 var(--indent-small);
  color: var(--black-o-60);
  font-size: var(--font-size-small);
  background-color: var(--secondary);
  transform: translateY(-60%);
}