@import "src/styles/mixins";

.page-container {
  min-height: var(--container-min-height-xs-device);
}

@include md-screen {
  .page-container {
    min-height: var(--container-min-height-md-device);
  }
}