@import "../../../../../../../styles/mixins";

.title {
  margin-bottom: var(--indent-medium);
  color: var(--dark-grey);
  font-size: 20px;
  font-family: var(--russo-one);
  text-transform: uppercase;
}

.required-message {
  margin-bottom: var(--indent-small);
  color: var(--grey);

  .required-symbol {
    color: var(--red);
  }
}

.sections {
  position: relative;
  display: grid;
  grid-column-gap: 45px;
  grid-template-columns: 752px 355px;

  .clear-form {
    margin-top: 80px;
  }
}

.hiding-container {
  @include hiding-container;
}

@media (min-width: 1224px) {
  .sections {
    grid-column-gap: 58px;
    grid-template-columns: 752px 365px;
  }
}

@media (min-width: 1280px) {
  .sections {
    grid-template-columns: 752px 420px;
  }
}