@import "src/styles/page-container.module.scss";

.container {
  padding-bottom: var(--indent-xlarge);
}

.date {
  color: var(--black-o-60);
  font-size: var(--font-size-medium);
}

.content {
  text-align: center;
  margin-top: 40px;

  .main-image {
    width: 100%;
    max-width: 420px;
    min-height: 310px;
    margin-bottom: var(--indent-base);
  }

  .body {
    text-align: justify;

    & > h1, h2, h3, h4 {
      font-family: var(--noto-sans);
      font-weight: var(--font-weight-bold);
    }

    .link {
      color: var(--black-o-87);
      font-weight: var(--font-weight-bold);
    }

    .image {
      width: 100%;
      max-height: 300px;
      object-fit: contain;

      &-container {
        text-align: center;
      }
    }

    /* set to default value for browser */
    & > h1 {
      font-size: 2em;
      margin: 0.67em 0;
    }

    & > h2 {
      margin: 0.83em 0;
    }

    & > h3 {
      margin: 1em 0;
    }

    & > h4 {
      margin: 1.33em 0;
    }

    & > p {
      margin: 1em 0;
    }

    & > *:nth-child(1) {
      margin-top: 0;
    }
  }
}

.table, .table td, .table tr {
  border: var(--border-black);
  border-collapse: collapse;
}

.table-wrapper {
  overflow-x: auto;
  padding-bottom: var(--indent-base);
}

.table {
  min-width: 1000px;
  text-align: center;

  .tr {
    &:nth-child(4) {
      .td:first-child {
        width: 5%;
      }

      .td:nth-child(2) {
        width: 35%;
      }
    }

    &:nth-child(6) {
      .td:nth-child(3) {
        width: 15%;
      }
    }

    &:nth-child(-n + 2) {
      font-weight: var(--font-weight-bold);
    }

    &:not(:nth-child(-n + 3)):not(:nth-child(7)):not(:nth-child(11)):not(:nth-child(13)):not(:nth-child(16)) {
      .td:nth-child(2) {
        text-align: left;
      }
    }
  }

  .td {
    padding: var(--indent-small);
  }
}

.btn {
  display: block;
}

@include md-screen {
  .container {
    padding-bottom: var(--indent-xxlarge);
  }

  .content {
    .main-image {
      float: left;
      margin-right: var(--indent-medium);
      margin-bottom: var(--indent-medium);
    }
  }
}

@include lg-screen {
  .title {
    line-height: var(--line-height-2rem);
  }
}