@import "src/styles/mixins";

.container {
  padding: var(--indent-base) var(--indent-small);
  background-color: var(--secondary);

  .img {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    margin-right: 12px;
    object-fit: contain;
    object-position: top;

    &-lg {
      display: none;
    }
  }

  .data-block {
    display: flex;
    color: var(--dark-grey);
    font-size: var(--font-size-medium);

    .price {
      font-weight: var(--font-weight-semi-bold);
      font-size: var(--font-size-medium);
      white-space: nowrap;
    }

    .title-row {
      flex-grow: 1;

      .title-block {
        .title {
          margin-bottom: var(--indent-xsmall);

          .subtitle {
            color: var(--grey);
          }
        }

        .price {
          display: none;
        }
      }

      .price-row-md {
        @include flex-start-between;

        .replace-btn {
          max-width: 25%;
          margin-left: var(--indent-medium);
          font-size: 8px;
        }
      }
    }


    .description-block {
      display: none;
    }
  }

  .description-block {
    margin-top: var(--indent-base);

    .description {
      .item {
        @include flex-start-between;
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-small);

        .label {
          flex-basis: 40%;
          min-width: 120px;
          margin-bottom: 0;
          color: var(--dark-grey);
        }

        .value {
          @include text-overflow-ellipsis;
          flex-basis: 55%;
          color: var(--grey);
        }
      }
    }

    .replace-btn {
      display: none;
      font-size: 8px;
    }
  }
}

@include md-screen {
  .container {
    padding: 12px;
    border: 1px solid var(--light-grey);

    .img {
      display: none;

      &-lg {
        display: block;
        float: left;
        width: 65px;
        height: 56px;
        margin-right: 0;
      }
    }

    .description-block {
      display: none;
    }

    .data-block {
      display: inline-block;
      width: calc(100% - 65px - 12px); // minus image width and container right padding
      margin-left: 12px;

      .title-row {
        .title-block {
          color: var(--primary);
          font-weight: var(--font-weight-semi-bold);
          font-size: var(--font-size-medium);

          .title {
            display: inline-block;
            max-width: 48%;

            .subtitle {
              display: inline;
              color: var(--primary);
            }
          }

          .price {
            display: inline-block;
            float: right;
            max-width: 48%;
            margin-left: var(--indent-medium);
          }
        }

        .price-row-md {
          display: none;
        }
      }

      .description-block {
        position: relative;
        display: block;

        .description {
          display: inline-block;
          max-width: 70%;
        }

        .replace-btn {
          position: absolute;
          right: 0;
          bottom: 0;
          display: inline-block;
          max-width: 25%;
        }
      }
    }
  }
}
