@import "src/styles/mixins";

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  &.loading {
    align-items: center;
  }
}

.skeleton {
  font-size: var(--font-size-base);
  border-radius: 2px;
  max-width: 120px;
  min-height: 12px;
}

.link,
.delimiter {
  color: var(--grey);
  font-size: var(--font-size-base);
  line-height: var(--line-height-1rem-half);

  &-active {
    color: var(--blue);
  }
}

.delimiter {
  margin: 0 calc(var(--indent-base) / 4);
}

@include lg-screen {
  .breadcrumbs {
    margin-bottom: 44px;
  }
}