@import "../../../../../../../../../styles/mixins";

.row {
  @include flex-start-between;
  position: relative;
  padding: var(--indent-base);
  padding-right: 116px;
  border-bottom: var(--border-black);

  &:first-child {
    .label,
    .description {
      font-weight: var(--font-weight-semi-bold);
    }
  }

  .label {
    flex: 0 0 45%;
    color: var(--dark-grey);
    font-size: var(--font-weight-medium);
  }

  .description {
    flex: 0 0 50%;
    color: var(--grey);
  }

  .checkbox {
    position: absolute;
    top: var(--indent-base);
    right: 43px;
  }
}