@import "../../../styles/mixins";

.section-container {
  position: relative;
  width: 100%;
  margin-top: 20px;

  .title {
    margin-bottom: var(--indent-medium);
    font-size: 20px;
    text-transform: capitalize;
  }

  .content {
    position: relative;
    display: grid;
    min-height: 150px;

    &.grid-for-card {
      grid-gap: 20px;
      grid-template-columns: 1fr;
    }

    &.grid-for-icons {
      grid-gap: var(--indent-large) var(--indent-base);
      grid-template-columns: repeat(3, 1fr);
      padding-top: var(--indent-medium);
    }

    .preloader {
      align-self: center;
    }
  }

  .btn {
    margin-top: var(--indent-medium);
    margin-left: auto;
  }
}

@media (min-width: 520px) {
  .section-container .content {
    &.grid-for-card {
      grid-template-columns: repeat(2, 1fr);
    }

    &.grid-for-icons {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@include sm-screen {
  .section-container .title {
    font-size: 22px;
  }
}

@include md-screen {
  .section-container {
    .content {
      &.grid-for-icons {
        grid-gap: calc(3 * var(--indent-base)) var(--indent-medium);
        grid-template-columns: repeat(6, 1fr);
        padding: var(--indent-large) 0 var(--indent-xlarge);
      }
    }

    .btn {
      display: flex;
      align-self: flex-end;
      margin-top: var(--indent-medium);
      margin-left: auto;
    }
  }
}

@media (min-width: 900px) {
  .section-container {
    .content {
      &.grid-for-card {
        grid-gap: var(--indent-medium);
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@include lg-screen {
  .section-container {
    .title {
      font-size: 24px;
    }

    .content {
      &.grid-for-card {
        grid-gap: var(--indent-large);
        margin-bottom: var(--indent-large);
      }
    }
  }
}

@include xl-screen {
  .section-container .title {
    font-size: 30px;
  }
}

@keyframes show-more-content {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}