@import "src/styles/mixins";

.container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .skeleton {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  &.text {
    width: auto;
    height: auto;
  }

  &.loading {
    .skeleton {
      z-index: 1;
      background-color: var(--light-grey);

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
        transform: translateX(-100%);
        animation: shimmer 2s infinite;
        content: "";
      }
    }

    &.text {
      display: inline-block;
      border-radius: var(--border-radius-skeleton);
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}