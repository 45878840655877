@import "src/styles/mixins";

.container {
  display: inline-block;
  cursor: pointer;

  &.read-only {
    pointer-events: none;

    .switch,
    .checkbox:checked + .switch {
      background-color: var(--silver);

      &::before {
        background-color: var(--light-grey);
      }
    }
  }

  &.no-transition {
    .switch {
      transition: none;

      &::before {
        transition: none;
      }
    }
  }

  .checkbox {
    display: none;

    &:checked + .switch {
      background-color: var(--alluminium);

      &::before {
        background-color: var(--dark-khaki);
        left: calc(100% - 20px);
      }
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 14px;
    border-radius: 7px;
    background-color: var(--grey);
    transition: .3s;

    &::before {
      position: absolute;
      display: inline-block;
      top: -3px;
      left: 0;
      width: 20px;
      height: 20px;
      background-color: var(--light-grey);
      border-radius: 50%;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
      transition: .3s;
      content: "";
    }
  }

  .label {
    margin-left: var(--indent-small);
    user-select: none;
  }
}