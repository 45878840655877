@import "src/styles/mixins";

.accordion {
  &-container {
    &:not(:last-child) {
      margin-bottom: var(--indent-base);
    }
  }

  &-label {
    background-color: var(--light-sand);
    cursor: pointer;

    &:after {
      right: var(--indent-base);
    }
  }

  &-content {
    padding: var(--indent-base) 0 0 0;
    background-color: var(--transparent);
  }
}