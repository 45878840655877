@import "src/styles/mixins";

.container {
  @include flex-column;
  @include box-shadow;
  position: relative;
  justify-content: flex-end;
  height: 150px;
  padding: var(--indent-base);
  background-color: var(--secondary);

  & > img {
    position: absolute;
    top: 35%;
    left: 50%;
    width: 120px;
    transform: translate(-50%, -50%);
  }

  &.no-link {
    box-shadow: none;
  }

  .hiding-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: var(--white-o-40);
  }
}

.title {
  color: var(--primary);
  font-size: var(--font-size-small);
  text-align: center;
  text-transform: uppercase;
}

@include lg-screen{
  .container {
    height: 180px;

    & > img {
      width: 110px;
    }
  }
}

@include xl-screen {
  .container {
    &:hover {
      box-shadow: var(--box-shadow-all-side);
    }

    &.no-link:hover {
      box-shadow: none;
    }
  }

  .title {
    font-size: var(--font-size-medium);
  }
}