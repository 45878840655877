@import "src/styles/mixins";

.radio {
  @include flex-center;
  position: relative;
  cursor: pointer;

  &::before {
    @include flex-center-center;
    position: relative;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    margin-right: var(--indent-small);
    border: var(--checkbox-border);
    border-radius: var(--border-radius-circle);
    content: '';
  }

  &.read-only {
    pointer-events: none;

    &:before {
      border-color: var(--grey34-o-40);
    }

    & > span {
      color: var(--grey34-o-80);

      & > span:before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: var(--white-o-40);
        content: '';
      }
    }

    input:checked + .checked-circle {
      background: var(--grey34-o-40);
    }
  }

  input:checked + .checked-circle {
    position: absolute;
    left: 4px;
    width: 10px;
    height: 10px;
    background: var(--alluminium);
    border-radius: var(--border-radius-circle);
  }

  .tooltip {
    margin-left: var(--indent-xsmall);
    transform: translateY(-10%);
  }
}