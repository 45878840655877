@import "src/styles/mixins";

.btn {
  @include flex-center-center;
  padding: 10px var(--indent-medium);
  font-size: var(--font-size-small);
  font-family: var(--russo-one);
  border: none;
}

.label-block {
  display: inline-block;
}

.label {
  display: block;
  letter-spacing: 1.25px;
}

.primary {
  min-height: var(--btn-primary-min-height);
}

.secondary {
  min-height: var(--btn-secondary-min-height);
}

.primary {
  color: var(--secondary);
  background-color: var(--red-primary);
}

.secondary {
  position: relative;
  color: var(--red-primary);
  background-color: var(--transparent);
  transition: background-color .3s;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  rect {
    transition: all 0.35s linear;
    fill: none;
    stroke: var(--red-primary);
    stroke-width: 4;
    stroke-dasharray: 422, 0;
  }

  &.disabled {
    rect {
      stroke: var(--white-red);
    }
  }
}

.text {
  padding: var(--indent-xsmall);
  color: var(--red-primary);
  background-color: var(--transparent);
}

.with-plus,
.with-minus {
  height: 48px;
  padding: 0;
  color: var(--red-primary);
  font-size: 10px;
  background-color: transparent;

  .icon {
    width: 14px;
    height: 14px;
    margin-right: var(--indent-small);
  }

  &.disabled {
    background-color: var(--transparent);
  }
}

.with-plus {
  .icon {
    background: url("../assets/other-icons/plus_icon.svg") center center no-repeat;
  }

  &.disabled {
    .icon {
      background: url("../assets/other-icons/disabled_plus_icon.svg") center center no-repeat;
    }
  }
}

.with-minus {
  .icon {
    background: url("../assets/other-icons/minus_icon.svg") center center no-repeat;
  }

  &.disabled {
    .icon {
      background: url("../assets/other-icons/disabled_minus_icon.svg") center center no-repeat;
    }
  }
}

.data-loading {
  pointer-events: none;

  .label-block {
    position: relative;

    .preloader {
      top: 50%;
      right: auto;
      left: 0;
      width: 24px;
      height: 24px;
      transform: translateY(-50%);

      & > div {
        transform-origin: 12px 12px;

        &:after {
          top: 2px;
          left: 11px;
          width: 2px;
          height: 5px;
          background: var(--secondary);
        }
      }

      &.type-text > div:after {
        background: var(--primary);
      }

      &.type-secondary > div:after {
        background: var(--sand-o-70);
      }
    }
  }

  .label {
    animation: label-shift .2s forwards;
  }
}

.uppercase {
  text-transform: uppercase;
}

.disabled {
  /* pointer-events set with disabled prop to button tag */
  color: var(--white-red);
  background-color: var(--silver);
  cursor: default;
}

.label-overflow-ellipsis {
  .label-block {
    max-width: 100%;

    .label {
      @include text-overflow-ellipsis;
      max-width: 100%;
    }
  }
}

@include md-screen {
  .text {
    padding: var(--indent-small);
  }

  .underline {
    position: relative;

    &:after {
      position: absolute;
      bottom: calc(-1 * var(--indent-xsmall));
      width: 100%;
      height: 1px;
      background-color: var(--primary);
      content: "";
    }
  }
}

@include xl-screen {
  .primary:hover {
    background-color: var(--berry-red);
  }

  .secondary:not(.disabled):hover {
    rect {
      stroke-width: 5;
      stroke-dasharray: 15, 310;
      stroke-dashoffset: 75;
      transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  .text:hover {
    color: var(--dark-red);
  }

  .with-plus:not(.disabled):hover,
  .with-minus:not(.disabled):hover {
    color: var(--dark-red);
  }

  .with-plus:not(.disabled):hover {
    .icon {
      background-image: url("../assets/other-icons/plus_icon_hover.svg");
    }
  }

  .with-minus:not(.disabled):hover {
    .icon {
      background-image: url("../assets/other-icons/minus_icon_hover.svg");
    }
  }

  .disabled:not(.with-minus):not(.with-plus):hover {
    background-color: var(--silver);
  }
}

@keyframes label-shift {
  0% {
    padding-left: 0;
  }

  100% {
    padding-left: 34px; // preloader icon 24px + 10px indent
  }
}