@import "src/styles/mixins";

.title {
  margin: calc(-1 * var(--indent-xsmall)) 0 var(--indent-medium);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .viewer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;

    .document {
      width: 100%;
      max-width: 892px; // as width in document Page component from properties of plugin, scale 1.5
      height: 1262px; // as height in document Page component from properties of plugin, scale 1.5
      margin: var(--indent-base) auto 0;
      overflow-y: auto;

      & > div[class*="react-pdf__message--loading"],
      .page > div[class*="react-pdf__message--loading"] {
        width: 100%;
        height: 100%;
      }

      .page {
        display: flex;
        justify-content: center;
        height: 100%;
      }

      .loading-block {
        width: 100%;
        height: 100%;
        background-color: var(--secondary);

        .preloader {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 5px;
          background-color: var(--white-blue);

          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(90deg, rgba(163, 171, 150, 0) 0, rgba(163, 171, 150, 0.2) 20%, rgba(163, 171, 150, 0.5) 60%, rgba(163, 171, 150, 0));
            transform: translateX(-100%);
            animation: shimmer 1s infinite;
            content: "";
          }
        }
      }
    }
  }

  .sticky-block {
    @include flex-center-center;
    position: sticky;
    bottom: 0;
    margin-top: 20px;
    padding: var(--indent-base) 0;
    background-color: var(--secondary);

    .navigation {
      display: flex;
      width: 100%;
      max-width: 892px; // as width in document Page component from properties of plugin, scale 1.5

      .numbers-block {
        margin-left: var(--indent-xlarge);

        &:not(:last-child):nth-child(2) {
          text-align: center;
          user-select: none;
        }

        & > div {
          display: inline-block;
          max-width: 30px;
          max-height: 30px;
          margin-right: var(--indent-xsmall);

          & > input {
            padding: 0;
            text-align: center;
          }
        }
      }

      .btn-group {
        display: flex;

        .prev-btn,
        .next-btn {
          @include flex-center-center;
          user-select: none;
          color: var(--secondary);
          background-color: var(--dark-khaki);
          min-width: 90px;
          height: var(--btn-secondary-min-height);
          cursor: pointer;

          &.disabled {
            color: var(--white-red);
            background-color: var(--silver);
          }
        }

        .prev-btn {
          &::before {
            width: 7px;
            height: 11px;
            margin-right: 12px;
            background: url("../assets/chevrons-and-arrows/chevron-left-white.svg") 100% / 100% no-repeat;
            content: "";
          }

          &.disabled {
            &::before {
              background: url("../assets/chevrons-and-arrows/chevron-left-grey.svg") 100% / 100% no-repeat;
            }
          }
        }

        .next-btn {
          margin-left: var(--indent-medium);

          &::after {
            width: 7px;
            height: 11px;
            margin-left: 12px;
            transform: rotate(180deg);
            background: url("../assets/chevrons-and-arrows/chevron-left-white.svg") 100% / 100% no-repeat;
            content: "";
          }

          &.disabled {
            &::after {
              background: url("../assets/chevrons-and-arrows/chevron-left-grey.svg") 100% / 100% no-repeat;
            }
          }
        }
      }

      .zoom {
        display: flex;
      }

      .btn-group-action {
        display: flex;
        margin-left: auto;

        .btn {
          max-width: 150px;

          &.multi-mode:nth-child(n + 2) {
            margin-left: var(--indent-large);
          }
        }
      }
    }
  }
}

@media print {
  .viewer {
    display: none;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}