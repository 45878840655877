.container {
  margin-top: var(--indent-xxlarge);

  .title {
    margin-bottom: var(--indent-medium);
    color: var(--dark-grey);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    opacity: .9;
  }

  .draft {
    .paragraph:not(:last-child) {
      margin-bottom: var(--indent-base);
      letter-spacing: 0.35px;
    }
  }
}