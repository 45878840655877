@import "src/styles/mixins";

.wrapper {
  padding: var(--indent-xxlarge) 0;

  &:nth-child(2) {
    background-color: var(--white-grey);
  }
}

.btn {
  min-height: var(--btn-primary-min-height);
  line-height: var(--line-height-1rem-half);
}

.container {
  @include flex-column-reverse-wrap-between;

  &:not(:last-child) {
    margin-bottom: var(--indent-xxlarge);
  }

  .image-block {
    @include flex-center;
    margin-bottom: var(--indent-base);

    .img {
      display: none;
    }

    .title-sm {
      font-size: 20px;
      text-transform: capitalize;

      &.not-capitalize-text {
        text-transform: initial;
      }
    }
  }

  .text {
    & > div {
      @include flex-column;
    }

    .title {
      display: none;
      text-transform: capitalize;

      &.not-capitalize-text {
        text-transform: uppercase;
      }
    }

    .description {
      margin-bottom: var(--indent-large);
      font-size: var(--font-size-base);
      color: var(--primary);

      .paragraph {
        margin: var(--indent-base) 0;

        &:first-child {
          margin: 0;
        }
      }
    }

    .img,
    .btn-block {
      align-self: center;
    }

    .img {
      width: 100%;
      max-width: 500px;
      margin-bottom: var(--indent-large);
    }

    .btn-block,
    .btn-block .btn {
      width: 100%;
    }
  }
}

.radio-block {
  .title {
    margin-bottom: var(--indent-base);
    font-size: 20px;
    text-transform: capitalize;

    &.not-capitalize-text {
      text-transform: initial;
    }
  }

  .accordion {
    &-container {
      margin-top: var(--indent-base);
      box-shadow: var(--box-shadow-panel);
    }

    &-label {
      color: var(--primary);
      background-color: var(--white-grey);
      padding: 14px var(--indent-large) 14px 14px;
      font-size: var(--indent-base);
      text-transform: none;

      &-text {
        max-width: 90%;
      }

      &:after {
        right: 14px;
        width: 9px;
        height: 14px;
        min-width: 9px;
        background: url("../../../common/assets/chevrons-and-arrows/arrow-red.svg") 100%/100% no-repeat;
      }
    }

    &-content {
      padding: var(--indent-base) var(--indent-small);
    }
  }

  .tab {
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .img {
      width: 80%;
      max-width: 300px;
      margin-bottom: var(--indent-large);
    }

    .text {
      color: var(--primary);

      .paragraph:not(:first-child) {
        margin-top: var(--indent-base);
      }
    }
  }

  .btn-block {
    width: 100%;
    display: block;
    margin-top: var(--indent-large);

    .btn {
      width: 100%;
      margin: 0 auto;
    }
  }
}

@include xs-screen {
  .container .text .btn-block,
  .radio-block .btn-block {
    width: auto;

    .btn {
      min-width: 300px;
      width: auto;
    }
  }
}

@include sm-screen {
  .container .image-block .title-sm,
  .radio-block .title {
    font-size: 22px;
  }

  .container .text .img {
    max-width: 550px;
  }
}

@include md-screen {
  .radio-block {
    .accordion {
      &-content {
        padding: var(--indent-base);
      }

      &-label {
        padding: var(--indent-base) var(--indent-xlarge) var(--indent-base) var(--indent-base);

        &:after {
          right: var(--indent-base);
        }
      }
    }

    .tab {
      & > div {
        flex-direction: row;
        align-items: flex-start;
      }

      .text {
        flex-basis: auto;

        .paragraph {
          max-width: none;
        }
      }

      .img {
        max-width: 280px;
        order: 2;
        align-self: center;
        margin: 0 var(--indent-large);
      }
    }
  }
}

@media (min-width: 900px) {
  .wrapper {
    padding: 80px 0;

    &:nth-child(2) .container {
      flex-direction: row-reverse;

      .text {
        padding: 0 0 0 var(--indent-xlarge);
      }

      .image-block {
        .img {
          margin: 0;
        }
      }
    }
  }

  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .image-block {
      flex-basis: 50%;
      margin: 0;

      .img {
        display: block;
        width: 100%;
        max-width: 500px;
      }

      .title-sm {
        display: none;
      }
    }

    .text {
      flex-basis: 50%;
      padding-right: var(--indent-xlarge);

      & > div {
        display: block;
        width: 100%;
        max-width: 540px;
      }

      .title {
        display: block;
        margin-bottom: var(--indent-medium);
        font-size: 24px;
        line-height: var(--line-height-1rem-7);
      }

      .description {
        font-size: var(--font-size-base);

        .paragraph {
          margin-top: var(--indent-base);
        }
      }

      .img {
        display: none;
      }
    }
  }

  .radio-block {
    .title {
      margin-bottom: var(--indent-medium);
    }

    .tab .text {
      font-size: var(--font-size-base);
    }

    .btn-block .btn {
      margin: 0;
    }
  }
}

@include lg-screen {
  .container {
    .image-block {
      .img {
        max-width: 585px;
      }
    }

    .text .title {
      font-size: 24px;
      line-height: var(--line-height-2rem);
    }
  }

  .radio-block-lg {
    display: flex;
    justify-content: space-between;

    .labels-block {
      flex-basis: 50%;
      padding-right: var(--indent-xxlarge);

      .title {
        font-size: 24px;
        margin-bottom: var(--indent-large);
        text-transform: capitalize;

        &.not-capitalize-text {
          text-transform: uppercase;
        }
      }

      .label-container {
        @include flex-center-between;
        width: 100%;
        max-width: 555px;
        min-height: 60px;
        padding: var(--indent-small) var(--indent-base);
        font-weight: var(--font-weight-semi-bold);
        background-color: var(--white-grey);
        transform: scale(1);
        box-shadow: var(--box-shadow-panel);
        cursor: pointer;

        &:not(:first-child) {
          margin-top: var(--indent-medium);
        }

        .icon {
          display: block;
          margin: 0;
          opacity: 0;
          transform: translateX(100%);
        }

        &.active {
          position: relative;
          transform: scale(1.03);
          transition: .3s;
          box-shadow: var(--box-shadow-sand);

          .icon {
            opacity: 1;
            width: 11px;
            height: 18px;
            min-width: 11px;
            margin-left: var(--indent-base);
            background: url("../../../common/assets/chevrons-and-arrows/arrow-red.svg") 100%/100% no-repeat;
            transform: translateX(0);
            transition: all .3s;
          }
        }

        input {
          display: none;
        }
      }

      .btn-block {
        display: block;
        margin-top: var(--indent-xxlarge);

        .btn {
          min-width: 300px;
        }
      }
    }

    .tab {
      display: none;
      flex-basis: 50%;
      color: var(--primary);

      &.active {
        display: block;
        animation: tab-opacity .5s;

        & > div {
          width: 100%;
          max-width: 540px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      .paragraph:not(:first-child) {
        margin-top: var(--indent-base);
      }

      .img {
        width: 80%;
        max-width: 470px;
        max-height: 315px;
        margin-bottom: var(--indent-medium);
      }
    }
  }
}

@include xl-screen {
  .wrapper {
    padding: 100px 0 120px;

    &:nth-child(2) {
      padding: 100px 0;
    }
  }

  .container {
    .text {
      .title {
        font-size: 30px;
      }
    }
  }

  .radio-block-lg .labels-block .title {
    font-size: 30px;
  }
}

@keyframes tab-opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}