@import "src/styles/mixins";

.container {
  @include flex-center;

  .status-circle {
    width: var(--indent-small);
    height: var(--indent-small);
    margin: 0 var(--indent-small) 0 0;
    border-radius: var(--border-radius-circle);
  }

  .text {
    word-break: break-all;
  }
}