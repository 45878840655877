@import "src/styles/mixins";

.item {
  color: var(--dark-grey);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-medium);
  text-transform: uppercase;
  user-select: none;

  &.expired-item {
    margin-bottom: var(--indent-xsmall);
    padding: 14px var(--indent-small);
    padding-right: calc(2 * 16px + 11px);
  }
}

.accordion {
  &-container {
    margin-bottom: var(--indent-xsmall);
  }

  &-container-active {
    border: var(--border-alluminium);

    .accordion-label {
      background-color: var(--secondary);
    }
  }

  &-label {
    margin-bottom: 2px;
    padding-right: calc(2 * 16px + 11px);
    background-color: var(--transparent);

    &:after {
      right: var(--indent-base);
    }

    & > div {
      width: 100%;
      max-width: none;
    }
  }

  &-content {
    padding: 0;

    & > div:last-child {
      border-bottom: none;
    }
  }
}

.empty {
  height: 115px;
  padding: var(--indent-xlarge) var(--indent-medium);
  color: var(--grey);
  font-size: var(--font-size-medium);
  text-align: center;
}

.preloader {
  top: 50%;
  transform: translate(-50%, -50%);
}