@import "../../../../../../../../../styles/mixins";

.container {
  .table-head {
    display: grid;
    align-items: center;
    grid-template-columns: 30% 20% 20% 30%;
    height: 52px;
    padding: var(--indent-small);
    color: var(--grey);
    border-top: var(--border-black);
  }

  .column {
    &:not(:last-child) {
      padding-right: var(--indent-base);
    }

    &:last-child {
      text-align: center;
    }
  }
}