@import "/src/styles/mixins";
@import "src/styles/flex-columns";

.container {
  display: grid;
  grid-template-columns: 10% 42% 20% auto;
  align-items: center;
  padding: 14px var(--indent-large);
  color: var(--dark-grey);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-medium);

  .column {
    &:not(:first-child) {
      margin-left: var(--indent-medium);
    }

    &.organization {
      @include text-overflow-ellipsis;
    }

    &.btn-block {
      @include flex-center;
    }
  }

  .btn {
    font-size: 10px;

    &-preloader > div:after {
      background: var(--primary) !important;
    }
  }
}

.accordion-label {
  padding: 0;

  &::after {
    right: var(--indent-base);
  }

  & > div {
    width: 100%;
    max-width: none;
  }
}

.accordion-body {
  padding: 0;
}

.accordion-container-active {
  &:not(:last-child) {
    margin-bottom: var(--indent-xsmall);
    border: none;
  }

  .accordion-label {
    border-bottom: var(--border-black);
  }
}

@include xl-screen {
  .container:hover {
    background-color: var(--white-green);
  }
}