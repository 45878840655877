@import "src/styles/mixins";

.container {
  padding: var(--indent-medium);
  background: var(--secondary);

  .title {
    margin-bottom: var(--indent-medium);
    color: var(--dark-grey);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    opacity: .9;
  }

  .grid-group {
    display: grid;
    grid-row-gap: var(--indent-medium);
    grid-column-gap: var(--indent-large);
    max-width: 692px;
    margin-bottom: 48px;
  }

  .group-even-2 {
    grid-template-columns: repeat(2, 1fr);

    &-revert > div:last-child {
      grid-column: 2 / 3;
    }
  }

  .radio-group {
    transform: translateY(-10px);
  }
}