@import "../../../../../../../styles/mixins";

/* the original image size is 500 * 400,
then we use the formula to find the height depending on the width */

/* mobile devise: width 300px - height 240px, but we take 300px and 220px,
then 20px is used for padding between slide images */

/* desktop device (from 992px in this case): width 400px - height 320px */

.container {
  width: 100%;
  max-width: 256px;
  height: 100%;
  margin: 0 auto var(--indent-medium);

  & > div[class~='swiper-container-main'] {
    z-index: 0 !important;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 100%;

    & > div[class~='swiper-wrapper'] {
      width: 100%;
      height: 100%;
      max-height: 185px;
      cursor: pointer;

      & > div[class~='swiper-slide'] {
        height: 185px;
      }
    }

    & > div[class~='swiper-pagination-bullets'] {
      position: relative;
      bottom: 0;
      margin-top: var(--indent-small);

      & > span[class~='swiper-pagination-bullet'] {
        width: 10px;
        height: 10px;
        margin: 0 6px;
        background-color: var(--alluminium);
        border-radius: 0;
      }

      & > span[class~='swiper-pagination-bullet-active'] {
        background-color: var(--dark-khaki);
      }
    }
  }

  .image {
    object-fit: contain;
  }
}

@media (min-width: 360px) {
  .container {
    max-width: 300px;

    & > div[class~='swiper-container-main'] {
      & > div[class~='swiper-wrapper'] {
        max-height: 220px;

        & > div[class~='swiper-slide'] {
          height: 220px;
        }
      }
    }
  }
}

@include lg-screen {
  .container {
    flex-basis: 48%;
    flex-shrink: 0;
    width: 460px;
    min-width: 460px; // this is used because parent container is flex
    height: 100%;
    margin: 0;

    & > div[class~='swiper-container-main'] {
      &:not(div[class~='swiper-container-thumb']) {
        @include flex-center;
        justify-content: space-around;

        & > div[class~='swiper-button-prev'],
        & > div[class~='swiper-button-next'] {
          width: 14px;
          height: 24px;
          margin: 0;
          transform: translateY(-50%);
        }

        & > div[class~='swiper-button-prev'] {
          left: 14px;
        }

        & > div[class~='swiper-button-next'] {
          right: 14px;
        }

        & > div[class~='swiper-wrapper'] {
          max-width: 340px;
          max-height: 272px;
          cursor: pointer;

          & > div[class~='swiper-slide'] {
            width: 340px !important;
            height: 272px;
            opacity: 0 !important;
          }

          & > div[class~='swiper-slide-active'] {
            opacity: 1 !important;
          }
        }
      }

      & + div[class~='swiper-container-thumb'] {
        z-index: 0 !important;
        max-width: 340px;
        margin: var(--indent-medium) auto 0;
        overflow: hidden;

        & > div[class~='swiper-wrapper'] {
          & > div[class~='swiper-slide'] {
            width: 100px !important;
            height: 80px;
            border: 3px solid var(--transparent);
            cursor: pointer;

            .image {
              object-fit: cover;
            }
          }

          & > div[class~='swiper-slide-thumb-active'] {
            border-color: var(--dark-khaki);
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1045px) {
  .container {
    & > div[class~='swiper-container-main'] {
      &:not(div[class~='swiper-container-thumb']) {
        & > div[class~='swiper-button-prev'] {
          left: 18px;
        }

        & > div[class~='swiper-button-next'] {
          right: 18px;
        }
      }
    }
  }
}

@media (min-width: 1100px) {
  .container {
    width: 500px;
    min-width: 500px;

    & > div[class~='swiper-container-main'] {
      &:not(div[class~='swiper-container-thumb']) {
        & > div[class~='swiper-wrapper'] {
          max-width: 400px;
          max-height: 320px;

          & > div[class~='swiper-slide'] {
            width: 400px !important;
            height: 320px;
          }
        }
      }

      & + div[class~='swiper-container-thumb'] {
        max-width: 400px;
      }
    }
  }
}

@media (min-width: 1280px) {
  .container {
    width: 600px;
    min-width: 600px;

    & > div[class~='swiper-container-main'] {
      &:not(div[class~='swiper-container-thumb']) {
        & > div[class~='swiper-wrapper'] {
          max-width: 500px;
          max-height: 400px;

          & > div[class~='swiper-slide'] {
            width: 500px !important;
            height: 400px;
          }
        }
      }

      & + div[class~='swiper-container-thumb'] {
        max-width: 500px;
      }
    }
  }
}