@import "../../../../../../../../styles/mixins";

.container {
  padding: 22px var(--indent-medium) 81px;
  background-color: var(--secondary);

  .text {
    margin-bottom: var(--indent-xlarge);
    color: var(--dark-grey);
    font-weight: var(--font-weight-medium);
  }

  .quotation-form {
    .row {
      display: grid;
      grid-column-gap: var(--indent-large);
      grid-template-columns: 153px auto;

      .label {
        align-self: center;
        color: var(--dark-grey);
      }

      .items {
        @include flex-center;
      }

      .upload-container {
        display: grid;
        grid-column-gap: var(--indent-medium);
        grid-template-columns: 328px auto;
        align-items: center;

        .dropzone-block {
          .dropzone {
            width: 100%;
            margin-bottom: 6px;
          }

          .tip {
            margin-top: 6px;
            margin-left: var(--indent-medium);
            font-size: var(--font-size-medium);

            .link {
              color: var(--blue);
            }
          }
        }

        .progressbar-block {
          .progressbar {
            &:not(:last-child) {
              margin-bottom: var(--indent-base);
            }
          }
        }
      }

      .btn-group {
        @include flex-center;
        grid-column: 2 / 3;
        margin-top: var(--indent-large);

        .cancel {
          min-width: 94px;
        }

        .proceed {
          min-width: 217px;
          min-height: var(--btn-secondary-min-height);
          margin-left: var(--indent-medium);
        }
      }

      .info {
        grid-column: 2 / 3;
      }
    }

    .options-row {
      margin-top: var(--indent-medium);

      .chip {
        height: 32px;
      }

      .chip + .chip {
        margin-left: var(--indent-small);
      }
    }

    .row + .row {
      margin-top: var(--indent-medium);
    }
  }
}

.warning {
  color: var(--dark-grey);
  text-align: center;
}

@include xl-screen {
  .container {
    .quotation-form {
      .row {
        .upload-container {
          grid-column-gap: 70px;
        }
      }
    }
  }
}