@import "src/styles/mixins";

.breadcrumbs-custom {
  padding: 0 var(--indent-base);
}

.main-container-custom {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.main-data-container {
  width: 100%;
  padding: 0 var(--indent-base) var(--indent-base);
}

.title {
  color: var(--black-o-87);
  font-family: var(--noto-sans-medium);

  &-md {
    display: none;
  }
}

.price-block {
  margin: .75rem 0 var(--indent-xsmall);
  color: var(--dark-grey);
  font-weight: var(--font-weight-bold);
  font-size: 18px;

  .label {
    color: var(--grey);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-base);
  }

  .price {
    @include flex-center;
  }

  .edit-product {
    &-link {
      display: block;
      width: 100%;
      max-width: 200px;
      margin: var(--indent-base) auto 0;
    }

    &-btn {
      width: 100%;
      color: var(--green);

      & > svg > rect {
        stroke: var(--green);
      }
    }
  }
}

.counter {
  margin: 0 auto;
}

.purchase-block {
  @include flex-column;
  align-items: center;
  margin-bottom: var(--indent-base);

  .btn-block {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: var(--indent-large);

    .btn-link, .btn {
      width: 100%;
      max-width: 304px;
    }

    .btn {
      &.added-to-quotation {
        color: var(--secondary);
        background-color: var(--dark-khaki);
      }
    }
  }

  .availability-label {
    color: var(--green);

    &.out-of-stock {
      color: var(--red);
    }

    & + .offers {
      margin-top: var(--indent-small);

      & > a {
        position: relative;

        &:after {
          position: absolute;
          right: calc(-1 / 2 * var(--indent-xsmall));
          bottom: calc(-1 * var(--indent-xsmall));
          left: calc(-1 / 2 * var(--indent-xsmall));
          height: 1px;
          background-color: var(--blue);
          content: "";
        }
      }
    }
  }
}

.accordion {
  &-container {
    margin-bottom: var(--indent-xsmall);
  }

  &-content {
    & > div {
      max-width: 550px;
    }
  }

  &-label {
    background-color: var(--light-sand);
  }
}

.tooltip-for-prices {
  @include flex-center;
  position: initial;
  margin-left: var(--indent-medium);
  transform: none;

  & > svg {
    width: 18px;
    height: 18px;

    & > path {
      fill: var(--green);
    }
  }
}

.tooltip-content {
  padding: var(--indent-base);

  & > div > div:not(:first-child) {
    margin-top: var(--indent-xsmall);
  }
}

@include sm-screen {
  .main-data-container {
    max-width: 450px;
    margin: 0 auto;
  }
}

@include md-screen {
  .breadcrumbs-custom {
    padding: 0 var(--indent-medium);
  }

  .main-data-container {
    display: grid;
    grid-template-areas:
            "slider price-block"
            "slider counter"
            "slider purches-block";
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr 1fr;
    max-width: none;
    margin: 0;

    &.without-counter {
      grid-template-areas:
            "slider price-block"
            "slider ."
            "slider purches-block";
    }
  }

  .title {
    display: none;

    &-md {
      display: block;
      font-weight: var(--font-weight-bold);
      font-size: 20px;
    }
  }

  .slider {
    grid-area: slider;
  }

  .price-block {
    grid-area: price-block;
    margin: 0;

    .price {
      margin-top: var(--indent-base);
    }

    .edit-product-link {
      display: inline-block;
      width: auto;
      min-width: 100px;
      max-width: none;
      margin-right: 0;
      margin-left: 0;
    }
  }

  .counter {
    grid-area: counter;
    max-width: 186px;
    margin: var(--indent-base) 0 0;
  }

  .purchase-block {
    grid-area: purches-block;
    align-items: flex-start;

    &-md {
      margin-bottom: 0;
    }

    &.not-available-block {
      grid-area: counter;
      margin-top: var(--indent-medium);
    }

    .btn-block {
      display: block;
    }
  }

  .offers.single-block {
    text-align: left;
  }
}