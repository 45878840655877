@import "src/styles/mixins";

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  text-transform: uppercase;
  background-color: var(--secondary);

  .label {
    padding: var(--indent-medium);
    color: var(--grey);
    font-size: var(--font-size-medium);
    border-bottom: var(--border-black-strong);
    cursor: pointer;

    &.active {
      color: var(--dark-khaki);
      font-weight: var(--font-weight-semi-bold);
      border-bottom: 2px solid var(--dark-khaki);
    }
  }
}
