@import "../../../../../../../styles/mixins";

.label-mobile {
  margin-bottom: var(--indent-base);
  color: var(--dark-grey);
  font-family: var(--noto-sans-semi-bold);
  font-size: var(--font-size-medium);
}

.item {
  @include flex-center;
  color: var(--grey);
  font-size: var(--font-size-medium);

  &:not(:last-child) {
    margin-bottom: var(--indent-medium);
  }

  .name {
    @include text-overflow-ellipsis;
    flex-grow: 1;
    margin-right: var(--indent-small);
    color: var(--grey);
  }

  .icon {
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    margin-right: var(--indent-medium);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .size {
    flex-shrink: 0;
    margin-left: auto;
  }
}

@include lg-screen {
  .container {
    padding: var(--indent-medium) calc(3.25 * var(--indent-base)) calc(3.25 * var(--indent-base));
    background-color: var(--secondary);
  }

  .content {
    max-width: 550px;

    .label {
      margin-bottom: 28px;
      color: var(--dark-grey);
      font-family: var(--noto-sans-semi-bold);
      font-size: var(--font-size-medium);
      text-transform: uppercase;
    }

    .item {
      font-size: var(--font-size-base);

      .name {
        max-width: 400px;
      }

      .icon {
        margin-right: 18px;
      }
    }
  }
}