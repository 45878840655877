@import "src/styles/mixins";

.container {
  position: relative;
  flex: 1 1 50%;
  width: 100%;

  &.disabled {
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: var(--white-o-40);
      content: '';
    }
  }

  &:last-child {
    .drop-menu {
      border-left: none;
    }
  }

  & > [data-tippy-root] {
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: none !important;

    & > div[class="tippy-box"] {
      color: var(--primary);
    }

    div[class*="items"] {
      padding: 0;
    }
  }
}

.drop-menu {
  background-color: var(--secondary);

  &:nth-child(n) {
    border-bottom: var(--border-black) !important;
  }
}

.content {
  @include flex-column;
  width: 100%;
  height: 100vh;
  padding-bottom: calc(4 * var(--indent-large));
  overflow: auto;
}

.label {
  position: relative;
  padding: var(--indent-base) var(--indent-base) var(--indent-base) calc(3.5 * var(--indent-base));
  color: var(--secondary);
  font-size: var(--font-size-medium);
  text-transform: uppercase;
  background: var(--dark-khaki);

  &::before {
    position: absolute;
    top: 50%;
    left: var(--indent-medium);
    display: inline-block;
    width: 7px;
    height: 12px;
    background: url("./assets/chevron_left.svg") center / auto no-repeat;
    transform: translateY(-50%);
    content: '';
  }
}

.btn-block {
  margin-top: auto;
  padding: var(--indent-large) var(--indent-base) 0;

  .show-result,
  .reset-all {
    width: 100%;
    min-height: var(--btn-primary-min-height);
  }

  .reset-all {
    margin-top: var(--indent-small);
    font-size: var(--font-size-medium);
  }
}