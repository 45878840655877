@import "../../../../../../../styles/mixins";

.container {
  padding: calc(2 * var(--indent-medium)) 0 calc(6 * var(--indent-medium));

  .title-block {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    padding: 0 var(--indent-small);

    .title {
      font-family: var(--noto-sans-medium);
      text-transform: uppercase;

      &.with-bottom-indent {
        margin-bottom: var(--indent-base);
      }
    }

    .subtitle {
      margin: 12px 0 var(--indent-base);
      font-weight: var(--font-weight-bold);
      font-size: 18px;
    }
  }

  & > div[class*="container"] {
    max-width: 280px;

    & > div[class~="swiper-container-popup-mobile"] {
      width: 100%;
      height: 100%;

      & > div[class~="swiper-wrapper"] {
        max-height: 224px;

        & > div[class~="swiper-slide"] {
          height: 224px;

          & > picture > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      & + div[class~="swiper-container-thumb"] {
        max-width: 280px;
        margin: var(--indent-base) auto 0;

        & > div[class~="swiper-wrapper"] {
          & > div[class~="swiper-slide"] {
            width: 70px !important;
            height: 56px;
            margin-right: 11px !important;
            border: 3px solid var(--transparent);

            & > div[class*='skeleton-preloader'] > picture > img {
              object-fit: cover
            }
          }

          & > div[class~="swiper-slide-thumb-active"] {
            border-color: var(--dark-khaki);
          }
        }
      }
    }
  }
}

@include lg-screen {
  .container {
    width: 100%;
    padding: var(--indent-medium) 0 calc(2 * var(--indent-medium));

    .title-block {
      @include flex-center;
      width: 100%;
      max-width: 90%;
      margin: 0 0 var(--indent-large) var(--indent-medium);

      .title,
      .subtitle {
        font-weight: var(--font-weight-normal);
        font-size: calc(2 * var(--font-size-small));
        font-family: var(--russo-one);
      }

      .title {
        flex-basis: 50%;
        flex-grow: 1;
      }

      .subtitle {
        flex-shrink: 0;
      }
    }

    & > div[class*="container"] {
      width: 650px;
      min-width: 650px;
      margin: 0 auto;

      & > div[class~="swiper-container-popup"] {
        margin: 0 auto;

        & > div[class~="swiper-button-prev"],
        & > div[class~="swiper-button-next"] {
          width: 14px;
          height: 24px;
          margin: 0;
          transform: translateY(-50%);
        }

        & > div[class~="swiper-button-prev"] {
          left: 14px;
        }

        & > div[class~="swiper-button-next"] {
          right: 14px;
        }

        & > div[class~="swiper-wrapper"] {
          max-width: 500px;
          max-height: 400px;
          margin: 0 auto;

          & > div[class~="swiper-slide"] {
            width: 500px !important;
            height: 400px;
            opacity: 0 !important;
          }

          & > div[class~="swiper-slide-active"] {
            opacity: 1 !important;
          }
        }

        & + div[class~="swiper-container-thumb"] {
          width: 100%;
          margin-top: var(--indent-large);

          & > div[class~="swiper-wrapper"] {
            & > div[class~="swiper-slide"] {
              width: 100px !important;
              height: 80px;
              border: 3px solid var(--transparent);
              cursor: pointer;

              & > div[class*='skeleton-preloader'] > picture > img {
                object-fit: cover;
              }
            }

            & > div[class~="swiper-slide-thumb-active"] {
              border-color: var(--dark-khaki);
            }
          }
        }
      }
    }
  }
}