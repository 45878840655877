@import "src/styles/mixins";

.container {
  position: relative;
  @include flex-center;

  &.without-text {
    margin-right: 10px;
  }

  &.read-only {
    pointer-events: none;

    .label {
      position: relative;
      color: var(--grey);
    }
  }
}

.checkbox {
  display: none;

  &:checked + .label:before {
    background: url("../assets/error_and_info_message-icons/check.svg") var(--alluminium);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px 14px;
  }

  &.secondary {
    &:checked + .label:before {
      background: url("../assets/other-icons/minus-white.svg") var(--alluminium);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 10px 2px;
    }
  }

  &.with-icons {
    &:checked + .label:before {
      background: url("../assets/error_and_info_message-icons/check-green.svg") var(--transparent);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 14px 10px;
      border: 1px solid var(--green);
    }

    & + .label {
      &:before {
        width: 18px;
        min-width: 18px;
        height: 18px;
        background: url("../assets/error_and_info_message-icons/error-upload-icon.svg") var(--transparent);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px 12px;
        border: 1px solid var(--red);
      }
    }
  }
}

.read-only {
  .checkbox {
    & + .label:before {
      border-color: var(--light-grey);
    }

    &:checked + .label:before {
      background-color: var(--light-grey);
    }
  }
}

.label {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &.with-txt {
    &:before {
      margin-right: 10px;
    }
  }

  &:before {
    box-sizing: border-box;
    width: var(--checkbox-size);
    min-width: var(--checkbox-size);
    height: var(--checkbox-size);
    border: var(--checkbox-border);
    border-radius: var(--border-radius-checkbox);
    content: '';
  }
}