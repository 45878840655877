@import "src/styles/mixins";
@import "src/styles/page-container.module.scss";

.page-container {
  position: relative;
}

.container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: var(--indent-xxxlarge) var(--indent-base);

  .title {
    margin-bottom: var(--indent-base);
    font-weight: var(--font-weight-bold);
    text-align: center;
    text-transform: uppercase;

    & > span {
      display: block;

      &:first-child {
        margin-bottom: var(--indent-xsmall);
      }
    }
  }

  .subtitle {
    font-size: var(--font-size-medium);
    color: var(--grey);
    text-align: center;
  }

  .sections-container {
    margin-top: var(--indent-medium);
    background-color: var(--secondary);

    div[class*="input-phone_container"] {
      max-width: none;
    }
  }

  .organization-section {
    margin-top: var(--indent-large);
    padding-top: var(--indent-large);
    border-top: 2px solid var(--grey77);
  }

  .section-title {
    margin-bottom: var(--indent-large);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-medium);
    text-transform: uppercase;
  }

  .section-info {
    margin-bottom: var(--indent-small);
  }

  .fields {
    display: grid;
    grid-row-gap: var(--indent-medium);

    .radio-group {
      transform: translateY(-10px);
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: var(--indent-medium);

    .confirm-btn,
    .cancel-btn {
      min-width: 200px;
    }

    .confirm-btn {
      min-height: var(--btn-secondary-min-height);
      margin-bottom: var(--indent-base);
    }
  }
}

.tooltip {
  padding: var(--indent-base);

  &-text {
    .list {
      margin: 0;
      padding-left: var(--indent-medium);
      font-size: var(--font-size-base);
      font-weight: var(--font-weight-bold);

      & > li {
        font-weight: var(--font-weight-normal);
        margin-top: var(--indent-xsmall);

        &:first-child {
          margin-top: var(--indent-small);
        }
      }
    }
  }
}

.required {
  @include required-field-error;
}

.error-block {
  @include response-error-block-on-page;
}

@include sm-screen {
  .container {
    .buttons-container {
      flex-direction: row;
      justify-content: flex-end;
      margin: var(--indent-xxlarge) auto 0;

      .confirm-btn,
      .cancel-btn {
        min-width: 160px;
      }

      .confirm-btn {
        margin-bottom: 0;
        margin-left: var(--indent-large);
      }
    }
  }
}

@include md-screen {
  .container {
    padding: 80px var(--indent-medium);

    .fields {
      grid-column-gap: var(--indent-large);
      grid-template-columns: repeat(2, 1fr);

      &-revert > div:last-child {
        grid-column: 2 / 3;
      }
    }
  }
}

@include lg-screen {
  .container {
    padding: 100px var(--indent-medium) 170px;

    .title {
      font-size: 22px;
    }

    .subtitle {
      font-size: var(--font-size-base);
    }

    .sections-container {
      margin-top: var(--indent-xxlarge);
    }

    .section-title {
      font-size: var(--font-size-base);
    }
  }
}