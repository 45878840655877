@import "../../../../../../../styles/mixins";

.container {
  position: relative;

  .message {
    position: absolute;
    top: -30px;
    color: var(--grey);
  }

  .table-content {
    background-color: var(--secondary);
    padding: var(--indent-base) var(--indent-medium) 0;

    .table-head {
      display: flex;
      padding: 14px 6px;
      border-bottom: var(--border-black);

      .checkbox {
        margin-right: var(--indent-medium);
      }

      .table-head-row {
        display: grid;
        grid-template-columns: 6% 20% 22% 12% repeat(2, 20%);
        width: 100%;
      }

      .column {
        font-size: var(--font-size-medium);
        color: var(--grey);

        &:not(:first-child) {
          padding-left: var(--indent-small);
        }

        &:nth-child(4) {
          text-align: center;
        }

        &:nth-child(5),
        &:last-child {
          text-align: end;
        }
      }
    }

    .total-block {
      @include flex-start-end;
      padding: 18px var(--indent-small) 18px;
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;

      .title {
        color: var(--black-o-87);
        margin-right: 40px;
      }

      .total {
        color: var(--dark-grey);
      }
    }
  }

  .btn-block {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 40px;

    .save-btn {
      width: 100%;
      max-width: 150px;
      margin-left: var(--indent-large);
      min-height: var(--btn-secondary-min-height);
    }

    .cancel-btn {
      width: 100%;
      max-width: 150px;
    }
  }
}