@import "src/styles/mixins";

.container {
  width: 100%;
  margin-top: auto;
  background-color: var(--white-grey-lighter);
}

.page__link {
  display: block;
  color: var(--primary);
  font-size: var(--font-size-small);
}

.social-links {
  display: flex;
  margin-top: var(--indent-medium);

  .link__item {

    .icon {
      width: 30px;
      height: 30px;
      background-color: var(--silver-dark);
      background-repeat: no-repeat;
      background-size: contain;
    }

    .linkedin {
      background-image: url("./assets/linkedin.svg");
    }

    .facebook {
      background-image: url("./assets/facebook.svg");
    }

    .twitter {
      background-image: url("./assets/twitter.svg");
    }

    &:nth-child(2n) {
      margin: 0 var(--indent-large);
    }
  }
}

.top-block {
  @include flex-wrap;

  .logo-block {
    display: grid;
    grid-template-areas:
      "logo"
      "description"
      "contacts";
    padding: var(--indent-medium) var(--indent-base);

    .logo {
      grid-area: logo;
    }

    .contacts {
      grid-area: contacts;
    }

    .description {
      grid-area: description;
      margin: var(--indent-medium) 0;
      color: var(--grey);
      font-size: var(--font-size-small);
    }
  }

  .pages {
    flex-basis: 100%;

    .page__item {
      display: block;
      padding: var(--indent-base);
      color: var(--primary);
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-bold);
      text-transform: capitalize;
      border-top: var(--border-black);

      &.normal {
        text-transform: initial;
      }
    }

    .contact-item {
      position: relative;
      padding-left: var(--indent-medium);
      color: var(--primary);

      &-container {
        padding: var(--indent-base);
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-normal);
        background-color: var(--transparent);
        border-top: var(--border-black);
        cursor: pointer;

        & > div {
          font-weight: var(--font-weight-bold);
          text-transform: capitalize;
          color: var(--primary);
        }
      }

      &-content {
        background-color: var(--transparent);
        padding: 0 var(--indent-base) var(--indent-base);

        .contact-item {
          display: block;
          font-size: var(--font-size-small);

          &:not(:first-child) {
            margin-top: var(--indent-small);
          }
        }

        .page__link {
          display: inline-block;
        }
      }

      .address {
        display: inline-block;
      }

      .icon {
        position: absolute;
        top: 2px;
        left: 0;
      }
    }

    .social-links {
      display: none;
    }
  }
}

.copyright {
  margin-top: var(--indent-medium);
  padding: var(--indent-small);
  background-color: var(--dark-grey);
  color: var(--white-o-70);
  font-size: var(--font-size-small);
  text-align: center;
}

@include sm-screen {
  .top-block .logo-block {
    grid-template-areas:
      "logo description"
      "contacts description";
    grid-template-columns: 1fr 60%;
    grid-column-gap: var(--indent-medium);

    .contacts {
      margin-top: var(--indent-medium);
    }

    .description {
      margin: 0;
    }
  }
}

@include md-screen {
  .top-block {
    .logo-block {
      .page__link {
        font-size: var(--font-size-medium);
      }
    }

    .pages {
      .page__item,
      .page__link,
      .contact-item-container,
      .contact-item-content .contact-item {
        font-size: var(--font-size-medium);
      }
    }
  }
}

@include lg-screen {
  .top-block {
    @include container-xl;
    padding: var(--indent-xlarge) var(--indent-medium);

    .logo-block {
      display: block;
      flex-basis: 45%;
      padding: 0;

      .contacts {
        display: none;
      }

      .description {
        margin-top: var(--indent-medium);
        color: var(--primary);
      }
    }

    .pages {
      display: flex;
      flex-basis: 55%;
      justify-content: space-between;

      &__block .page__item:not(:first-child) {
        margin-top: var(--indent-base);
      }

      & > div:nth-child(2) {
        margin: 0 var(--indent-base) 0 var(--indent-medium);
      }

      .page__item {
        padding: 0;
        border-top: none;
      }

      .page__link {
        font-size: var(--font-size-small);
      }

      .contact-item {
        padding-left: 0;
        font-size: var(--font-size-small);

        &:not(:first-child) {
          margin-top: var(--indent-small);
        }

        .icon {
          left: -10px;
          transform: translateX(-100%);
        }
      }

      .contact-us {
        display: flex;
        flex-direction: column;
        max-width: 30%;
      }

      .social-links {
        display: flex;
        margin-top: 0;
      }
    }

    .description {
      max-width: 380px;
    }
  }

  .copyright {
    margin-top: 0;
  }
}

@include xl-screen {
  .top-block {
    .description {
      max-width: 415px;
    }
  }
}