@import "src/styles/mixins";

.container {
  .item-block {
    margin-bottom: 6px;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-medium);

    .label {
      margin-bottom: var(--indent-xsmall);
      color: var(--dark-grey);
    }

    .value {
      color: var(--grey);

      &.status > div:first-child {
        margin-top: 2px;
      }
    }
  }
}

@include sm-screen {
  .container {
    display: grid;
    grid-gap: var(--indent-base);
    grid-template-columns: repeat(2, 1fr);
  }
}

@include lg-screen {
  .container {
    grid-template-columns: repeat(4, 1fr);
  }
}