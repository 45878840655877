@import "src/styles/mixins";

.content {
  padding: var(--indent-xsmall);
  color: var(--grey);
  word-break: break-word;
  background-color: var(--secondary);
  box-shadow: var(--box-shadow-tooltip);
}

.text {
  @include text-overflow-ellipsis;
}