@import "../../../../../../../../../../styles/mixins";

.container {
  .table {
    padding: var(--indent-base) var(--indent-medium);
    background-color: var(--secondary);

    &.hiding-container .preloader {
      top: 50%;
    }

    .table-head {
      display: grid;
      grid-template-columns: 38px 65% auto 72px;
      padding: 0 var(--indent-small) 19px;
      color: var(--grey);
      font-size: var(--font-size-medium);
      border-bottom: var(--border-black);

      .column {
        &:not(:first-child) {
          padding-left: var(--indent-medium);
        }

        &:nth-child(2) {
          display: grid;
          grid-template-columns: 32% 20% repeat(2, 11%) repeat(2, 13%);

          & > div:first-child {
            padding-left: var(--indent-small);
          }

          & > div:not(:first-child) {
            padding-left: var(--indent-base);
          }
        }
      }
    }

    .empty {
      padding: 40px 0 var(--indent-medium);
      color: var(--dark-grey);
      font-size: var(--font-size-medium);
      text-align: center;
    }
  }

  .form-block {
    margin-top: var(--indent-base);
    padding: var(--indent-medium) var(--indent-medium) var(--indent-base);
    background-color: var(--secondary);
  }

  .total-block {
    margin-top: 40px;
  }
}

.hiding-container {
  position: relative;
  pointer-events: none;

  .preloader {
    top: 35%;
    z-index: 1;
    transform: translate(-50%, -50%);
  }

  &::after {
    @include hiding-container;
    content: "";
  }
}