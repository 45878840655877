@import "src/styles/mixins";

.label {
  position: relative;
  cursor: pointer;
  user-select: none;

  &:after {
    position: absolute;
    content: "";
  }
}

.filter {
  &.label {
    padding: 12px var(--indent-base);
    font-size: var(--font-size-medium);
    text-transform: uppercase;
    border: var(--border-black);

    &:after {
      top: 55%;
      right: var(--indent-small);
      width: 10px;
      height: 5px;
      background: url("../assets/chevrons-and-arrows/chevron_triagle_down.svg") center / auto no-repeat;
      transform: translateY(-50%);
    }
  }

  .items {
    @include flex-wrap;
    position: relative;
    padding: 42px 0 calc(3.5 * var(--indent-base));
    background-color: var(--secondary);

    .close-btn {
      display: none;
    }
  }
}

@include xs-screen {
  .filter {
    &.label {
      &:after {
        right: var(--indent-medium);
      }
    }
  }
}

@include md-screen {
  .filter {
    &.label {
      padding: var(--indent-base);
      color: var(--grey);

      &.active {
        border-bottom: var(--border-transparent);
      }
    }

    &.items-block {
      @include box-shadow-bottom;
      width: 100vw;
      background-color: var(--secondary);

      .items {
        @include container-xl;
        padding: 42px var(--indent-medium) calc(3.5 * var(--indent-base));

        .close-btn {
          position: absolute;
          top: 21px;
          right: 2.25rem;
          display: block;
          width: 14px;
          height: 14px;
          background-image: url("../assets/cross-icons/cross-black.svg");
          background-repeat: no-repeat;
          background-size: contain;
          cursor: pointer;
        }
      }
    }
  }
}