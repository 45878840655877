@import "../../../../../../../../styles/mixins";

.preloader {
  position: relative;
}

.container {
  color: var(--dark-grey);

  .dropzone {
    max-width: 328px;
    margin-bottom: var(--indent-xxlarge);
  }

  .files-block {
    max-width: 80%;

    &.full-width {
      max-width: 100%;
    }

    .file {
      &:not(:first-child) {
        margin-top: var(--indent-small);
      }
    }

    .empty-block {
      text-align: center;
    }
  }

  .btn-group {
    display: grid;
    grid-column-gap: var(--indent-large);
    grid-template-columns: repeat(2, 130px);
    justify-content: flex-end;
    margin-top: 40px;

    & > button {
      min-height: var(--btn-secondary-min-height);
    }
  }
}