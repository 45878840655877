@import "../../../../../../../../../styles/mixins";
@import "../../../../../../../../../styles/flex-columns";

.container {
  padding: var(--indent-small) 0;

  .title {
    display: none;
  }

  .certificates {
    display: flex;
    flex-wrap: wrap;

    .certificate-wrapper {
      margin: var(--indent-small) 0;
      text-align: center;
    }

    .certificate {
      display: inline-block;
      margin: 0 auto;
    }

    .label {
      @include flex-column;
      align-items: center;

      .icon {
        width: 60px;
        height: 60px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }

      .name {
        margin-top: var(--indent-xsmall);
        color: var(--grey);
        font-size: var(--font-size-medium);
      }
    }

    .items-block {
      margin-top: var(--indent-xsmall);
      padding: var(--indent-xsmall) 0;
      background-color: var(--secondary);
      box-shadow: var(--box-shadow-all-side);

      .item {
        position: relative;
        display: block;
        max-width: 300px;
        padding: var(--indent-xsmall) var(--indent-small);
        color: var(--dark-grey);
        font-size: var(--font-size-medium);

        &:not(:last-child):after {
          position: absolute;
          right: var(--indent-small);
          bottom: 0;
          left: var(--indent-small);
          height: 1px;
          background-color: var(--secondary);
          content: '';
        }

        @include xl-screen {
          &:hover {
            background-color: var(--white-green);
          }
        }
      }
    }
  }
}

@include lg-screen {
  .container {
    padding: var(--indent-large) calc(2.5 * var(--indent-base));

    .title {
      display: block;
      margin-bottom: var(--indent-medium);
      color: var(--dark-grey);
      font-family: var(--noto-sans-semi-bold);
      font-size: var(--font-size-medium);
      text-transform: uppercase;
    }

    .certificates {
      .certificate-wrapper {
        margin-right: var(--indent-small);
        text-align: start;
      }
    }
  }
}