@import "src/styles/mixins";

.container {
  background-color: var(--black-o-20) !important;
  @include reset-ul;
}

.modal {
  width: 100%;
  max-width: 350px;
  padding: var(--indent-medium);
  border-radius: 0;
  box-shadow: var(--box-shadow-pop-up-box);

  &-show {
    animation: swal-modal-show .3s forwards;
  }

  &-hide {
    animation: swal-modal-hide .3s forwards;
  }

  &-error {
    .icon {
      animation: none !important;

      & > span {
        top: 2px;
      }
    }
  }

  &.with-btn {
    padding-bottom: var(--indent-medium);
  }
}

.icon {
  margin: 0 auto;
  transform: scale(0.7);
  animation: none !important;
}

.title {
  margin: var(--indent-xsmall) 0;
  padding: 0;
  color: var(--grey);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-base);

  &.error {
    color: var(--red);
  }
}

.success-text,
.error-text,
.warning-text,
.question-text,
.info-text {
  margin: var(--indent-xsmall) 0 0;
  font-size: var(--font-size-base); // 1rem
}

.success-text,
.warning-text,
.question-text,
.info-text {
  color: var(--grey);
}

.error-text {
  display: flex !important;
  align-items: center;
  color: var(--red);
}

.btn-block {
  flex-direction: column;

  .btn {
    width: 116px;
    text-transform: uppercase;

    &-cancel {
      margin-bottom: var(--indent-base);
    }

    &-confirm {
      min-height: var(--btn-secondary-min-height);
    }
  }
}

@include sm-screen {
  .btn-block {
    flex-direction: row;

    .btn {
      &-cancel {
        margin-right: var(--indent-base);
        margin-bottom: 0;
      }
    }
  }
}

@keyframes swal-modal-show {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes swal-modal-hide {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}