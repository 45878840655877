.container {
  position: relative;
  width: 100%;
  padding: var(--indent-base);
  padding-right: calc(12px + 1.5 * 22px); // close-btn + close-btn-position indent
  background-color: var(--secondary);
  box-shadow: var(--box-shadow-pop-up-box);
  cursor: auto;

  .close-btn {
    position: absolute;
    top: 22px;
    right: 22px;
    width: 12px;
    height: 12px;
    background: url("../../common/assets/cross-icons/cross-grey.svg") no-repeat;
    background-size: cover;
    cursor: pointer;
  }
}