@import "src/styles/mixins";

.device-info-block {
  margin-bottom: var(--indent-base);
  padding: var(--indent-small);
  color: var(--dark-grey);
  font-size: var(--font-size-medium);
  text-align: center;
  background-color: var(--dirty-orange);
  border-radius: 4px;
}

.grid-items {
  position: relative;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  margin-bottom: var(--indent-medium);
}

.title-container {
  @include flex-column;
  margin-bottom: var(--indent-base);

  .title {
    color: var(--dark-grey);
    font-size: 18px;
    font-family: var(--russo-one);
    text-transform: uppercase;
  }

  .right-block {
    @include flex-center;
    margin-top: var(--indent-medium);

    .download-file {
      width: auto;

      &-skeleton {
        margin-right: var(--indent-medium);

        &.active {
          max-height: 24px;
        }
      }

      &-container {
        @include flex-center;
        min-height: var(--btn-secondary-min-height);
      }
    }
  }

  .views {
    @include flex-center;

    & > svg {
      cursor: pointer;

      &:first-child {
        margin-right: var(--indent-base);
      }
    }
  }
}

.content-container {
  position: relative;
}

.hiding-container {
  @include hiding-container;
}

.filters-block {
  display: flex;
  margin-bottom: var(--indent-medium);
}

.products-block {
  position: relative;
  padding-bottom: var(--main-padding-bottom);

  .list-header {
    @include flex-start-between;
    padding: 18px var(--indent-base);
    padding-right: 40px;
    color: var(--half-black);
    font-size: var(--font-size-small);
    text-transform: uppercase;
    background-color: var(--secondary);
    border-bottom: var(--border-black);

    .column {
      flex-basis: calc((100% - 38px) / 2);

      &:first-child {
        flex-basis: 50px;
        margin-right: var(--indent-small);
        text-align: right;
      }

      &:nth-child(3) {
        display: none;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .empty-container {
    @include flex-column;
    align-items: center;
    margin-top: var(--indent-small);

    .message {
      font-weight: var(--font-weight-medium);
    }

    .btn-secondary {
      margin: var(--indent-large) 0;
    }
  }
}

.disabled {
  pointer-events: none;
}

@include xs-screen {
  .grid-items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include sm-screen {
  .title-container {
    @include flex-start-between;
    flex-direction: row;

    .right-block {
      margin-top: 0;
    }
  }

  .products-block {
    .list-header {
      .column {
        flex-basis: calc((100% - 38px) / 3);

        &:nth-child(3) {
          display: block;
        }
      }
    }
  }
}

@include md-screen {
  .title-container {
    margin-bottom: 40px;

    .title {
      font-size: 20px;
    }
  }

  .products-block {
    padding-bottom: var(--main-padding-bottom-medium-device);

    .list-header {
      padding-right: var(--indent-base);

      .column {
        &:last-child {
          text-align: left;
        }
      }
    }
  }
}

@include lg-screen {
  .grid-items {
    grid-gap: var(--indent-medium);
    grid-template-columns: repeat(3, 1fr);
  }

  .products-block {
    .list-header {
      padding-right: 40px;

      .column {
        &:first-child {
          margin-right: var(--indent-medium);
        }
      }
    }
  }
}

@include xl-screen {
  .filters-block {
    display: block;

    & > div > div[data-tippy-root] {
      left: 0 !important;
      width: 100%;
      transform: translate(0, 62px) !important;

      & > div[class="tippy-box"] > div[class="tippy-content"] > div > div[class*="items-block"] {
        width: 100%;

        & > div > div[class*="close-btn"] {
          right: var(--indent-base);
        }
      }
    }
  }
}