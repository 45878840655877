@import "src/styles/mixins";

.label {
  position: relative;
  padding: 14px var(--indent-small);
  color: var(--dark-grey);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-medium);
  text-transform: uppercase;
  background-color: var(--secondary);
  user-select: none;

  &-text {
    display: inline-block;
    max-width: 75%;
  }

  &::after {
    position: absolute;
    top: 50%;
    width: 7px;
    height: 11px;
    margin-left: 15px;
    background: url('./assets/chevron.svg') 100% / 100% no-repeat;
    transform: translateY(-50%);
    transition: .3s;
    content: '';
  }

  &.active {
    &::after {
      top: 41%;
      transform: rotate(90deg);
    }
  }
}

.content {
  padding: var(--indent-small) var(--indent-small) var(--indent-medium);
  background-color: var(--secondary);
}

@include lg-screen {
  .label {
    cursor: pointer;
  }
}