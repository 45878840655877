@import "../../../../../../../../styles/mixins";

.preloader {
  position: relative;
}

.title {
  margin: 64px 0 40px;
  color: var(--dark-grey);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-base);
  text-transform: uppercase;
}

.grid-group {
  display: grid;
  grid-row-gap: var(--indent-medium);
  grid-column-gap: var(--indent-base);
}

.group-even-2 {
  grid-template-columns: repeat(2, 1fr);
}

.group-even-3 {
  grid-template-columns: repeat(3, 1fr);
}

.btn-group {
  display: grid;
  grid-column-gap: var(--indent-large);
  grid-template-columns: repeat(2, 130px);
  justify-content: flex-end;
  margin-top: 40px;

  .btn-submit {
    min-height: var(--btn-secondary-min-height);
  }
}