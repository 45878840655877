@import "src/styles/mixins";

.select-autocomplete__wrapper {
  position: relative;

  .select-autocomplete {
    &--is-disabled {
      position: relative;

      &:before {
        position: absolute;
        top: -16px;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        background-color: var(--white-o-40);
        content: '';
      }
    }

    &__label {
      @include text-overflow-ellipsis-line-clamp-2;
      position: absolute;
      top: 0;
      left: var(--indent-small);
      max-width: 90%;
      padding: 0 var(--indent-small);
      color: var(--black-o-60);
      font-size: var(--font-size-small);
      background-color: var(--secondary);
      transform: translateY(-60%);
    }

    &__control {
      min-height: var(--input-height);
      padding: var(--indent-small) 0;
      background-color: var(--secondary);
      border: var(--border-black);
      border-radius: var(--border-radius-input);
      cursor: pointer;

      .select-autocomplete__value-container {
        .select-autocomplete {
          &__multi-value {
            cursor: pointer;

            &__label {
              @include flex-center;
              padding: 6px 6px 6px 12px;
              background-color: var(--alluminium);
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;

              &:before {
                width: 18px;
                height: 18px;
                margin-right: 8px;
                background-image: url("../../common/assets/other-icons/active-item-tick.svg");
                background-repeat: no-repeat;
                background-size: 18px;
                content: "";
              }
            }

            &__remove {
              position: relative;
              background-color: var(--alluminium);
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;

              &:hover {
                color: var(--primary);

                & > svg {
                  transform: scale(1.1);
                }
              }
            }
          }
        }
      }
    }

    &__menu {
      width: 100%;
      margin: var(--indent-xsmall) 0 0;
      font-size: var(--font-size-base);
      font-family: var(--noto-sans);
      background-color: var(--secondary);
      border-radius: 0;
      box-shadow: var(--box-shadow-input);

      .select-autocomplete {
        &__option {
          @include flex-center;
          padding: 12px 18px;
          cursor: pointer;

          &:before {
            display: inline-block;
            width: 18px;
            min-width: 18px;
            height: 18px;
            min-height: 18px;
            margin-right: var(--indent-medium);
            border: 2px solid var(--alluminium);
            border-radius: var(--border-radius-checkbox);
            content: "";
          }

          &--is-selected {
            color: var(--dark-grey);
            background-color: var(--secondary);

            &:before {
              background: url("../assets/error_and_info_message-icons/check.svg") var(--alluminium);
              background-repeat: no-repeat;
              background-position: center;
              background-size: 14px 12px;
            }
          }
        }
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      padding: 0 14px;
      color: var(--grey);
    }

    &__preloader {
      top: 50%;
      right: auto;
      left: 50%;
      width: 24px;
      height: 24px;
      transform: translate(-50%, -50%);

      & > div {
        transform-origin: 12px 12px;

        &:after {
          top: 2px;
          left: 11px;
          width: 2px;
          height: 5px;
        }
      }
    }
  }
}