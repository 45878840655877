@import "../../../../../../../styles/mixins";

.title {
  margin-bottom: 40px;
  color: var(--dark-grey);
  font-size: 20px;
  font-family: var(--russo-one);
  text-transform: uppercase;
}

.content {
  padding: var(--indent-large) var(--indent-medium) 40px;
  background: var(--secondary);

  .group {
    &-small {
      max-width: 692px;
    }

    .grid-group {
      display: grid;
      grid-row-gap: var(--indent-medium);
      grid-column-gap: var(--indent-large);
    }

    .group-even-2 {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: var(--indent-medium);
    }

    .btn-block {
      margin-top: var(--indent-medium);
    }
  }
}

.btn-group {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 40px;

  .save-btn {
    width: 100%;
    max-width: 150px;
    min-height: var(--btn-secondary-min-height);
    margin-left: var(--indent-large);
  }

  .cancel-btn {
    width: 100%;
    max-width: 150px;
  }
}