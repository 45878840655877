@import "../../../../../../../../styles/mixins";

.container {
  position: relative;
  display: flex;
  margin-top: var(--indent-small);
  padding: var(--indent-base);
  padding-right: 40px;
  background-color: var(--light-sand);
  border-bottom: var(--border-black);

  &.deleting {
    pointer-events: none;
  }

  .number {
    flex-basis: 50px;
    margin-right: var(--indent-small);
    color: var(--dark-grey);
    font-weight: var(--font-weight-medium);
  }

  .options-menu {
    position: absolute;
    top: 15px;
    right: var(--indent-small);
  }

  .main {
    flex-basis: calc(100% - 38px);
    width: 100%;

    &-info {
      margin-bottom: var(--indent-large);

      .title-block {
        font-weight: var(--font-weight-bold);

        .part-number {
          color: var(--dark-grey);
          font-size: var(--font-size-base);
        }

        .name {
          color: var(--dark-grey);
          font-size: var(--font-size-medium);
        }
      }

      .condition {
        display: none;
      }

      .price-block {
        margin-top: var(--indent-small);
        text-align: right;

        .price {
          position: relative;
          font-weight: var(--font-weight-semi-bold);

          .info {
            position: absolute;
            top: -4px;
            margin-left: var(--indent-xsmall);
            cursor: pointer;

            &-icon {
              width: 15px;
              height: 15px;
            }
          }

          .info-content {
            padding: var(--indent-xsmall) 12px;
            color: var(--secondary);
            font-size: var(--font-size-small);
            background-color: var(--grey);
          }
        }

        .condition {
          display: block;
          font-size: var(--font-size-medium);
        }
      }
    }

    .description {
      .content {
        margin-bottom: var(--indent-base);

        .item {
            font-weight: var(--font-weight-medium);
            font-size: var(--font-size-medium);
          color: var(--dark-grey);

            &:not(:last-child) {
              margin-bottom: 12px;
            }

          .label {
            margin-bottom: var(--indent-xsmall);
          }
        }
      }

      .info {
        font-weight: var(--font-weight-semi-bold);
        font-size: var(--font-size-medium);

        .stock-count {
          margin-bottom: 12px;
          color: var(--green);
        }

        .reserved-count {
          margin-bottom: 12px;
          color: var(--red-txt-secondary);
        }

        .views-count {
          color: var(--grey);
        }

        .status {
          margin-top: 12px;
        }
      }
    }
  }
}

.hiding-container {
  @include hiding-container;

  .preloader {
    height: 45px;

    & > div {
      transform-origin: 39px 25px;

      &:after {
        width: 4px;
        height: 12px;
      }
    }
  }
}

@include sm-screen {
  .container {
    .main {
      &-info {
        @include flex-start-between;

        .title-block {
          flex-basis: calc(100% / 3);
          max-width: none;
          padding-right: 0;
        }

        .condition {
          display: block;
          flex-basis: calc(100% / 3);
        }

        .price-block {
          flex-basis: calc(100% / 3);
          margin-top: 0;

          .condition {
            display: none;
          }
        }
      }

      .description {
        .content {
          max-width: 55%;

          .item {
            @include flex-start-between;

            .label {
              flex-basis: 50%;
              margin-bottom: 0;
            }

            .value {
              flex-basis: 45%;
            }
          }
        }
      }
    }
  }
}

@include md-screen {
  .container {
    padding-right: var(--indent-base);

    .main {
      &-info {
        margin-bottom: var(--indent-base);

        .price-block {
          text-align: left;
        }
      }
    }
  }
}

@include lg-screen {
  .container {
    .number {
      margin-right: var(--indent-medium);
    }

    .main {
      .title-block {
        .part-number {
          font-size: 18px;
        }

        .name {
          font-size: var(--font-size-base);
        }
      }

      .description {
        @include flex-start-between;

        .content {
          flex-grow: 1;
          margin-bottom: 0;
          padding-right: var(--indent-base);
        }

        .info {
          flex: 0 0 calc(100% / 3);
        }
      }
    }
  }
}

@include xl-screen {
  .container {
    padding-right: 40px;

    &:hover {
      background-color: var(--white-green);
    }
  }
}