@import "../../../../../../../../styles/mixins";

.container {
  @include flex-column;
  position: relative;

  .btn-clear-all {
    position: absolute;
    top: calc(-1 * var(--indent-large));
    right: 0;
    margin-left: auto;
  }

  .btn-block {
    position: relative;
    align-self: flex-end;
    margin: var(--indent-base) 0 38px;
    @include flex-column;
  }

  .btn-add {
    display: inline-block;
    margin-left: auto;
  }

  .btn-upload {
    display: none;
    margin-top: var(--indent-medium);
  }
}

@include md-screen {
  .container .btn-clear-all {
    top: calc(-1 * var(--indent-xlarge));
  }
}

@include xl-screen {
  .container .btn-upload {
    display: block;
  }
}