@import "./mixins";

$col-counts: 12;
$min-col-size: 100% / $col-counts;

@for $i from 1 through $col-counts {
  $size: $min-col-size * $i;

  .col-#{$i} {
    flex-basis: $size;
    width: $size;
    max-width: $size;
  }
}

@for $i from 1 through $col-counts {
  $size: $min-col-size * $i;

  @include xs-screen {
    .col-xs-#{$i} {
      flex-basis: $size;
      width: $size;
      max-width: $size;
    }
  }
}

@for $i from 1 through $col-counts {
  $size: $min-col-size * $i;

  @include sm-screen {
    .col-sm-#{$i} {
      flex-basis: $size;
      width: $size;
      max-width: $size;
    }
  }
}

@for $i from 1 through $col-counts {
  $size: $min-col-size * $i;

  @include md-screen {
    .col-md-#{$i} {
      flex-basis: $size;
      width: $size;
      max-width: $size;
    }
  }
}

@for $i from 1 through $col-counts {
  $size: $min-col-size * $i;

  @include lg-screen {
    .col-lg-#{$i} {
      flex-basis: $size;
      width: $size;
      max-width: $size;
    }
  }
}

@for $i from 1 through $col-counts {
  $size: $min-col-size * $i;

  @include xl-screen {
    .col-xl-#{$i} {
      flex-basis: $size;
      width: $size;
      max-width: $size;
    }
  }
}