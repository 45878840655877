@import "../../../../../../../../../../../../../styles/mixins";

.container {
  @include flex-center;
  padding: var(--indent-base) 5px;
  border-bottom: var(--border-black);
  font-size: var(--font-size-medium);
  color: var(--black-o-87);

  .checkbox {
    max-width: 18px;
    max-height: 18px;
    margin-right: 35px;

    & > label {
      padding-left: 18px;

      &::before {
        box-sizing: border-box;
      }
    }
  }

  .row {
    display: grid;
    grid-template-columns: 7% repeat(2, 26%) repeat(2, 10.5%) 20%;
    align-items: center;
    width: 100%;

    .column {
      &:not(:first-child) {
        padding-left: var(--indent-small);
      }

      &:nth-child(3) {
        min-width: 100px;
      }

      &:nth-child(4) {
        padding-right: var(--indent-large);
        justify-self: flex-end;
      }

      &.packed {
        @include flex-center;
        color: var(--dark-khaki);
        font-weight: var(--font-weight-medium);

        &::before {
          width: 16px;
          height: 16px;
          margin-right: var(--indent-small);
          background: url("../../../../../../../../../../../../common/assets/other-icons/box.svg") 100% / 100% no-repeat;
          content: "";
        }
      }
    }
  }
}