@import "src/styles/mixins";
@import "src/styles/page-container.module.scss";

.side-menu {
  display: none;

  &-mobile {
    @include scroll;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 500px;
    transform: translateX(-120%);
    background-color: var(--secondary);
    transition: transform .5s;
    overflow: auto;

    &.open {
      transform: translateX(0);
    }

    .ordered-list {
      list-style: none;
      padding: 0;

      .item {
        padding: var(--indent-small) var(--indent-base);
        border-top: var(--border-black);

        &:last-child {
          border-bottom: var(--border-black);
        }

        &.active {
          font-weight: var(--font-weight-bold);
          background-color: var(--white-green);
        }
      }
    }

    &-controller {
      @include flex-center;
      position: sticky;
      top: -4px;
      width: 100vw;
      background: var(--white-green);
      padding: var(--indent-base);
      margin-bottom: var(--indent-medium);
      border-radius: var(--border-radius);
      transform: translateX(-16px);
      cursor: pointer;

      .btn {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: var(--indent-base);
      }
    }

    .close-btn {
      width: 16px;
      height: 16px;
      margin: 16px 16px 0 auto;
      background-image: url("./assets/close-icon.svg");
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
}

.content {
  .title {
    margin-bottom: var(--indent-base);
    font-size: 20px;
    text-transform: uppercase;
    color: var(--black-o-60);
  }

  & > div:not(:first-child) {
    .title {
      margin-top: var(--indent-base);
      padding-top: var(--indent-medium);
    }
  }

  .subtitle {
    margin-bottom: var(--indent-base);
    margin-left: var(--indent-medium);
  }

  .paragraph {
    margin-bottom: var(--indent-base);
  }

  .link {
    color: var(--primary);
    text-decoration: underline;
    word-break: break-word;
  }

  .table-container {
    overflow-x: scroll;
    padding-bottom: var(--indent-base);
  }

  .table {
    width: 100%;
    min-width: 550px;
    border-collapse: collapse;

    th, tr, td {
      border: var(--border-black);
    }

    tr {
      & > th,
      & > td {
        padding: var(--indent-small);
        text-align: left;
      }
    }

    tr > td {
      vertical-align: top;

      &:first-child {
        width: 50%;
      }

      & > div {
        margin-bottom: 0;

        & > span {
          display: block;
        }
      }
    }
  }

  ul, ol {
    margin: var(--indent-base) 0;
  }

  li {
    margin-bottom: var(--indent-small);
  }

  ul > li:last-child,
  ol > li:last-child {
    margin-bottom: 0;
  }

  ul > li > span:last-child,
  ol > li > span:last-child {
    margin-bottom: 0;
  }

  .ordered-list {
    padding: 0;
    list-style: none;

    .without-title {
      display: flex;
      padding-left: var(--indent-base);

      & > span:first-child {
        display: block;
        margin-right: 8px;
      }

      .paragraph {
        margin-bottom: 0;
      }
    }
  }
}

.scroll-to-top-arrow {
  position: sticky;
  margin: var(--indent-xxlarge) 0 0 auto;
}

@include md-screen {
  .side-menu-mobile-controller {
    transform: translateX(-24px);
  }
}

@include xl-screen {
  .container {
    display: flex;
    align-items: flex-start;
  }

  .side-menu {
    @include scroll;
    position: sticky;
    top: var(--indent-medium);
    display: block;
    margin-right: var(--indent-xlarge);
    margin-bottom: var(--indent-xxlarge);
    background-color: var(--white-green);
    padding-left: var(--indent-base);

    .container {
      width: 300px;
      padding: var(--indent-base) var(--indent-medium) 0 0;
      overflow-y: scroll;
    }

    .ordered-list {
      margin: 0;
      padding-left: 0;
      list-style: none;

      .item {
        color: var(--primary);
        margin-bottom: var(--indent-base);
        cursor: pointer;

        &.active {
          font-weight: var(--font-weight-bold);
        }
      }
    }

    &-mobile {
      display: none;

      &-controller {
        display: none;
      }
    }
  }

  .content {
    text-align: justify;
  }
}

@media (min-width: 1360px) {
  .side-menu .container {
    width: 350px;
  }
}