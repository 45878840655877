@import "src/styles/mixins";

.container {
  position: relative;
  width: 100%;

  & > [data-tippy-root] {
    width: 100%;
    transform: translate3d(0, 44px, 0) !important;
    z-index: 10 !important;
  }
}

@include md-screen {
  .container {
    max-width: 550px;
    margin: 0 var(--indent-large);
  }
}