@import "../../../../../../../../../../styles/mixins";

.modal {
  max-width: 500px !important;
  box-shadow: var(--box-shadow-pop-up-box);
}

.container {
  margin-top: 58px;

  .table-head,
  .row {
    display: grid;
    grid-template-columns: 4% repeat(2, 22%) repeat(2, 10%) repeat(2, 16%);
    align-items: center;
    font-size: var(--font-size-medium);
    border-bottom: var(--border-black);

    &.with-serial-number {
      grid-template-columns: 4% repeat(2, 21%) 10% 7% 16% 12% 9%;

      .column {
        &:nth-child(7) {
          justify-self: flex-end;
          padding-right: var(--indent-medium);
        }
      }
    }

    &.with-short-table {
      grid-template-columns: 6% repeat(2, 29%) 16% 20%;
    }

    .column {
      &.input {
        & > div {
          height: auto;

          & > input {
            padding: var(--indent-small) 12px !important;
            font-size: var(--font-size-medium);
          }
        }
      }

      &:not(:first-child) {
        padding-left: var(--indent-small);
      }

      &:nth-child(4) {
        justify-self: flex-end;
        padding-right: var(--indent-medium);
      }
    }
  }

  .table-head {
    padding: 0 2px 10px;
    color: var(--grey);
  }

  .row {
    padding: 15px 2px;

    .column {
      &:nth-child(6) {
        align-self: center;
      }

      &.column-serial-number {
        @include flex-center;

        .serial {
          max-width: 80%;
        }

        .edit-serial-number {
          flex-shrink: 0;
          align-self: flex-start;
        }
      }

      .serial-item {
        @include text-overflow-ellipsis;

        &:not(:last-child) {
          margin-bottom: var(--indent-small);
        }
      }

      .edit-serial-number {
        width: 18px;
        height: 18px;
        margin-left: 10px;
        background-image: url("../../../../../../../../../common/assets/other-icons/edit_icon.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;
      }

      .btn {
        height: auto;
      }
    }
  }
}