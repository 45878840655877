@import "src/styles/mixins";

.modal-container {
  z-index: 99;
}

.container {
  @include flex-column;
  width: 100%;
  background-color: var(--secondary);

  .info-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--indent-xxlarge) var(--indent-base) 40px;
    color: var(--secondary);
    background-color: var(--dark-khaki);

    .title {
      font-size: 1.5rem;
      font-family: var(--russo-one);
      text-align: center;
      text-transform: uppercase;
    }

    .items {
      margin: var(--indent-medium) 0 62px;
      padding: 0;
      list-style: none;

      .item {
        display: flex;
        max-width: 300px;

        &:not(:last-child) {
          margin-bottom: var(--indent-medium);
        }

        & > span {
          display: inline-block;
          font-size: var(--font-size-base);
        }

        &::before {
          flex-grow: 0;
          flex-shrink: 0;
          width: 10px;
          height: 10px;
          margin-top: 6px;
          margin-right: 12px;
          background-color: var(--secondary);
          content: "";
        }
      }
    }
  }

  .form-block {
    padding: var(--indent-xlarge) 40px;

    .title {
      margin-bottom: var(--indent-medium);
      color: var(--dark-grey);
      font-weight: var(--font-weight-bold);
      font-size: 20px;
      text-align: center;
      text-transform: uppercase;
    }

    .form-simple-text {
      margin-bottom: 40px;
      color: var(--grey);
      text-align: center;
    }

    .inputs-block {
      display: flex;
      flex-direction: column;

      .email {
        margin-bottom: var(--indent-large);
      }

      .reset-password-label {
        align-self: flex-end;
        margin-bottom: var(--indent-base);
        color: var(--grey);
        font-size: var(--font-size-medium);
        cursor: pointer;
      }
    }

    .reset-password-label {
      max-width: 110px;
      margin: 0 0 27px auto;
      color: var(--red-primary);
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-medium);
      cursor: pointer;
    }

    .btn {
      width: 100%;
    }

    .request-access-link {
      margin-bottom: 18px;
      color: var(--grey);
      font-weight: var(--font-weight-medium);
      text-align: center;

      .link {
        color: var(--red-primary);
        font-weight: var(--font-weight-semi-bold);
        cursor: pointer;
      }
    }

    .error {
      height: 22px;
      margin-bottom: var(--indent-medium);
      color: var(--red);
      text-align: center;
      opacity: 0;

      &.show {
        opacity: 1;
      }
    }
  }
}

@include lg-screen {
  .container {
    flex-direction: row;
    max-width: 1000px;
    height: 600px;
    margin: 0;

    .info-block,
    .form-block {
      flex: 0 1 50%;
    }

    .info-block {
      padding: 112px 60px;

      .title {
        font-size: 24px;
      }

      .items {
        margin: auto 0;
      }
    }

    .form-block {
      padding: 112px 70px 40px;

      .request-access-link {
        .link {
          display: inline-block;
        }
      }
    }
  }
}

@media (min-width: 1100px) {
  .modal {
    max-width: 1000px;
  }
}