@import '../../../../../../../../../styles/mixins';

.container {
  .table-head,
  .table-row {
    display: grid;
    grid-template-columns: 5% repeat(2, 23%) 8% 9% repeat(2, 16%);
    font-size: var(--font-size-medium);
    border-bottom: var(--border-black);

    .column {
      &:not(:first-child) {
        padding-left: var(--indent-small);
      }

      &:nth-child(2),
      &:nth-child(3) {
        min-width: 100px;
      }

      &:nth-child(6),
      &:nth-child(7) {
        justify-self: end;
      }
    }
  }

  .table-head,
  .table-row,
  .total-block {
    padding: var(--indent-base);
    padding-right: 116px;
  }

  .table-head {
    color: var(--grey);
  }

  .table-row {
    position: relative;
    color: var(--black-o-87);

    .checkbox {
      position: absolute;
      top: var(--indent-base);
      right: 43px;
    }
  }

  .total-block {
    @include flex-start-end;

    .title,
    .total {
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-medium);
    }

    .title {
      color: var(--grey);
    }

    .total {
      margin-left: 50px;
      color: var(--dark-grey);
    }
  }
}