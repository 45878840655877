@font-face {
  font-display: swap;
  font-family: "Russo One";
  src: url("./RussoOne/RussoOne.ttf") format("truetype"),
  url("./RussoOne/RussoOne.woff") format("woff"),
  url("./RussoOne/RussoOne.woff2") format("woff2"),
  url("./RussoOne/RussoOne.eot") format("embedded-opentype"),
  url("./RussoOne/RussoOne.otf") format("opentype"),
  url("./RussoOne/RussoOne.svg") format("svg");
}

@font-face {
  font-display: swap;
  font-family: "Noto Sans";
  //font-weight: 400;
  src: url("./NotoSans/NotoSans.ttf") format("truetype"),
  url("./NotoSans/NotoSans.woff") format("woff"),
  url("./NotoSans/NotoSans.woff2") format("woff2"),
  url("./NotoSans/NotoSans.eot") format("embedded-opentype"),
  url("./NotoSans/NotoSans.svg") format("svg");
}

@font-face {
  font-display: swap;
  font-family: "Noto Sans Medium";
  font-weight: 500;
  src: url("./NotoSans/NotoSans-Medium.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Noto Sans Semi Bold";
  font-weight: 600;
  src: url("./NotoSans/NotoSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Noto Sans Bold";
  font-weight: 700;
  src: url("./NotoSans/NotoSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Noto Sans Extra Bold";
  font-weight: 800;
  src: url("./NotoSans/NotoSans-ExtraBold.ttf") format("truetype");
}
