@import "src/styles/mixins";

.container {
  @include flex-start-between;
  color: var(--dark-grey);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-base);

  & > [data-tippy-root] {
    width: 100%;
    transform: translate3d(0, 50px, 0) !important;
  }

  .number-block {
    flex-basis: 35%;
  }

  .total-block {
    flex-basis: 63%;
    flex-shrink: 0;
    align-self: center;
    width: 100%;
  }

  .total-block {
    display: flex;

    .info {
      display: inline-block;
      margin-left: 11px;

      &-icon {
        width: 12px;
        height: 12px;
      }
    }

    .info-text {
      padding: var(--indent-small) var(--indent-base);
      font-weight: var(--font-weight-normal);
      white-space: normal;
      text-transform: none;
    }
  }

  .number-block {
    overflow-x: auto;

    .number {
      position: relative;
      display: inline-block;
      white-space: nowrap;
      border-bottom: 1px solid var(--grey);
    }

    &::-webkit-scrollbar {
      height: .1rem;
    }
  }

  .total {
    overflow-x: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      height: .1rem;
    }
  }

  .description {
    padding-left: var(--indent-base);
  }
}

@include sm-screen {
  .container {
    & > [data-tippy-root] {
      width: 350px;
    }
  }
}

@include md-screen {
  .container {
    justify-content: flex-start;

    .total {
      margin-left: var(--indent-base);
    }
  }
}