@import "src/styles/mixins";

.top-container {
  @include flex-column;
  margin: var(--indent-medium) 0 calc(2.5 * var(--indent-base));

  .btn-block {
    display: flex;
    margin-top: var(--indent-medium);
    align-self: flex-end;

    .print-btn {
      margin-left: var(--indent-base);
      min-height: var(--btn-secondary-min-height);
    }
  }
}

.print-blob {
  width: 30px;
  height: 30px;
  min-width: 30px;
  margin-left: var(--indent-large);
  cursor: pointer;
}

@include md-screen {
  .top-container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: calc(2.5 * var(--indent-base)) 0;

    .btn-block {
      align-self: initial;
      margin-top: 0;
      margin-left: var(--indent-medium);
    }
  }
}