@import "../../../../../../../../../styles/mixins";

@include lg-screen {
  .container {
    padding: calc(2.5 * var(--indent-base));

    .description-item {
      max-width: 600px;
    }
  }
}