@import "src/styles/mixins";

.container {
  @include more-about-general-params;

  h1 {
    margin-bottom: var(--indent-large);

    &.not-capitalize-text {
      text-transform: none;
    }
  }
}

@include lg-screen {
  .container h1 {
    margin-bottom: 80px;
  }
}