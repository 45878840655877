@import "../../../../../../../../../../../styles/mixins";

.label-row {
  display: flex;
  padding: 18px var(--indent-small);
  border-bottom: 1px solid var(--light-grey);

  .label {
    flex-basis: 48%;
    color: var(--grey);
    font-size: var(--font-size-medium);
    text-transform: uppercase;
  }
}

.item {
  display: flex;
  padding: var(--indent-base) 0;

  .currency,
  .exchange-rate {
    flex-basis: 48%;
  }

  .currency {
    @include flex-center;
    padding-left: var(--indent-base);
    color: var(--dark-grey);
    text-transform: uppercase;

    .flag {
      width: 25px;
      height: 13px;
      margin-right: 15px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .exchange-rate {
    @include flex-center-between;
    max-width: 200px;

    .rate {
      flex-shrink: 0;
      color: var(--dark-grey);
    }

    .status {
      color: var(--red);
    }
  }
}