@import "../../../styles/mixins";

.container {
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@include sm-screen {
  .container {
    height: 40px;
  }
}

@include md-screen {
  .container {
    height: 50px;
  }
}

@include xl-screen {
  .container {
    height: 60px;
  }
}