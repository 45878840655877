@import "src/styles/mixins";

.container {
  position: relative;
  height: var(--input-height);
  border: var(--border-black);
  border-radius: var(--border-radius-input);

  &.with-hover {
    &:hover {
      padding-right: var(--indent-medium);

      .remove-input-field {
        display: block;
      }
    }
  }

  &.with-different-border {
    border: var(--checkbox-border);
  }

  &.with-red-border {
    border: var(--error-border);
  }
}

.input {
  width: 100%;
  height: 100%;
  padding: var(--indent-base);
  color: var(--dark-grey);
  font-size: var(--font-size-base);
  font-family: var(--noto-sans);
  border: none;
  cursor: pointer;

  &-number {
    padding: var(--indent-base) !important;
  }

  &-password {
    padding-right: var(--indent-xlarge);
  }

  &-password-confirmation {
    padding-right: 80px;
  }
}

.label {
  @include text-overflow-ellipsis-line-clamp-2;
  position: absolute;
  top: 0;
  left: var(--indent-small);
  max-width: 90%;
  padding: 0 var(--indent-small);
  color: var(--black-o-60);
  font-size: var(--font-size-small);
  background-color: var(--secondary);
  transform: translateY(-60%);

  &.with-tooltip {
    padding-right: var(--indent-medium);
  }
}

.required-icon {
  margin-left: var(--indent-xsmall);
  color: var(--red);
}

.read-only {
  @include read-only-input;
}

.remove-input-field {
  position: absolute;
  top: 50%;
  right: var(--indent-small);
  display: none;
  width: 24px;
  height: 24px;
  background-image: url("../assets/other-icons/minus_icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(-50%);
  cursor: pointer;
}

.required {
  @include required-field-error;
}

.error-tooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 110%;
  max-width: 150px;
  padding: var(--indent-xsmall) var(--indent-small);
  font-size: var(--font-size-small);
  transform: translate(-50%, -50%);
  animation: error-fade .3s forwards;
  box-shadow: var(--box-shadow-tooltip);
  background-color: var(--secondary);
  text-align: center;
  z-index: 1;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: var(--indent-base);
  width: 24px;
  height: 24px;
  background-image: url("../assets/other-icons/eye.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: translateY(-50%);
  cursor: pointer;

  &.active {
    background-image: url("../assets/other-icons/active-eye.svg");
    background-position: center -1px;
  }
}

.password-confirmation {
  position: absolute;
  top: 50%;
  right: var(--indent-xlarge);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: translate(-50%, -50%);

  &.correct {
    width: 20px;
    height: 20px;
    background-image: url("../assets/other-icons/tick-green.svg");
  }

  &.not-correct {
    width: 16px;
    height: 16px;
    right: 54px;
    background-image: url("../assets/cross-icons/cross-red.svg");
  }
}

@keyframes error-fade {
  from {
    opacity: 0;
    transform: translate(-50%, -80%);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}