@import "src/styles/mixins";

.container {
  display: grid;
  grid-template-columns: 10% 12% 9.5% 11% 9.5% 18% 16% 14%;
  align-items: center;
  padding: 14px var(--indent-small);
  cursor: pointer;

  .column {
    &:not(:first-child) {
      padding-left: var(--indent-small);
    }

    &:nth-child(4),
    &:nth-child(7) {
      @include text-overflow-ellipsis;
    }

    &:nth-child(7) {
      padding-right: var(--indent-small);
    }

    &.status {
      & > div:first-child {
        margin-top: 2px;
      }
    }
  }
}

@include xl-screen {
  .container:hover {
    background-color: var(--white-green) !important;
  }
}