@import "../../../../../../../styles/mixins";

.container {
  margin-top: var(--indent-base);
  color: var(--grey);
  font-family: var(--noto-sans-medium);
  text-align: center;

  .link {
    color: var(--blue);
  }

  & > span .price {
    white-space: nowrap;
  }
}

@include lg-screen {
  .container {
    margin-top: 0;
    margin-left: var(--indent-base);
    text-align: left;

    .link {
      font-size: 18px;
    }

    & > span {
      display: block;
      font-size: 18px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1140px) {
  .container {
    margin-left: 0;

    & > span {
      display: inline;
    }
  }
}