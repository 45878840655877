@import "../../../../../../../styles/mixins";

.container {
  position: relative;

  .title {
    margin-bottom: var(--indent-base);
    color: var(--dark-grey);
    font-size: 18px;
    font-family: var(--russo-one);
    text-transform: uppercase;
  }

  .subtitle {
    margin-bottom: var(--indent-medium);
    color: var(--dark-grey);
    font-weight: var(--font-weight-bold);
  }

  .description {
    margin-bottom: 40px;
    color: var(--grey);
  }

  .accordion {
    &-container {
      &:not(:last-child) {
        margin-bottom: var(--indent-base);
      }
    }

    &-content {
      padding: 0;
    }

    &-label {
      padding: var(--indent-base) var(--indent-small);
      color: var(--dark-grey);
      font-weight: var(--font-weight-bold);
      font-size: 18px;
      border-bottom: var(--border-black);

      &::after {
        margin-left: 22px;
      }
    }
  }

  .btn-block {
    @include flex-start-end;
    margin-top: 40px;

    .btn {
      width: auto;

      &.with-min-size {
        min-width: 150px;
        min-height: var(--btn-primary-min-height);
      }

      &.cancel {
        margin-right: auto;
      }

      &:last-child {
        margin-left: var(--indent-medium);
      }
    }
  }

  .signature-section {
    display: flex;
    justify-content: space-between;
    margin-top: 120px;
    color: var(--dark-grey);

    .signature-block {
      flex-basis: 45%;

      &:last-child {
        .message {
          text-align: end;
        }

        .signature {
          justify-content: end;
        }
      }

      .message {
        margin-bottom: 72px;
        font-weight: var(--font-weight-medium);
        font-size: 18px;
      }

      .signature {
        display: flex;
        font-weight: var(--font-weight-semi-bold);
        font-size: var(--font-size-medium);
        text-transform: uppercase;

        & > span {
          position: relative;
          align-self: end;
          width: 120px;
          height: 1px;
          margin: 0 6px;
          border-bottom: var(--border-grey77);
        }
      }
    }
  }
}

@include md-screen {
  .container {
    .title {
      font-size: 20px;
    }
  }
}