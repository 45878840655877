@import "../../../../../../../../styles/mixins";

.preloader {
  position: relative;
}

.form {
  color: var(--dark-grey);

  .section {
    margin-bottom: var(--indent-medium);
  }

  .checkbox-group {
    max-width: 60%;
  }

  .dangerous-section {
    margin-bottom: var(--indent-large);
  }

  .title-block {
    position: relative;
    margin: var(--indent-xlarge) 0 var(--indent-medium);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-base);
    text-transform: uppercase;

    .title-in-block {
      display: inline-block;
    }

    .tooltip {
      top: 6px;
      width: 16px;
      height: 16px;
      margin-left: var(--indent-xsmall);
    }
  }

  .title {
    @include flex-center;
    margin: var(--indent-xlarge) 0 var(--indent-medium);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-base);
    text-transform: uppercase;
  }

  .group {
    margin-top: var(--indent-large);
  }

  .grid-group {
    display: grid;
    grid-row-gap: var(--indent-medium);
    grid-column-gap: var(--indent-base);
  }

  .group-even-2 {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: var(--indent-medium);
  }

  .group-even-3 {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: var(--indent-medium);
  }

  .group-even-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  .btn-group {
    position: relative;
    display: grid;
    grid-column-gap: var(--indent-large);
    grid-template-columns: repeat(2, 130px);
    justify-content: flex-end;
    margin-top: 40px;

    .error-text {
      position: absolute;
      top: -6px;
      right: 0;
      color: var(--red);
      transform: translateY(-100%);
      @include flex-center;

      &:before {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        margin-right: 12px;
        background-image: url("../../../../../../../common/assets/error_and_info_message-icons/exclamation-mark.svg");
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
      }
    }

    .btn-submit {
      min-height: var(--btn-secondary-min-height);
    }
  }
}