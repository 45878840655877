/* X-Small devices (portrait phones, less than 400px) */
@mixin xs-screen {
  @media (min-width: 400px) {
    @content;
  }
}

/* Small devices (portrait phones, less than 768px) */
@mixin sm-screen {
  @media (min-width: 576px) {
    @content;
  }
}

/* Medium devices (tablets, less than 992px) */
@mixin md-screen {
  @media (min-width: 768px) {
    @content;
  }
}

/* Large devices (tablets, less than 1200px) */
@mixin lg-screen {
  @media (min-width: 992px) {
    @content;
  }
}

/* XLarge devices (laptops, desktops, more than 1200px) */
@mixin xl-screen {
  @media (min-width: 1200px) {
    @content;
  }
}

/* container width */
@mixin container-lg {
  width: 100%;
  max-width: 992px;
  margin: 0 auto;
}

@mixin container-xl {
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
}

@mixin more-about-general-params {
  @include container-xl;
  padding: 40px var(--indent-medium) var(--main-padding-bottom);

  h1 {
    margin-bottom: var(--indent-base);
    font-weight: var(--font-weight-normal);
    font-family: var(--russo-one);
    font-size: 26px;
    color: var(--sand);
    text-transform: capitalize;
    text-align: center;
    line-height: normal;
  }

  @include sm-screen {
    h1 {
      font-size: 30px;
    }
  }

  @include lg-screen {
    padding-top: 86px;

    h1 {
      margin-bottom: 42px;
      font-size: 38px;
    }
  }
}

/* display: flex */
@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-center-evenly {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@mixin flex-start-between {
  display: flex;
  justify-content: space-between;
}

@mixin flex-start-end {
  display: flex;
  justify-content: flex-end;
}

@mixin flex-end-between {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

@mixin flex-column-reverse-wrap-between {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
}

@mixin flex-center-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@mixin flex-center-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* position */
@mixin position-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* text styles */
@mixin header-txt-style {
  color: var(--dark-grey);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-medium);
  line-height: 19px;
  text-transform: uppercase;
}

@mixin text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin text-overflow-ellipsis-line-clamp-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@mixin text-overflow-ellipsis-line-clamp-3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

// scroll
@mixin scroll {
  &::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem;
    background-color: var(--secondary);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--light-sand);
    border-radius: 5px;
  }
}

/* box-shadow */
@mixin box-shadow {
  box-shadow: var(--box-shadow-initial);
  transition: box-shadow .3s ease;
}

@mixin box-shadow-bottom {
  position: relative;

  &:after {
    position: absolute;
    bottom: 1px;
    z-index: -1;
    width: 100%;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.35);
    transform: scale(.99);
    content: "";
  }
}

/* hiding container */
@mixin hiding-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: var(--white-o-40);
  user-select: none;
  pointer-events: none;
}

@mixin read-only-input {
  position: absolute;
  top: -16px;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: var(--white-o-40);
}

@mixin required-field-error {
  border: 1px solid var(--red) !important;

  &[class*="checkbox_container"] {
    border: none !important;

    & > label {
      color: var(--red);

      &:before {
        border-color: var(--red);
      }
    }
  }

  & > span[class*="label"] {
    color: var(--red) !important;
  }
}

@mixin response-error-block-on-page {
  margin-top: var(--indent-large);
  list-style-type: none;

  .item {
    position: relative;
    color: var(--red);

    &:not(:first-child) {
      margin-top: var(--indent-small);
    }

    &:before {
      position: absolute;
      left: -40px;
      top: 5px;
      width: 12px;
      height: 12px;
      background-image: url("../components/common/assets/cross-icons/cross-red.svg");
      background-size: contain;
      content: "";
    }
  }
}

/* Reset property mixins */
@mixin reset-ul {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}