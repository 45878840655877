@import "../../../../../../../../../styles/mixins";

.container {
  position: relative;
  height: 78px;
  padding: 0 var(--indent-medium);

  .progress-base-line {
    position: relative;
    width: 100%;
    height: 7px;
    background: var(--grey77);

    &::before,
    &::after {
      position: absolute;
      top: 0;
      width: 25px;
      height: 25px;
      background: var(--grey77);
      border-radius: 50%;
      content: "";
    }

    &::before {
      left: 0;
      z-index: 99;
      transform: translate(-50%, -40%);
    }

    &::after {
      right: 0;
      transform: translate(50%, -40%);
    }

    &.before-circle-error::before {
      background: var(--red);
    }

    &.before-circle-success::before {
      background: var(--dark-khaki);
    }

    .step {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        background: var(--grey77);
        border-radius: 50%;
        transform: translate(50%, -40%);
        content: "";
      }

      &.error,
      &.error::after {
        background: var(--red);
      }

      &.success,
      &.success::after {
        background: var(--dark-khaki);
      }

      &.success {
        .info {
          color: var(--dark-grey);
          font-weight: var(--font-weight-medium);
        }
      }

      &.even {
        .info {
          bottom: 0;
          margin-bottom: 35px;
          margin-top: 0;
        }
      }

      &:first-child {
        .info {
          padding: 0;
        }
      }

      &:last-child {
        right: 0;

        .info {
          text-align: end;
          transform: none;
        }
      }

      .info {
        position: absolute;
        right: 0;
        margin-top: 15px;
        width: 100%;
        min-width: 100px;
        max-width: 120px;
        color: var(--grey);
        font-size: var(--font-size-medium);
        transform: translate(100%, 10px);
      }
    }
  }
}