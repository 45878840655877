@import "src/styles/mixins";

.container {
  @include flex-center-center;
  height: 84px;
  padding: var(--indent-xsmall);
  border: var(--border-black);

  .block {
    @include flex-center;
    flex-direction: column;
    color: var(--grey);
    font-size: var(--font-size-medium);

    .text {
      .browse-btn {
        color: var(--blue);
        cursor: pointer;
      }
    }

    .sub-text {
      margin-top: var(--indent-small);
      font-size: var(--font-size-small);
      text-align: center;
    }
  }

  &.active {
    background-color: var(--transparent);
    border: 2px solid var(--dark-khaki);
  }

  &.disabled {
    background-color: var(--transparent);
    border: 1px solid var(--silver);

    .block {
      color: var(--silver);

      .text {
        .browse-btn {
          color: var(--silver);
        }
      }
    }
  }

  &.error {
    border: var(--error-border);
  }
}