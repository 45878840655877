@import "../../../../../../../../../styles/mixins";

.container {
  margin-top: var(--indent-base);
  background-color: var(--secondary);

  .title {
    padding: 18px var(--indent-small);
    color: var(--dark-grey);
    font-weight: var(--font-weight-bold);
    font-size: 18px;
    text-transform: uppercase;
    border-bottom: var(--border-black);
  }

  .table-head {
    display: grid;
    grid-template-columns: 30% 20% 15% 15% 20%;
    padding: 18px var(--indent-small);
    color: var(--grey);
    font-size: var(--font-size-medium);
    border-bottom: var(--border-black);
  }

  .column {
    &:not(:first-child) {
      padding-left: var(--indent-small);
    }

    &.days,
    &:last-child {
      text-align: center;
    }
  }
}