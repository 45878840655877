@import "src/styles/mixins";

.container {
  min-height: var(--container-min-height-xs-device);

  .main {
    .title {
      margin-bottom: var(--indent-base);
      color: var(--dark-grey);
      font-size: 18px;
      font-family: var(--russo-one);
      text-transform: uppercase;
    }
  }
}

@media (min-width: 810px) {
  .container {
    display: grid;
    grid-column-gap: var(--indent-medium);
    grid-template-columns: 200px auto;
    min-height: var(--container-min-height-md-device);

    .main {
      position: relative;

      .title {
        margin-bottom: 40px;
        font-size: 20px;
      }
    }
  }
}