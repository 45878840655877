.container {
  display: flex;
  padding: 14px 6px;
  border-bottom: var(--border-black);

  .checkbox {
    margin-right: var(--indent-medium);
  }

  .row {
    display: grid;
    grid-template-columns: 6% 20% 22% 12% repeat(2, 20%);
    width: 100%;
  }

  .column {
    font-size: var(--font-size-medium);

    &:not(:first-child) {
      padding-left: var(--indent-small);
    }

    &:nth-child(3) {
      padding-right: var(--indent-base);
      min-width: 100px;
    }

    & > input {
      font-size: var(--font-size-medium);
    }

    &.end {
      text-align: end;
    }
  }
}