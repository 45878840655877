@import "../../../../../../../../../styles/mixins";

.container {
  .resources-block {
    margin-bottom: var(--indent-base);
  }
}

@include lg-screen {
  .container {
    padding: calc(2.5 * var(--indent-base));

    .resources-block {
      margin-bottom: var(--indent-large);
    }

    .description-item {
      & > div[class*='value'] {
        flex-basis: 35%;
        white-space: nowrap;
      }
    }
  }
}

@include xl-screen {
  .container {
    @include flex-start-between;

    .resources-block {
      flex-grow: 1;
      margin-right: var(--indent-base);
      margin-bottom: 0;
    }

    .warranty-block {
      flex-grow: 1;

      .description-item {
        & > div[class*='value'] {
          flex-basis: 20%;
        }
      }
    }
  }
}