@import "src/styles/mixins";

.select-container {
  position: relative;
  min-width: 0;
  height: var(--input-height);

  & > div[data-tippy-root] {
    width: 100%;
    padding: var(--indent-xsmall) 0;
  }

  .select-item {
    position: relative;
    height: var(--input-height);
    color: var(--dark-grey);
    font-size: var(--font-size-base);
  }

  .item-in-list {
    &-active {
      color: var(--dark-grey);
      background-color: var(--white-green);
    }

    &-not-available {
      color: var(--light-grey);
      pointer-events: none;
    }
  }
}

.radio-group-label,
.checkbox-group-label {
  position: relative;
  margin-bottom: var(--indent-base);
  color: var(--grey);
  font-size: var(--font-size-small);

  .hiding-container {
    @include hiding-container;
  }
}

.radio-group {
  @include flex-center;

  & > label:not(:first-child) {
    margin-left: var(--indent-medium);
  }
}

.checkbox-group-label {
  .tooltip {
    top: 50%;
    margin-left: var(--indent-small);
    transform: translateY(-50%);
  }
}

.checkbox-group {
  display: grid;
  grid-row-gap: var(--indent-small);
  grid-template-columns: repeat(3, auto);

  & > div:nth-child(3n - 1) {
    margin: 0 28px;
  }
}

.switcher-group {
  @include flex-center;

  & > label:not(:last-child) {
    margin-right: 40px;
  }
}

.textarea-block {
  position: relative;
  padding-top: var(--indent-small);
  border: var(--border-black);
  border-radius: var(--border-radius-input);
}

.required-icon {
  margin-left: var(--indent-xsmall);
  color: var(--red);
}