@import "src/styles/mixins";

.container {
  @include flex-center;
  @include text-overflow-ellipsis-line-clamp-2;
  position: relative;
  padding-left: var(--indent-medium);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-medium);
  color: var(--grey);

  .link {
    color: var(--grey);
  }

  //&.red,
  &.green {
    color: var(--dark-grey);

    .link {
      color: var(--dark-grey);
    }
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 16px;
    height: 20px;
    background: url("../assets/other-icons/document-icon.svg") 100% / 100% no-repeat;
    transform: translateY(-50%);
    content: "";
  }

  &.green::before {
    background: url("../assets/other-icons/green-document-icon.svg") 100% / 100% no-repeat;
  }

  //&.red::before {
  //  background: url("../assets/other-icons/red-document-icon.svg") 100% / 100% no-repeat;
  //}
}