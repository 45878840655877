@import "src/styles/mixins";

.label {
  position: relative;
}

.with-icon {
  &:before {
    position: absolute;
    top: 50%;
    left: 20px;
    z-index: 1;
    width: 18px;
    height: 18px;
    transform: translateY(-50%);
    content: url("./assets/search-icon.svg");
  }
}

.input {
  width: 100%;
  padding: 10px var(--indent-large) 10px calc(4 * var(--indent-base));
  font-size: var(--font-size-base);
  font-family: var(--noto-sans);
  background-color: var(--transparent);
  border: var(--border-black);
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &::placeholder {
    color: var(--grey);
    font-size: var(--font-size-base);
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
}

.skeleton {
  min-width: 100px;
  min-height: 22px;

  &-container {
    @include flex-center;
  }
}

.clear-btn {
  position: absolute;
  top: 50%;
  right: 12px;
  width: 12px;
  height: 12px;
  background: url("../assets/cross-icons/cross-grey.svg") no-repeat 100%/100%;
  transform: translateY(-50%);
  cursor: pointer;
}