@import "src/styles/mixins";

.container {
  position: relative;
  display: inline-block;
  width: 100%;

  .title {
    max-width: 75%;
    color: var(--dark-grey);
    font-family: var(--russo-one);
    text-transform: uppercase;
  }

  .slider {
    position: initial;

    & > div[class~='slick-list'] {
      & > div[class~='slick-track'] {

        & > div:not(:first-child) {
          padding-left: var(--indent-small);
        }
      }
    }
  }

  &-close-btn {
    position: absolute;
    top: var(--indent-base);
    right: 0;
    z-index: 2;
    display: block;
    width: 14px;
    height: 14px;
    background-image: url("../assets/cross-icons/cross-black.svg");
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }
}

.arrow {
  position: absolute;
  top: var(--indent-medium);
  right: 0;
  width: 8px;
  height: 14px;
  transform: scale(1, -1);

  &:before {
    font-size: var(--font-size-medium);
    content: url("../assets/chevrons-and-arrows/chevron-right.svg");
  }

  &-left {
    right: var(--indent-large);
    left: auto;
    transform: scale(-1, -1);

    &-shift {
      right: calc(var(--indent-large) + calc(3 * 14px)); // + btn-close width * 3
    }
  }

  &-right-shift {
    right: calc(3 * 14px); // 0 + btn-close width * 3
  }
}

@include md-screen {
  .container {
    .title {
      max-width: none;
    }
  }

  .arrow {
    top: 55%;

    &-left {
      right: auto;
      left: 0;
      transform: scale(-1, 1);

      &-shift {
        right: var(--indent-large); // initial state without close btn
      }
    }

    &-right-shift {
      right: 0;
    }
  }
}