@import "../../../../../../../../../styles/mixins";

.container {
  .title {
    color: var(--dark-grey);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--indent-medium);
    text-transform: uppercase;
  }

  .switchers-group {
    margin-bottom: 30px;

    & > label:not(:last-child) {
      margin-right: 40px;
    }
  }

  .radio {
    margin-bottom: 27px;

    & > div[class *= 'radio-group'] {
      & > label:last-child {
        margin-left: 44px;
      }
    }

    & > div[class *= 'radio-group-label'] {
      display: none;
    }
  }

  .description {
    max-width: 274px;
    margin-bottom: var(--indent-large);
  }

  .grid-group-4-even {
    display: grid;
    column-gap: 15px;
    grid-template-columns: repeat(4, 120px);
  }

  .grid-group-4 {
    display: grid;
    column-gap: 15px;
    grid-template-columns: repeat(2, 150px) 200px 150px;
    margin-bottom: 35px;
  }

  .grid-group-7 {
    display: grid;
    column-gap: 15px;
    grid-template-columns: repeat(2, 1.5fr) repeat(3, 1fr) 3fr 1.5fr;
  }
}