@import "../../../styles/mixins";

.container {
  max-width: 482px;
  padding: 0 var(--indent-base);
  margin: var(--indent-medium) auto;

  .text {
    font-weight: var(--font-weight-bold);
    text-align: center;
    text-transform: uppercase;
  }

  .btn-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--indent-large);

    .sing-in-btn,
    .sign-up-btn {
      min-width: 175px;
    }

    .sign-up-btn {
      min-height: var(--btn-secondary-min-height);
      margin-top: var(--indent-base);
    }
  }
}

@include lg-screen {
  .container {
    max-width: 510px;
    margin: var(--indent-base) auto 0;

    .btn-block {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      .sign-up-btn {
        margin-top: 0;
        margin-left: var(--indent-large);
      }
    }
  }
}