@import "../../../../styles/mixins";

.container {
  display: flex;
  justify-content: center;
  margin-top: var(--indent-medium);

  .prev-btn,
  .next-btn {
    @include flex-center-center;
    user-select: none;
    color: var(--secondary);
    background-color: var(--dark-khaki);
    min-width: 89px;
    cursor: pointer;

    &.disabled {
      color: var(--white-red);
      background-color: var(--silver);
    }
  }

  .prev-btn {
    margin-right: var(--indent-medium);

    &::before {
      width: 7px;
      height: 11px;
      margin-right: 12px;
      background: url("../../assets/chevrons-and-arrows/chevron-left-white.svg") 100% / 100% no-repeat;
      content: "";
    }

    &.disabled {
      &::before {
        background: url("../../assets/chevrons-and-arrows/chevron-left-grey.svg") 100% / 100% no-repeat;
      }
    }
  }

  .next-btn {
    margin-left: var(--indent-medium);

    &::after {
      width: 7px;
      height: 11px;
      margin-left: 12px;
      transform: rotate(180deg);
      background: url("../../assets/chevrons-and-arrows/chevron-left-white.svg") 100% / 100% no-repeat;
      content: "";
    }

    &.disabled {
      &::after {
        background: url("../../assets/chevrons-and-arrows/chevron-left-grey.svg") 100% / 100% no-repeat;
      }
    }
  }

  .page-btn {
    @include flex-center-center;
    height: 40px;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
    text-align: center;

    &:nth-child(3) {
      display: none;
    }

    &.active {
      color: var(--secondary);
      background-color: var(--dark-khaki);
    }
  }
}

@include sm-screen {
  .container {
    .page-btn {
      width: 40px;
      min-width: 40px;

      &:nth-child(3) {
        display: flex;
      }
    }
  }
}

@include md-screen {
  .container {
    justify-content: initial;
    margin-top: var(--indent-large);

    .prev-btn {
      margin-right: 35px;
    }

    .next-btn {
      margin-left: 35px;
    }
  }
}