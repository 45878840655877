.btn {
  width: 100%;
  min-height: var(--btn-secondary-min-height);
}

.btn-success {
  color: var(--green);

  &:hover {
    color: var(--dark-green);
  }
}

.dropzone-button {
  width: 100%;
}