@import "src/styles/mixins";

.container {
  @include flex-column;
  justify-content: center;
  max-width: 300px;
  height: 100vh;
  padding: var(--indent-base);
  margin: 0 auto;
  color: var(--dark-grey);
  text-transform: uppercase;

  .content {
    display: grid;
    grid-template-areas:
          "title title title"
          "text text ."
          "btn . image";

    .title {
      grid-area: title;
      margin-bottom: var(--indent-base);
      font-weight: var(--font-weight-bold);
      font-size: 2rem;
    }

    .text {
      grid-area: text;
    }

    .btn {
      grid-area: btn;
      margin-top: var(--indent-medium);
      margin-bottom: auto;
      min-width: 110px;
      max-width: 270px;
    }

    .image {
      grid-area: image;
      width: 100%;
      height: auto;

      &.gears-svg {
        width: 100px;
      }

      &.plane-svg {
        max-width: 80%;
      }
    }
  }

  &.internal-server-error {
    .content {
      grid-template-areas:
          "title title title"
          "text text text"
          "btn . image";

      .text {
        &:first-child,
        &:last-child {
          margin-bottom: var(--indent-base);
        }
      }

      .subtext {
        margin-bottom: var(--indent-xsmall);
        font-size: var(--font-size-medium);
        text-transform: none;
      }
    }
  }

  &.not-found {
    .content {
      grid-template-areas:
          "title"
          "text"
          "btn"
          "image";

      .image {
        margin-top: var(--indent-large);
        justify-self: center;
      }
    }
  }

  &.position-container {
    justify-content: flex-start;
    height: auto;
    min-height: var(--container-min-height-xs-device);
    width: 100%;
    padding-top: 50px;
    flex: 1 0 auto;
  }
}

@include xs-screen {
  .container {
    max-width: 380px;

    .content {
      .title {
        font-size: 2.5rem;
      }

      .btn {
        margin-right: var(--indent-medium);
      }

      .image {
        &.gears-svg {
          width: 150px;
        }
      }
    }
  }
}

@include sm-screen {
  .container {
    max-width: 540px;

    .content {
      margin: 0 auto;

      .text {
        max-width: 280px;
        font-size: 24px;
      }

      .btn {
        margin-right: var(--indent-xlarge);
      }

      .image {
        &.gears-svg {
          position: relative;
          top: -24px;
          left: -16px;
          width: 180px;
        }
      }
    }

    &.internal-server-error {
      .content {
        .text {
          max-width: none;
        }

        .subtext {
          font-size: 20px;
        }

        .image {
          &.gears-svg {
            position: relative;
            top: 0;
            left: 0;
          }
        }
      }
    }

    &.not-found {
      .content {
        .text {
          max-width: none;
        }

        .image {
          max-width: 70%;
        }
      }
    }
  }
}

@include md-screen {
  .container {
    max-width: 700px;

    .content {
      .title {
        margin-bottom: var(--indent-medium);
        font-size: 3.5rem;
      }

      .text {
        max-width: 350px;
        font-weight: var(--font-weight-medium);
        font-size: 30px;
      }

      .image {
        &.gears-svg {
          width: 220px;
        }
      }
    }

    &.internal-server-error {
      .content {
        .text {
          &:last-child {
            margin-bottom: var(--indent-xlarge);
          }
        }
      }
    }

    &.not-found {
      .content {
        grid-template-areas:
          "title title title"
          "text text text"
          "btn image image";

        .text {
          margin-bottom: var(--indent-large);
        }

        .btn {
        }

        .image {
          width: 400px;
          max-width: none;
        }
      }
    }

    &.position-container {
      min-height: var(--container-min-height-md-device);
    }
  }
}

@include lg-screen {
  .container {
    max-width: 900px;

    .content {
      width: 100%;
      max-width: 90%;

      .btn {
        font-size: var(--font-size-medium);
      }

      .image {
        &.gears-svg {
          width: 250px;
        }
      }
    }
  }
}