.title {
  margin-bottom: var(--indent-medium);
  color: var(--dark-grey);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  opacity: .9;
}

.container {
  display: grid;
  grid-column-gap: var(--indent-large);
  grid-template-columns: 328px auto;
  margin-bottom: var(--indent-large);

  .file {
    &:not(:first-child) {
      margin-top: var(--indent-xsmall);
    }
  }
}