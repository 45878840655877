@import "src/styles/mixins";

.container {
  margin: 0;
  padding: 0;
  text-transform: none;
  list-style: none;

  .item-block {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-medium);

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .label {
      margin-bottom: var(--indent-xsmall);
      color: var(--dark-grey);
    }

    .value {
      color: var(--grey);
    }
  }
}