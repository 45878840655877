@import "../../../../../../../../../styles/mixins";

.h2 {
  margin-bottom: var(--indent-base);
  font-size: var(--font-size-base);

  & + .hr {
    display: none;
  }
}

.table-header {
  display: none;
}

@include lg-screen {
  .container {
    padding: calc(2.5 * var(--indent-base)) var(--indent-medium);
  }

  .h2 {
    margin-bottom: var(--indent-medium);
    font-size: 18px;
  }

  .table-header {
    @include flex-start-between;
    margin-bottom: var(--indent-medium);
    font-family: var(--noto-sans-semi-bold);

    .value {
      flex-basis: 29%;
    }
  }

  .description-item {
    & > div[class*='value'],
    & > div[class*='dots']{
      flex-basis: 30%;
    }
  }
}