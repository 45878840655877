@import "src/styles/mixins";
@import "src/styles/base";

.app {
  @include flex-column;
  min-height: 100vh;

  &.no-scroll {
    overflow: hidden;
  }
}

.main-container {
  @include container-xl;
  flex: 1 0 auto;
  position: relative;
  height: 100%;
  padding: var(--indent-base) var(--indent-base) var(--main-padding-bottom);
}

.hiding-container {
  display: none;
}

@include md-screen {
  .main-container {
    padding: 28px var(--indent-medium) var(--main-padding-bottom-medium-device);
  }
}

@include lg-screen {
  .app.no-scroll {
    width: calc(100% - 5px);
  }
}