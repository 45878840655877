@import "../../../../../styles/mixins";

.title {
  margin-bottom: 40px;
  color: var(--dark-grey);
  font-size: 20px;
  font-family: var(--russo-one);
  text-transform: uppercase;
}

.container {
  padding: 22px var(--indent-medium) 57px;
  background-color: var(--secondary);

  .text {
    margin-bottom: 40px;
    color: var(--dark-grey);
    font-weight: var(--font-weight-medium);
  }

  .upload-container {
    display: grid;
    grid-column-gap: 70px;
    grid-template-columns: 394px auto;

    .dropzone-block {
      .dropzone {
        width: 100%;
        max-width: none;
        margin-bottom: 6px;
      }
    }
  }

  .example-block {
    margin-top: var(--indent-large);
    padding-top: var(--indent-base);
    border-top: 2px solid var(--grey77);

    .example-table {
      /* width for screen size 1200px,
      this row need for calc correct table container size
       */
      max-width: 880px;

      & > [data-tippy-root] {
        position: relative !important;
        transform: translate(0) !important;

        & > div[class="tippy-box"] {
          color: var(--primary);
        }
      }

      .accordion {
        &-label {
          padding: 0;
          font-size: var(--font-size-base);
          text-transform: none;

          &:after {
            width: 8px;
            height: 14px;
          }

          &[class*="active"]:after {
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
          }
        }

        &-label-text {
          color: var(--primary);

          & > div {
            @include flex-center;

            .icon {
              width: 16px;
              height: 16px;
              margin-left: 15px;
              background-image: url("../../../../common/assets/other-icons/table.svg");
              background-size: contain;
              background-repeat: no-repeat;
            }
          }
        }

        &-content {
          padding: var(--indent-medium) 0;
        }
      }

      .table-container {
        width: 100%;
        overflow-x: scroll;

        .table {
          margin-bottom: var(--indent-small);
          text-align: center;
          border-collapse: collapse;

          .tr, .td {
            border: var(--border-light-grey);
          }

          .tr:first-child {
            vertical-align: top;
            font-weight: var(--font-weight-bold);

            .td {
              padding: var(--indent-xsmall);
            }
          }

          .td {
            min-width: 150px;

            &:first-child {
              min-width: 100px;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              min-width: 250px;
            }
          }
        }
      }
    }

    .example-file {
      margin-top: var(--indent-large);

      .label {
        font-weight: var(--font-weight-bold);
        margin-bottom: var(--indent-small);
      }

      .file {
        max-width: 500px;
        grid-template-columns: 24px auto 120px 0 40px;
      }
    }
  }

  .description-table {
    margin-top: var(--indent-large);

    .row {
      display: grid;
      grid-template-columns: 200px auto;
      grid-column-gap: var(--indent-large);

      .items-column {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: var(--indent-small);
        padding: var(--indent-base);
        background-color: var(--light-sand-o-40);

        &.col-2 {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      &:not(:first-child) {
        margin-top: var(--indent-medium);
      }
    }
  }
}

.btn-group {
  @include flex-center;
  margin-top: var(--indent-xlarge);

  .cancel {
    min-width: 94px;
  }

  .proceed {
    min-width: 217px;
    min-height: var(--btn-secondary-min-height);
    margin-left: var(--indent-medium);
  }

  .upload {
    width: auto;
  }
}