@import "../../../../../../../styles/mixins";

.container {
  .tabs-labels {
    display: flex;

    .tab {
      width: 100%;
      background-color: var(--secondary);

      & > input[type="radio"] {
        display: none;

        &:checked + .label {
          position: relative;
          color: var(--dark-khaki);
          font-weight: var(--font-weight-semi-bold);

          &::before {
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--dark-khaki);
            content: "";
          }
        }
      }

      .label {
        @include flex-center-center;
        height: 100%;
        padding: 23px var(--indent-small);
        color: var(--grey);
        font-size: var(--font-size-medium);
        text-align: center;
        text-transform: uppercase;
        border-bottom: var(--border-black-strong);
        cursor: pointer;

        .label-text {
          position: relative;
          max-width: 75%;

          .counter {
            @include flex-center-center;
            position: absolute;
            top: 50%;
            right: -8px;
            width: 27px;
            height: 27px;
            color: var(--secondary);
            background-color: var(--alluminium);
            border-radius: 2px;
            transform: translate(100%, -50%);
          }
        }
      }
    }
  }
}