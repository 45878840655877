.title {
  padding: var(--indent-medium) var(--indent-base);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-medium);
  text-transform: uppercase;
}

.item {
  padding: 12px var(--indent-base);
  font-size: var(--font-size-base);
  border-bottom: var(--border-black);
}