@import "../../../../../../../styles/mixins";

.container {
  position: relative;

  .tab-container {
    margin-bottom: var(--indent-xsmall);

    .tab-label {
      background-color: var(--light-sand);
    }

    & > [data-tippy-root] {
      position: relative !important;
      transform: translate(0) !important;

      & > div[class="tippy-box"] {
        color: var(--primary);
      }
    }
  }
}

@include lg-screen {
  .container {
    margin: var(--indent-xlarge);

    .tab-labels {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .label {
      position: relative;
      width: 100%;
      padding: 14px var(--indent-small);
      color: var(--grey);
      font-size: var(--font-size-medium);
      white-space: nowrap;
      text-align: center;
      text-transform: uppercase;
      background-color: var(--secondary);
      border-bottom: var(--border-black-strong);
      cursor: pointer;
      user-select: none;

      &.active {
        color: var(--dark-grey);
        font-family: var(--noto-sans-semi-bold);

        &:before {
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: var(--dark-khaki);
          content: '';
        }
      }
    }

    .tab-content > div {
      background-color: var(--secondary);
    }
  }
}