@import "src/styles/mixins";

.options-block {
  display: grid;
  grid-gap: calc(1.25 * var(--indent-base)) 0;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;

  .section {
    @include flex-wrap;
    flex-basis: 100%;
  }
}

.option-item {
  display: inline-flex;
  margin: var(--indent-small) 0;

  &:not(:last-child) {
    margin-right: var(--indent-base);
  }
}

@include md-screen {
  .options-block {
    grid-gap: var(--indent-large) calc(3 * var(--indent-base));
    grid-template-columns: repeat(4, 1fr);
    width: calc(100% - calc(2.5 * var(--indent-base)));

    .section {
      display: block;
      flex-basis: 22%;
    }
  }
}

@include lg-screen {
  .options-block {
    grid-template-columns: repeat(5, 1fr);

    .section {
      flex-basis: 17%;
    }
  }
}