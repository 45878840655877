@import "src/styles/mixins";

.container {
  @include flex-center-center;
  position: relative;
  min-height: var(--btn-secondary-min-height);
  padding: 0 var(--indent-small);
  border: var(--border-language-menu);

  & > [data-tippy-root] > div[class*="tippy-box"] {
    border: var(--border-light-grey);

    & > div[class*="tippy-arrow"] {
      color: var(--white-green);
    }
  }

  .selected-item {
    display: inline-block;
    padding: 0;
    font-size: var(--font-size-medium);
    font-family: var(--noto-sens);
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    user-select: none;
  }

  .options-block {
    width: 200px;

    &.loading {
      position: relative;

      .hiding-container {
        @include hiding-container;
        background-color: var(--white-o-70);

        &:after {
          content: none;
        }

        .preloader {
          top: 50%;
          left: 50%;
          width: 30px;
          height: 30px;
          transform: translate(-50%, -50%);

          & > div {
            transform-origin: 15px 15px;

            &:after {
              top: 0;
              left: 13px;
              width: 3px;
              height: 7px;
            }
          }
        }
      }

      .item {
        user-select: none;
        pointer-events: none;
      }
    }

    .item {
      display: flex;
      color: var(--dark-khaki);
      cursor: pointer;

      .abbr {
        flex-basis: 15%;
        margin-right: var(--indent-large);
        text-transform: uppercase;
      }
    }

    .active-item {
      color: var(--dark-grey);
      background-color: var(--white-green);
    }
  }
}