@import "../../styles/mixins";

.container {
  position: relative;
  padding: var(--indent-large) var(--indent-medium) 92px;
  background-color: var(--secondary);

  .back-btn {
    position: absolute;
    top: -32px;
    left: 1%;
    color: var(--blue);
    font-weight: var(--font-weight-medium);
    cursor: pointer;

    &::before {
      display: inline-block;
      width: 7px;
      height: 11px;
      margin-right: 10px;
      background: url("../common/assets/chevrons-and-arrows/back-arrow.svg") center no-repeat;
      content: "";
    }
  }

  .text {
    margin-bottom: var(--indent-medium);
    color: var(--dark-grey);
    font-weight: var(--font-weight-medium);
  }

  .table-container {
    max-width: 870px;
    margin-bottom: 72px;
    overflow-x: auto;
  }

  .quotation-table,
  .place_product_table {
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0 0;
  }

  .quotation-table {
    //part-col
    .title:nth-child(1) {
      min-width: 180px;
    }

    //description-col
    .title:nth-child(2) {
      min-width: 280px;
    }

    //doq-col
    .title:nth-child(3) {
      min-width: 80px;
    }

    //quantity-col
    .title:nth-child(4) {
      min-width: 90px;
    }

    .title {
      padding-left: 5px;
      text-align: start;
      background-color: var(--light-alluminium);
      border: var(--border-black);
    }

    .cell {
      border: var(--border-black);
    }
  }

  .place_product_table {
    //part-col, part-cyrillic-col
    .title:nth-child(1),
    .title:nth-child(2) {
      min-width: 235px;
    }

    //description-col
    .title:nth-child(3) {
      min-width: 350px;
    }

    //section-col
    .title:nth-child(4) {
      min-width: 250px;
    }

    //doq-col
    .title:nth-child(5) {
      min-width: 80px;
    }

    //quantity-col, price-col
    .title:nth-child(6),
    .title:nth-child(8) {
      min-width: 90px;
    }

    //condition-col
    .title:nth-child(7) {
      min-width: 110px;
    }

    //currency-col
    .title:nth-child(9) {
      min-width: 130px;
    }

    //date-col
    .title:nth-child(10) {
      min-width: 220px;
    }

    //offer-validity-col
    .title:nth-child(11) {
      min-width: 160px;
    }

    //delivery-lead-time-col
    .title:nth-child(12) {
      min-width: 200px;
    }

    //from-importer-col
    .title:nth-child(13) {
      min-width: 310px;
    }

    //supply-conditions-col
    .title:nth-child(14) {
      min-width: 290px;
    }

    //supply-point-col
    .title:nth-child(15) {
      min-width: 130px;
    }

    .title {
      padding-left: 5px;
      text-align: start;
      background-color: var(--light-alluminium);
      border: var(--border-black);
    }

    .cell {
      border: var(--border-black);
    }
  }

  .tip {
    margin-bottom: var(--indent-medium);
    color: var(--dark-grey);
    font-weight: var(--font-weight-medium);
  }

  .file {
    grid-template-columns: 24px auto 120px 0 30px;
    max-width: 460px;
  }
}

@media (min-width: 1250px) {
  .container .table-container {
    max-width: 920px;
  }
}

@media (min-width: 1300px) {
  .container .table-container {
    max-width: 970px;
  }
}

@media (min-width: 1350px) {
  .container .table-container {
    max-width: 1020px;
  }
}

@media (min-width: 1400px) {
  .container .table-container {
    max-width: 1040px;
  }
}