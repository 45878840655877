@import "../../../../../../../styles/mixins";

.container {
  position: relative;

  .number {
    position: absolute;
    top: -26px;
    color: var(--dark-grey);
    font-weight: var(--font-weight-bold);
  }
}