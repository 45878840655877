@import "../../../../../../../../../styles/mixins";

.places-block {
  .place {
    margin-top: 40px;

    &.without-top-indent:first-child {
      margin-top: 0;
    }

    &.with-underline,
    &:not(:last-child) {
      padding-bottom: var(--indent-large);
      border-bottom: 2px solid var(--grey77);
    }

    .remove {
      height: auto;
      margin: var(--indent-medium) 0 0 auto;
    }
  }
}

.add {
  height: auto;
  margin: var(--indent-large) 0 0 auto;
}

.btn-block {
  @include flex-start-end;
  margin-top: 40px;

  .btn {
    min-height: var(--btn-secondary-min-height);

    &:first-child {
      min-width: 117px;
    }

    &:last-child {
      min-width: 100px;
      margin-left: var(--indent-large);
    }
  }
}