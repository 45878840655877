@import "src/styles/mixins";

.main-data-container {
  position: relative;
  padding: var(--indent-medium) 0 0;
}

.title-row {
  @include flex-start-between;
  margin-bottom: var(--indent-base);

  &.with-price-block {
    .title {
      flex-basis: 48%;
    }
  }

  .title {
    color: var(--dark-grey);
    font-size: calc(1.5 * var(--font-size-base));
    font-family: var(--russo-one);
    text-transform: uppercase;
  }
}

.price-container {
  display: flex;
  flex-basis: 48%;

  .price-block {
    .price {
      @include flex-center;
      color: var(--dark-grey);
      font-size: calc(1.5 * var(--font-size-base));
      font-family: var(--russo-one);
    }

    .label {
      color: var(--grey);
      user-select: none;
    }
  }

  .tooltip-for-prices {
    @include flex-center;
    position: initial;
    margin-left: var(--indent-medium);
    transform: none;

    & > svg {
      width: 20px;
      height: 20px;

      & > path {
        fill: var(--green);
      }
    }
  }

  .tooltip-content {
    padding: var(--indent-base);
    font-family: var(--noto-sans);
    font-weight: var(--font-weight-normal);

    & > div > div:not(:first-child) {
      margin-top: var(--indent-xsmall);
    }
  }
}

.content-row {
  @include flex-start-between;

  .details-block {
    @include flex-column;
    flex-basis: 48%;

    &.with-button-section {
      justify-content: space-between;
    }

    .edit-product {
      &-link {
        align-self: flex-start;
        min-width: 100px;
        margin-bottom: var(--indent-base);
      }

      &-btn {
        width: 100%;
        color: var(--green);

        & > svg > rect {
          stroke: var(--green);
        }
      }
    }

    .description-item {
      & > div[class*="value"] {
        flex-basis: 50%;
      }
    }
  }

  .purchase-block {
    @include flex-start-between;
    margin-top: calc(3.5 * var(--indent-base));

    .counter {
      margin-right: var(--indent-base);
    }

    .btn-block {
      position: relative;
      flex-basis: 60%;

      .btn {
        position: relative;
        flex-grow: 1;
        flex-shrink: 0;
        width: 100%;
        min-width: 250px;
        max-width: 250px;

        &.added-to-quotation {
          min-width: 290px;
          color: var(--secondary);
          background-color: var(--dark-khaki);
        }
      }

      .offers {
        margin-top: var(--indent-small);
        margin-left: 0;

        & > span {
          display: inline;
        }
      }
    }

    .not-available-block {
      .availability-label {
        &.out-of-stock {
          color: var(--red);
          font-size: 18px;
        }

        & + .offers {
          margin: var(--indent-small) 0 0;

          & > span {
            display: inline;
          }
        }
      }
    }
  }
}

.offers.single-block {
  margin-left: 0;

  & > span {
    display: inline;
  }
}

.modal {
  max-width: 80%;
}

@media (min-width: 992px) and (max-width: 1054px) {
  .title-row.with-price-block {
    .title {
      flex-basis: 462px;
    }

    .price-container {
      flex-basis: calc(100% - (462px + 62px));
    }
  }
}

@media (min-width: 992px) and (max-width: 1140px) {
  .content-row {
    .purchase-block {
      .btn-block {
        .btn {
          width: 100%;
        }
      }
    }
  }
}