@import "../../../../../../../../../styles/mixins";

.container {
  display: flex;
  padding: var(--indent-medium);
  background-color: var(--secondary);

  .column {
    position: relative;
    padding-right: var(--indent-large);
    flex: 1 0 17%;

    .label {
      margin-bottom: 12px;
      color: var(--grey);
      font-size: var(--font-size-medium);
    }

    .info {
      color: var(--dark-grey);
      font-weight: var(--font-weight-medium);
      text-transform: uppercase;

      &.price {
        white-space: nowrap;
      }

      &.status {
        max-width: 170px;
      }

      & > div:last-child {
        @include text-overflow-ellipsis;
      }
    }

    &:first-child {
      flex: 1 1 32%;
      min-width: 150px;
    }

    &:not(:last-child):after {
      position: absolute;
      top: 0;
      width: 1px;
      height: 95%;
      right: var(--indent-base);
      bottom: 0;
      margin: auto 0;
      background-color: var(--light-grey);
      content: "";
    }
  }

  .tooltip {
    right: var(--indent-large);
    transform: none;

    & > svg {
      width: 20px;
      height: 20px;

      & > path {
        fill: var(--green);
      }
    }

    &-content {
      padding: var(--indent-base);

      .data {
        &:nth-child(n + 2) {
          margin-top: var(--indent-base);
        }
      }
    }
  }
}