@import "src/styles/mixins";

.arrow-up {
  position: absolute;
  right: 20px;
  bottom: calc(3.5 * var(--indent-base));
  display: block;
  width: 28px;
  height: 28px;
  background: url('../../assets/chevrons-and-arrows/arrow_scroll_to_top.svg') 100%/100% no-repeat;
  cursor: pointer;

  &.hidden {
    display: none;
  }
}

@include md-screen {
  .arrow-up:not(.visible-always) {
    display: none;
  }
}