@import "src/styles/mixins";

.container {
  padding-top: 18px;
  background-color: var(--white-green);

  &.without-btn {
    background-color: var(--secondary);
    padding-top: 0;
  }

  .btn {
    margin: 0 auto 18px;
    font-size: 10px;

    &-preloader > div:after {
      background: var(--primary) !important;
    }
  }

  .product-card {
    position: relative;
    padding: var(--indent-base) var(--indent-small) 12px;
    background-color: var(--secondary);

    &:not(:last-child) {
      border-bottom: var(--border-black);
    }

    .title-row {
      display: flex;
      align-items: flex-start;
      margin-bottom: var(--indent-base);
      font-size: var(--font-size-base);

      .number {
        margin-right: 6px;
        color: var(--primary);
        font-weight: var(--font-weight-medium);
      }

      .title-block {
        flex-grow: 1;

        .title {
          color: var(--dark-grey);
        }

        .subtitle {
          color: var(--grey);
        }
      }

      .delete {
        width: 24px;
        height: 24px;
        background: url("../../../../../../../../common/assets/other-icons/trash-icon.svg") center no-repeat;
      }

      .preloader-block {
        position: relative;
        width: 24px;
        height: 24px;

        .preloader {
          right: 0;
          left: auto;
          width: 50px;
          height: 26px;
          transform: none;

          & > div {
            transform-origin: 38px 13px;

            &:after {
              width: 2px;
              height: 4px;
            }
          }
        }
      }
    }

    .description-row {
      .item {
        margin-bottom: 6px;
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-medium);

        .label {
          margin-bottom: var(--indent-xsmall);
          color: var(--dark-grey);
        }

        .value {
          color: var(--grey);
        }
      }
    }

    .hiding-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-color: var(--white-o-40);
    }
  }
}

@include sm-screen {
  .container .product-card .description-row {
    display: grid;
    grid-gap: var(--indent-base);
    grid-template-columns: repeat(2, 1fr);
  }
}

@include lg-screen {
  .container .product-card .description-row {
    grid-template-columns: repeat(4, 1fr);
  }
}