@import "../../../../../../../styles/mixins";

.content {
  padding: var(--indent-large) var(--indent-medium) var(--indent-medium);
  background-color: var(--secondary);

  .checkbox-group {
    margin-bottom: var(--indent-medium);
  }

  .grid-group {
    display: grid;
    grid-row-gap: var(--indent-medium);
    grid-column-gap: var(--indent-large);
    max-width: 692px;

    &.hiding-container {
      position: relative;
      pointer-events: none;

      &::after {
        position: absolute;
        top: -5%;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 105%;
        background-color: var(--white-o-40);
        content: '';
        user-select: none;
        pointer-events: none;
      }
    }
  }

  .group-even-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .btn-block {
    margin-top: var(--indent-medium);
  }

  .separator {
    height: 1px;
    margin: var(--indent-medium) 0 var(--indent-medium);
    background-color: var(--grey77);
    border: none;
  }
}

.btn-group {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 40px;

  .save-btn {
    width: 100%;
    max-width: 150px;
    min-height: var(--btn-secondary-min-height);
    margin-left: var(--indent-large);
  }

  .cancel-btn {
    width: 100%;
    max-width: 150px;
  }
}