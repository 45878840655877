@import "../../../../../../../styles/mixins";

.wrapper {
  position: sticky;
  position: -webkit-sticky;
  top: 30px;
  height: 550px;

  .container {
    padding: 0 var(--indent-medium) var(--indent-medium) 0;
    background: var(--secondary);

    .item {
      @include flex-center;
      color: var(--grey);
      cursor: pointer;

      .step {
        @include flex-center-center;
        position: relative;
        min-width: 30px;
        min-height: 30px;
        width: 30px;
        height: 30px;
        margin-right: var(--indent-base);
        background: var(--white-green);
      }

      &:not(:last-child) {
        margin-bottom: var(--indent-medium);

        .step {
          background: var(--white-green);

          &::before {
            position: absolute;
            top: 30px;
            right: 0;
            left: 0;
            width: 4px;
            height: 40px;
            margin: 0 auto;
            background: var(--white-green);
            content: "";
          }
        }
      }

      &.active {
        color: var(--dark-grey);
        font-weight: var(--font-weight-semi-bold);

        .step {
          color: var(--secondary);
          background-color: var(--light-sand);
        }
      }

      &.saved {
        .step {
          color: var(--secondary);
          background-color: var(--alluminium);

          &::before {
            background: var(--alluminium);
          }

          &::after {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: var(--alluminium);
            background-image: url("../../../../../../common/assets/error_and_info_message-icons/check.svg");
            background-repeat: no-repeat;
            background-position: center;
            content: "";
          }
        }
      }

      &.disabled {
        position: relative;
        pointer-events: none;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          background-color: var(--white-o-40);
          content: "";
        }
      }
    }
  }

  .btn-block {
    display: flex;
    margin-top: calc(80px - var(--indent-medium));

    & > button {
      padding-right: 16px;
      padding-left: 16px;
    }

    .primary-btn {
      min-height: var(--btn-secondary-min-height);
      margin-right: 16px;
    }
  }
}

@media (min-width: 1224px) {
  .wrapper {
    .btn-block {
      .primary-btn {
        margin-right: var(--indent-medium);
      }
    }
  }
}

@media (min-width: 1280px) {
  .wrapper {
    .container {
      padding-left: var(--indent-medium);
    }

    .btn-block {
      padding-left: var(--indent-medium);

      & > button {
        padding-right: var(--indent-medium);
        padding-left: var(--indent-medium);
      }
    }
  }
}