@import "src/styles/mixins";

.container {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--secondary);
  padding: var(--indent-base);
  text-align: center;
  animation: sm-device-hidden .8s;

  &.shown {
    animation: sm-device-show .8s;
  }

  .title {
    margin-bottom: var(--indent-xsmall);
    font-weight: var(--font-weight-bold);
    color: var(--dark-grey);
  }

  .text {
    font-size: var(--font-size-medium);
    color: var(--grey);
    margin-bottom: 20px;
  }

  .btn-block {
    .btn {
      width: 100%;
      min-height: var(--btn-secondary-min-height);

      &:first-child {
        margin-bottom: var(--indent-small);
      }
    }
  }
}

@include xs-screen {
  .container {
    .btn-block {
      @include flex-center-center;

      .btn {
        width: auto;

        &:first-child {
          margin: 0 var(--indent-base) 0 0;
        }
      }
    }
  }
}

@include md-screen {
  .container {
    bottom: 20px;
    left: auto;
    right: 20px;
    max-width: 500px;
    padding: var(--indent-large);
    text-align: start;
    box-shadow: var(--box-shadow-all-side);
    animation: md-device-hidden .8s;

    &.shown {
      animation: md-device-show .8s;
    }

    .btn-block {
      justify-content: flex-start;
    }
  }
}

@keyframes sm-device-show {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes sm-device-hidden {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes md-device-show {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes md-device-hidden {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}