@import "src/styles/mixins.scss";

.counter {
  @include flex-center-evenly;
  position: relative;
  width: 100%;
  max-width: 150px;
  height: 48px;
  padding: 0 22px;
  border: var(--border-black);

  &.secondary {
    height: auto;
    padding: 0;
    border: none;
  }
}

.error-message {
  padding: var(--indent-small) var(--indent-base);
  color: var(--red);
  font-size: var(--font-size-small);
  background-color: var(--secondary);
  box-shadow: var(--box-shadow-all-side);

  &:before {
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-right: var(--indent-small);
    background: url("../../common/assets/error_and_info_message-icons/exclamation-mark.svg") 100% / 100% no-repeat;
    content: "";
  }
}

.decrement,
.increment {
  @include flex-center;
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  padding: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  border: none;
  cursor: pointer;
  user-select: none;

  &.disabled {
    cursor: auto;
  }
}

.increment {
  background-image: url('./assets/increment.svg');

  &.secondary {
    background-image: url('./assets/increment-secondary.svg');
  }

  &.disabled {
    background-image: url('./assets/disabled_inc.svg');
  }
}

.decrement {
  background-image: url('./assets/decrement.svg');

  &.secondary {
    background-image: url('./assets/decrement-secondary.svg');
  }

  &.disabled {
    background-image: url('./assets/disabled_dec.svg');
  }
}

.count {
  width: 100%;
  margin: 0 var(--indent-small);
  color: var(--dark-khaki);
  font-weight: var(--font-weight-semi-bold);
  font-size: 18px;
  text-align: center;
  background-color: var(--transparent);
  border: none;
}

@include md-screen {
  .counter {
    max-width: 186px;
    height: 52px;
  }
}