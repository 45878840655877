@import "src/styles/mixins";

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  min-height: 100vh;

  .modal-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: var(--black-o-20);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .modal {
    position: relative;
    width: 100%;
    max-width: 450px;
    height: 100%;
    margin: 0 auto;
    background-color: var(--secondary);
    animation: hidden-modal-small-device .3s forwards;

    &.shown {
      animation: show-modal-small-device .4s forwards;
    }

    .close-btn {
      position: absolute;
      top: 14px;
      right: 14px;
      cursor: pointer;

      &:after {
        content: url("./assets/close-icon.svg");
      }

      &.white:after {
        content: url("./assets/close-icon-white.svg");
      }
    }
  }
}

@media (min-width: 450px) {
  .container {
    .modal {
      height: auto;
      margin: var(--indent-medium) auto var(--indent-xxlarge);
    }
  }
}

@include lg-screen {
  .container {
    .modal {
      max-width: 960px;
      margin: var(--indent-large) auto;
      animation: hidden-modal-large-device .2s forwards;

      &.shown {
        animation: show-modal-large-device .3s forwards;
      }

      .close-btn {
        top: 15px;
        right: 15px;

        &.white:after {
          content: url("./assets/close-icon.svg");
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1120px) {
  .container {
    .modal {
      .close-btn {
        top: 20px;
        right: 20px;
      }
    }
  }
}

@keyframes show-modal-small-device {
  0% {
    transform: translateY(-120%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes hidden-modal-small-device {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-120%);
  }
}

@keyframes show-modal-large-device {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  90% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes hidden-modal-large-device {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  60% {
    opacity: 0;
  }

  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}