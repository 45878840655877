@import '../../../../../../../styles/mixins';

// A4 -> 794px * 1123px
// A6 -> 397px x 559px

.addition-settings-block {
  @include flex-center-between;
  margin-bottom: var(--indent-medium);

  & > div {
    user-select: none;
  }
}

.container {
  &.for-view {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--indent-medium);
  }

  .list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-gap: var(--indent-small);

    &:before,
    &:after {
      position: absolute;
      width: 18px;
      height: 18px;
      background-image: url("assets/cut.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
    }

    &:before {
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
    }

    &:after {
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
    }

    &.full-grid,
    &.one-label-in-group,
    &.two-label-in-group {
      grid-template-rows: repeat(2, 1fr);
    }

    &.for-print,
    &.print-with-cut-line {
      grid-gap: 0;
    }

    &.one-label,
    &.one-label-in-group,
    &.for-print {
      &:before,
      &:after {
        content: none;
      }
    }

    &.two-label,
    &.two-label-in-group {
      &:before {
        top: 5px;
        left: 50%;
        transform: translateX(-50%) rotate(-90deg);
      }

      &:after {
        content: none;
      }
    }

    &.print-with-cut-line {
      &.one-label,
      &.one-label-in-group,
      &.two-label,
      &.two-label-in-group {
        &:before,
        &:after {
          content: '';
        }

        &:before {
          top: 5px;
          bottom: auto;
          transform: translate(-50%, 50%) rotate(-90deg);
        }

        &:after {
          top: auto;
          right: auto;
          bottom: 0;
          left: 15px;
          transform: translate(-50%, 50%) scale(-1, 1);
        }
      }

      &.one-label-in-group,
      &.two-label-in-group {
        &:after {
          top: 50%;
          transform: translate(-50%, -50%) scale(-1, 1);
        }
      }

      &.full-grid {
        &:before,
        &:after {
          width: 16px;
          height: 16px;
          content: '';
        }
      }
    }
  }

  .label {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: var(--indent-small) 12px 10px var(--indent-small);
    border: var(--border-dashed-alluminium);

    &.for-print,
    &.print-with-cut-line {
      width: 397px;
      height: calc(1123px / 2);
      padding: var(--indent-base);

      .description {
        &-label,
        &-value {
          font-size: 12px;
        }
      }

      .logo {
        margin-top: var(--indent-large);
      }
    }

    &.for-print {
      border: none;
    }

    &.print-with-cut-line {
      &:nth-child(1),
      &:nth-child(3) {
        border-right: var(--border-dashed-alluminium);
      }

      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: var(--border-dashed-alluminium);
      }
    }

    .title-block {
      @include flex-center-between;
      margin-bottom: var(--indent-medium);

      .checkbox,
      .checkbox > label {
        display: block;
      }

      .checkbox > label {
        position: relative;
        padding-left: 28px;

        &:before {
          position: absolute;
          top: -1px;
          left: 0;
          width: 18px;
          min-width: 18px;
          height: 18px;
          margin-right: 0;
        }
      }

      .checkbox > label,
      .title {
        font-size: var(--font-size-small);
        font-weight: 800;
        text-transform: uppercase;
      }

      .icons {
        display: flex;
        align-items: center;
        height: 24px;

        & > img {
          max-height: 100%;

          &:not(:first-child) {
            margin-left: var(--indent-base);
          }
        }
      }
    }

    .description {
      position: relative;
      display: grid;
      grid-template-columns: 45% 55%;
      margin-bottom: var(--indent-base);

      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 45%;
        border-right: 1px solid var(--grey);
        content: '';
      }

      & > div {
        margin-bottom: var(--indent-small);

        &:nth-child(odd) {
          padding-right: var(--indent-small);
        }

        &:nth-child(even) {
          padding-left: var(--indent-small);
        }
      }

      &-label,
      &-value {
        font-size: 10px;
      }

      &-label {
        font-weight: var(--font-weight-bold);
      }

      &-value {
        font-weight: var(--font-weight-medium);
      }
    }

    .logo {
      width: 120px;
      align-self: flex-end;
      margin-top: auto;
    }
  }
}