@import "../../../../../../../styles/mixins";

.item-block {
  width: 100%;
  margin-bottom: var(--indent-base);

  &:last-child {
    margin: 0;
  }
}

.item {
  width: 100%;

  .label {
    margin-bottom: var(--indent-xsmall);
    color: var(--dark-grey);
    font-family: var(--noto-sans-medium);
    font-size: var(--font-size-medium);
  }

  .link {
    color: var(--blue);
    text-decoration: underline;
  }

  .value {
    color: var(--grey);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-medium);
  }
}

.title {
  display: none;
  margin-bottom: var(--indent-base);
  color: var(--dark-grey);
  font-family: var(--noto-sans-semi-bold);
  font-size: var(--font-size-medium);

  &.mobile-title {
    display: block;
  }
}

.item-list-ol,
.item-list-ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@include lg-screen {
  .item {
    display: flex;

    .label {
      margin-bottom: 0;
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-base);
    }

    .dots {
      flex-grow: 1;
      min-width: 40px;
      height: 22px;
      margin-right: 5px;
      margin-left: var(--indent-small);
      border-bottom: 2px dotted var(--silver);
    }

    .value {
      flex-basis: 15%;
      flex-shrink: 0;
      font-size: var(--font-size-base);
    }
  }

  .title {
    display: block;
    text-transform: uppercase;
  }

  .item-list-ol {
    padding-inline-start: 20px;
    list-style: decimal;

    .item {
      margin-left: var(--indent-xsmall);
    }
  }
}