@import "src/styles/mixins";

.container {
  width: 100%;

  .table-head {
    display: grid;
    grid-template-columns: 10% 15% repeat(3, 9.5%) 20% 19.5% 7%;
    align-items: center;
    padding: var(--indent-base) var(--indent-small);
    color: var(--grey);
    font-size: var(--font-size-medium);

    & > div:not(:first-child) {
      padding-left: var(--indent-small);
    }
  }

  .table-items {
    &.not-accordion {
      position: relative;
      height: 118px;
      padding: var(--indent-xlarge) var(--indent-medium);
    }

    .item {
      color: var(--dark-grey);
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-medium);
      border-top: var(--border-black);
    }

    .empty {
      color: var(--grey);
      text-align: center;
    }

    .preloader {
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.accordion {
  &-label {
    padding: 0;
    color: var(--dark-grey);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-medium);
    text-transform: none;
    background-color: var(--transparent);

    & > div {
      width: 100%;
      max-width: none;
    }

    &::after {
      right: var(--indent-base);
      margin-right: 0;
    }
  }

  &-content {
    padding: 0;
    background: var(--white-blue);
    border-top: var(--border-black);

    & > div:not(:last-child) {
      border-bottom: var(--border-black);
    }
  }

  &-container-active {
    border: var(--border-alluminium);
    border-top: none;

    .accordion-label {
      background-color: var(--secondary);
    }

    &:not(:last-child) {
      border-bottom: none;
    }
  }
}