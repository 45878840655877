@import "../../../../../../../../../../../../../styles/mixins";

.container {
  max-width: 350px;
  margin-left: auto;

  .total-blocks {
    margin-bottom: 40px;

    .total-block {
      @include flex-start-between;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-medium);

      &:not(:first-child) {
        margin-top: 18px;
      }

      .label {
        color: var(--grey);
      }

      .value {
        color: var(--dark-grey);
      }
    }
  }

  .warning {
    margin-bottom: var(--indent-base);
    color: var(--red);
    font-size: var(--font-size-medium);
    text-align: center;
  }

  .btn {
    width: 100%;
  }
}