@import "src/styles/mixins";

.autogenerate-mode {
  @include flex-column;
  align-items: center;
  max-width: 280px;
  padding-top: var(--indent-xxlarge);

  .file-icon {
    width: 100px;
  }

  .btn-block {
    @include flex-column;
    align-items: center;
    margin-top: var(--indent-xlarge);

    .text {
      margin: var(--indent-small) 0;
      color: var(--black-o-87);
      font-family: var(--russo-one);
      font-size: var(--font-size-small);
      text-transform: uppercase;
    }

    .secondary {
      padding: var(--indent-xsmall);
      border-bottom: 2px solid var(--red-primary);

      &:hover {
        border-bottom-color: var(--dark-red);
      }
    }
  }
}

.container {
  padding: 22px var(--indent-medium) 57px;
  background-color: var(--secondary);

  .text {
    margin-bottom: 40px;
    color: var(--dark-grey);
    font-weight: var(--font-weight-medium);
  }

  .upload-container {
    display: grid;
    grid-column-gap: 70px;
    grid-template-columns: 394px auto;

    .dropzone-block {
      .dropzone {
        width: 100%;
        max-width: none;
        margin-bottom: 6px;
      }
    }
  }
}

.btn-group {
  @include flex-center;
  margin-top: var(--indent-large);

  .cancel {
    min-width: 94px;
  }

  .proceed {
    width: auto;
    min-width: 217px;
    min-height: var(--btn-secondary-min-height);
    margin-left: var(--indent-medium);
  }
}