@import "../../../../../../../../../styles/mixins";

.accordion {
  &-container {
    margin-top: var(--indent-base);

    &-active {
      border: var(--border-alluminium);
    }
  }

  &-label {
    padding: 18px var(--indent-small);
    color: var(--dark-grey);
    font-weight: var(--font-weight-bold);
    font-size: 18px;
    text-transform: uppercase;
    border-bottom: var(--border-black);
    background-color: var(--light-sand);

    &:after {
      right: 14px;
      width: 10px;
      height: 15px;
    }
  }

  &-content {
    padding: 0;
  }
}

.shipment-notice {
  padding: var(--indent-base) var(--indent-small) 40px;

  .info-row {
    @include flex-start-between;
    margin-bottom: 100px;
    color: var(--dark-grey);
    font-size: var(--font-size-medium);

    .label-block {
      font-weight: var(--font-weight-semi-bold);

      .label {
        text-transform: uppercase;
      }
    }
  }
}

.documents-container {
  .documents-label {
    font-weight: var(--font-weight-bold);
    border-top: var(--border-black);
    background-color: var(--light-sand);

    &::after {
      right: 14px;
    }

    & > div[class*="text"] {
      @include flex-center;
      width: 100%;
      max-width: none;
    }
  }

  .documents-content {
    padding: 0;
  }
}

.icon {
  width: 16px;
  margin: 0 12px;

  &.warehouse,
  &.to {
    margin-left: 0;
  }

  &.from {
    margin-right: 0;
  }
}