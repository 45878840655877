@import "../../../../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  padding: var(--indent-medium);
  background-color: var(--secondary);

  .title-block {
    @include flex-start-between;

    .title {
      color: var(--dark-grey);
      font-weight: var(--font-weight-semi-bold);
      text-transform: uppercase;
    }

    .buttons-block {
      display: flex;

      .arrow-block {
        display: flex;
        margin-right: var(--indent-base);

        .prev-arrow,
        .next-arrow {
          width: 10px;
          height: 18px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          cursor: pointer;
        }

        .prev-arrow {
          background-image: url("../../../../../../common/assets/chevrons-and-arrows/chevron-right-light.svg");
          transform: rotate(180deg);

          &.disabled {
            cursor: auto;
            background-image: url("../../../../../../common/assets/chevrons-and-arrows/chevron-right-grey.svg");
          }
        }

        .next-arrow {
          background-image: url("../../../../../../common/assets/chevrons-and-arrows/chevron-right-light.svg");
          margin-left: var(--indent-base);

          &.disabled {
            cursor: auto;
            background-image: url("../../../../../../common/assets/chevrons-and-arrows/chevron-right-grey.svg");
          }
        }
      }

      .delete {
        display: inline-block;
        width: 14px;
        height: 18px;
        background: url("../../../../../../common/assets/other-icons/trash-icon-grey.svg") 100% / 100% no-repeat;
        cursor: pointer;
      }

      .close {
        width: 18px;
        height: 18px;
        margin-left: var(--indent-base);
        background: url("../../../../../../common/assets/cross-icons/cross-grey.svg") 100% / 100% no-repeat;
        cursor: pointer;
      }
    }
  }

  .time {
    margin-top: 6px;
    color: var(--grey);
    font-size: var(--font-size-small);
  }

  .content-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    margin-top: var(--indent-xlarge);
    padding-right: var(--indent-small);
    color: var(--grey);
    overflow-y: auto;

    .main-block {
      .hello-block {
        margin-bottom: var(--indent-medium);
      }

      .list-block {
        list-style: none;

        & > li {
          & > a {
            color: var(--dark-grey);
            font-weight: var(--font-weight-semi-bold);
          }

          &:before {
            display: inline-block;
            width: 10px;
            height: 10px;
            min-width: 10px;
            margin-right: 12px;
            background-color: var(--alluminium);
            content: "";
          }
        }
      }
    }

    .sign-block {
      margin: var(--indent-large) 0 var(--indent-medium);
      white-space: pre-line;
      font-style: italic;
    }
  }
}