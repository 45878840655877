@import "src/styles/mixins";

.container {
  position: relative;
  width: 100%;
  max-width: 328px;
  max-height: var(--input-height);
  padding: var(--indent-base);
  border: var(--border-black);

  .label {
    position: absolute;
    top: -18%;
    left: var(--indent-small);
    padding: 0 var(--indent-small);
    color: var(--black-o-60);
    font-size: var(--font-size-small);
    background-color: var(--secondary);
  }

  .required-icon {
    margin-left: var(--indent-xsmall);
    color: var(--red);
  }

  .input-wrapper {
    font-size: var(--font-size-base);
    font-family: var(--noto-sans);

    .input {
      width: 100%;
      padding: 0 0 0 76px;
      color: var(--dark-grey);
      font-family: var(--noto-sans);
      line-height: 0;
      background: var(--transparent);
      border: none;
      border-radius: 0;

      &:focus {
        box-shadow: none;
      }
    }

    .select-btn {
      background-color: var(--transparent);
      border: none;
      border-radius: 0;

      &:after {
        position: absolute;
        top: 0;
        right: -16px;
        width: 1px;
        height: 100%;
        background-color: var(--black-o-20);
        content: '';
      }

      &.flag-hidden {
        & > div[class*='selected-flag'] {
          & > div[class*='flag'] {
            background-image: none;
          }
        }
      }

      & > div[class*='selected-flag'] {
        width: 48px;
        padding: 0;

        &::before {
          display: none;
        }

        & > div[class*='flag'] {
          &::after {
            display: inline-block;
            width: 11px;
            height: 7px;
            margin-left: 35px;
            background: url("../assets/chevrons-and-arrows/arrow-down-light.svg") center no-repeat;
            content: "";
          }

          & > div[class*='arrow'] {
            display: none;
          }
        }
      }

      .menu {
        @include scroll;
        width: 328px;
        margin: 20px 0 0 -17px;
        overflow-y: auto;
        border: var(--border-black);
        border-radius: 2px;
        box-shadow: var(--box-shadow-input);

        &::-webkit-scrollbar {
          width: 0.20em;
        }

        & > li[class*="search"] {
          @include flex-center;
          padding: 10px 10px var(--indent-base);

          &::before {
            display: inline-block;
            width: 18px;
            height: 18px;
            background: url("../assets/other-icons/search-icon.svg") center no-repeat;
            content: "";
          }

          & > input[class*="search-box"] {
            margin-left: var(--indent-base);
            padding: var(--indent-xsmall);
            border-top: none;
            border-right: none;
            border-bottom: var(--border-black);
            border-left: none;
            border-radius: 0;

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
              display: none;
            }

            &::-ms-clear,
            &::-ms-reveal {
              display: none;
              width: 0;
              height: 0;
            }
          }
        }

        & > li[class*="no-entries-message"] {
          padding: 10px;
          color: var(--grey);
          text-align: center;
        }

        & > li[class*="country"] {
          padding: 6px 10px 6px 46px;
          color: var(--grey);

          &:not(:last-child) {
            &::after {
              position: absolute;
              right: 0;
              bottom: 0;
              left: 0;
              display: inline-block;
              width: 90%;
              height: 1px;
              margin: 0 auto;
              background-color: var(--light-grey);
              visibility: visible;
              opacity: 1;
              content: "";
            }
          }

          &[class*="highlight"] {
            color: var(--dark-grey);
            background-color: var(--white-green);

            &::after {
              visibility: hidden;
              opacity: 0;
            }
          }

          & > div[class*="flag"] {
            top: 13%;
            margin: 0;
          }
        }
      }
    }
  }

  .error-message {
    position: absolute;
    bottom: calc(-1 * var(--indent-xsmall));
    left: var(--indent-base);
    display: none;
    color: var(--red);
    font-size: var(--font-size-small);
    transform: translateY(100%);
  }

  .error-icon {
    position: absolute;
    top: 50%;
    right: 4%;
    display: none;
    width: 20px;
    height: 20px;
    background: url('../assets/error_and_info_message-icons/exclamation-mark.svg') 100% / 100% no-repeat;
    transform: translateY(-50%);
  }

  &.error {
    padding-right: calc(2.5 * var(--indent-base));
    border-color: var(--red);

    .label,
    .input::placeholder {
      color: var(--red);
    }

    .error-message,
    .error-icon {
      display: block;
    }
  }
}

@include xl-screen {
  .container {
    .input-wrapper {
      .select-btn {
        .menu {
          & > li[class*='country']:hover {
            background-color: var(--white-green);
          }
        }
      }
    }
  }
}