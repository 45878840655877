@import "../../../styles/mixins";

.container {
  overflow: hidden;

  .row {
    position: relative;
    transform: translateX(100%) scale(0.7);

    &:nth-child(2n + 1) {
      transform: translateX(-100%) scale(0.7);
    }

    &.visible {
      animation-fill-mode: forwards;
      animation-duration: .8s;
      animation-name: transform-from-right;
      transform: translate(0) scale(1);

      &:nth-child(2n + 1) {
        animation-name: transform-from-left;
      }
    }

    &:not(:last-child) {
      .arrow-block {
        height: 60px;

        .arrow {
          position: relative;
          width: 2px;
          height: 100%;
          background-color: var(--alluminium);
          margin: 0 auto;

          &::after {
            position: absolute;
            bottom: 0;
            left: 0.8px;
            width: 10px;
            height: 10px;
            background-color: var(--alluminium);
            transform: translate(-50%, 50%) rotate(45deg);
            content: "";
          }
        }
      }
    }

    .action-block {
      position: relative;
      width: 100%;
      min-height: 200px;
      padding: var(--indent-base);
      padding-left: var(--indent-small);
      border: var(--border-sand-o-70);
      box-shadow: var(--box-shadow-panel);

      .text {
        display: flex;
        align-self: flex-start;

        .number {
          margin-right: var(--indent-small);
          color: var(--dark-khaki);
          font-size: var(--indent-medium);
          font-weight: var(--font-weight-bold);
          line-height: 24px;
        }

        .description-block {
          .title {
            margin-bottom: var(--indent-small);
            color: var(--dark-khaki);
            font-weight: var(--font-weight-semi-bold);
            text-transform: uppercase;
          }

          .description {
            color: var(--grey);
          }
        }
      }

      .img {
        margin: 0 auto;
        max-width: 150px;
        max-height: 150px;

        & > img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .container {
    .row {
      .action-block {
        @include flex-start-between;

        .text {
          margin-right: var(--indent-base);

          .number {
            margin-right: var(--indent-base);
            font-size: 42px;
            line-height: 42px;
          }

          .description-block {
            .title {
              max-width: 300px;
            }

            .description {
              max-width: 350px;
            }
          }
        }

        .img {
          margin: 0;
        }
      }
    }
  }
}

@include md-screen {
  .container {
    .row {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 60px;

        .arrow-block {
          position: relative;
          z-index: 1;
          width: 100%;
          height: auto;

          .arrow {
            position: absolute;
            top: 50%;
            left: 0;
            width: 85%;
            height: calc(50% + 60px);
            background-color: var(--transparent);
            border-width: 2px 2px 0 0;
            border-style: solid;
            border-color: var(--alluminium);

            &::after {
              position: absolute;
              right: -1px;
              left: auto;
              transform: translate(50%, 50%) rotate(45deg);
              content: "";
            }
          }
        }
      }

      &:nth-child(2n) {
        flex-direction: row-reverse;

        .arrow-block {
          transform: scale(-1, 1);
        }
      }

      .action-block {
        flex-shrink: 0;
        max-width: 80%;
        padding: var(--indent-large) 40px var(--indent-base);

        .text .description-block .description {
          max-width: 400px;
        }
      }
    }
  }
}

@include lg-screen {
  .container {
    .row {
      .action-block {
        max-width: 800px;

        .text .description-block .description {
          max-width: 450px;
        }
      }
    }
  }
}

@keyframes transform-from-left {
  from {
    transform: translateX(-100%) scale(0.7);
  }

  to {
    transform: translateX(0) scale(1);
  }
}

@keyframes transform-from-right {
  from {
    transform: translateX(100%) scale(0.7);
  }

  to {
    transform: translateX(0) scale(1);
  }
}